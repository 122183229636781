import { ReactElement } from 'react'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import Box, {
  BorderColorType,
  BoxColorType,
} from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import {
  AnchorLink,
  AnchorLinkProps,
  LinkStyleType,
} from 'src/components/atoms/Link/Link'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TypographyColor,
} from 'src/components/atoms/Typography/Typography'

export interface AlertProps {
  title: string
  description?: string
  style?: 'warning' | 'info' | 'success' | 'danger'
  backgroundStyle?: 'white' | 'color'
  testId?: string
  action?: ReactElement
  link?: AnchorLinkProps
  dismiss?: () => void
}

const Alert = ({
  title,
  description,
  style = 'warning',
  testId,
  action,
  link,
  dismiss,
  backgroundStyle = 'color',
  ...rest
}: AlertProps): ReactElement => {
  const styles: {
    [key: string]: {
      titleColor: TypographyColor
      descriptionColor: TypographyColor
      iconColor: TypographyColor
      backgroundColor: BoxColorType
      icon: React.FunctionComponent<React.ComponentProps<'svg'>>
      linkStyle: LinkStyleType
      borderColor: BorderColorType | undefined
    }
  } = {
    danger: {
      titleColor: 'text-base-color-fg-danger',
      descriptionColor:
        backgroundStyle === 'color'
          ? 'text-base-color-fg-danger'
          : 'text-base-color-fg-default',
      iconColor: 'text-base-color-fg-danger',
      backgroundColor:
        backgroundStyle === 'color'
          ? 'bg-base-color-bg-danger'
          : 'bg-base-color-bg-default',
      icon: ExclamationCircleIcon,
      linkStyle: 'danger',
      borderColor:
        backgroundStyle !== 'color' ? 'border-base-color-bg-danger' : undefined,
    },
    warning: {
      titleColor: 'text-base-color-fg-attention',
      descriptionColor:
        backgroundStyle === 'color'
          ? 'text-base-color-fg-attention'
          : 'text-base-color-fg-default',
      iconColor: 'text-base-color-fg-attention',
      backgroundColor:
        backgroundStyle === 'color'
          ? 'bg-base-color-bg-attention'
          : 'bg-base-color-bg-default',
      icon: ExclamationTriangleIcon,
      linkStyle: 'warning',
      borderColor:
        backgroundStyle !== 'color'
          ? 'border-base-color-bg-attention'
          : undefined,
    },
    info: {
      titleColor: 'text-base-color-fg-accent',
      descriptionColor:
        backgroundStyle === 'color'
          ? 'text-base-color-fg-accent'
          : 'text-base-color-fg-default',
      iconColor: 'text-base-color-fg-accent',
      backgroundColor:
        backgroundStyle === 'color'
          ? 'bg-base-color-bg-accent'
          : 'bg-base-color-bg-default',
      icon: InformationCircleIcon,
      linkStyle: 'primary',
      borderColor:
        backgroundStyle !== 'color' ? 'border-base-color-bg-accent' : undefined,
    },
    success: {
      titleColor: 'text-base-color-fg-success',
      descriptionColor:
        backgroundStyle === 'color'
          ? 'text-base-color-fg-success'
          : 'text-base-color-fg-default',
      iconColor: 'text-base-color-fg-success',
      backgroundColor:
        backgroundStyle === 'color'
          ? 'bg-base-color-bg-success'
          : 'bg-base-color-bg-default',
      icon: CheckCircleIcon,
      linkStyle: 'success',
      borderColor:
        backgroundStyle !== 'color'
          ? 'border-base-color-bg-success'
          : undefined,
    },
  }
  const selectedStyle = styles[style]
  const Icon = selectedStyle.icon
  return (
    <Box
      data-testid={testId}
      color={selectedStyle.backgroundColor}
      className={clsx('rounded-md p-4')}
      border={!!selectedStyle.borderColor}
      borderColor={selectedStyle.borderColor}
      {...rest}
    >
      <StackView gap={100}>
        <StackView direction="row" space={50}>
          <Box className="flex-shrink-0">
            <Icon
              className={clsx('h-5 w-5', selectedStyle.iconColor)}
              aria-hidden="true"
            />
          </Box>
          <StackView>
            <Typography
              size="s"
              fontWeight="medium"
              color={selectedStyle.titleColor}
            >
              {title}
            </Typography>
            {!!description && (
              <Box className="mt-2 whitespace-pre-wrap">
                <Typography color={selectedStyle.descriptionColor} size="s">
                  {description}
                </Typography>
              </Box>
            )}
          </StackView>
          {action && <Box>{action}</Box>}
          {dismiss && (
            <Button onClick={dismiss} icon={XMarkIcon} buttonStyle="ghost" />
          )}
        </StackView>

        {link ? (
          <StackView className="pl-core-space-175">
            <AnchorLink linkStyle={selectedStyle.linkStyle} {...link} />
          </StackView>
        ) : null}
      </StackView>
    </Box>
  )
}

export default Alert
