const NoSearchResultsIcon = () => {
  return (
    <svg
      width="183"
      height="149"
      viewBox="0 0 183 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="94" cy="67" r="64" fill="#EAECF0" />
      <ellipse
        cx="31.7634"
        cy="12.678"
        rx="5.65789"
        ry="5.67797"
        fill="#F2F4F7"
      />
      <ellipse
        cx="28.3684"
        cy="123.779"
        rx="7.92105"
        ry="7.94915"
        fill="#F2F4F7"
      />
      <ellipse
        cx="172.079"
        cy="86.9492"
        rx="7.92105"
        ry="7.94915"
        fill="#F2F4F7"
      />
      <ellipse
        cx="159.632"
        cy="9.08436"
        rx="4.52632"
        ry="4.54237"
        fill="#F2F4F7"
      />
      <g filter="url(#filter0_dd_2815_10646)">
        <path
          d="M52.2491 108.601L93.1857 89.6765C94.8571 88.9039 95.5767 86.9267 94.793 85.2605L71.0458 34.7738L55.1457 29.0365L22.0264 44.3471C20.3551 45.1197 19.6355 47.0968 20.4192 48.7631L47.8037 106.983C48.5875 108.649 50.5777 109.374 52.2491 108.601Z"
          fill="url(#paint0_linear_2815_10646)"
        />
        <path
          d="M55.1462 29.0371L71.0464 34.7744L60.2027 39.7873L55.1462 29.0371Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter1_dd_2815_10646)">
        <path
          d="M71.1903 94.8963H116.369C118.21 94.8963 119.703 93.4036 119.703 91.5623V35.8615L107.738 24H71.1903C69.3489 24 67.8562 25.4927 67.8562 27.3341V91.5623C67.8562 93.4036 69.3489 94.8963 71.1903 94.8963Z"
          fill="url(#paint1_linear_2815_10646)"
        />
        <path d="M107.738 24L119.703 35.8615H107.738V24Z" fill="#D0D5DD" />
      </g>
      <g filter="url(#filter2_dd_2815_10646)">
        <path
          d="M93.8148 89.6529L134.751 108.577C136.423 109.35 138.413 108.626 139.197 106.959L162.944 56.4727L157.157 40.7096L124.037 25.399C122.366 24.6264 120.376 25.3508 119.592 27.017L92.2075 85.2369C91.4238 86.9032 92.1434 88.8803 93.8148 89.6529Z"
          fill="url(#paint2_linear_2815_10646)"
        />
        <path
          d="M157.157 40.71L162.944 56.473L152.101 51.4601L157.157 40.71Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter3_b_2815_10646)">
        <rect
          x="66"
          y="68"
          width="56"
          height="56"
          rx="28"
          fill="#344054"
          fillOpacity="0.4"
        />
        <path
          d="M106.708 107.293L100.449 101.035C102.263 98.8574 103.167 96.064 102.974 93.2362C102.781 90.4084 101.505 87.7639 99.4122 85.8527C97.319 83.9416 94.5696 82.9111 91.7359 82.9755C88.9023 83.0399 86.2025 84.1943 84.1983 86.1985C82.194 88.2027 81.0397 90.9025 80.9753 93.7362C80.9109 96.5698 81.9414 99.3193 83.8525 101.412C85.7636 103.506 88.4081 104.782 91.236 104.975C94.0638 105.168 96.8572 104.263 99.035 102.449L105.293 108.708C105.385 108.801 105.496 108.874 105.617 108.925C105.739 108.975 105.869 109.001 106 109.001C106.131 109.001 106.262 108.975 106.383 108.925C106.504 108.874 106.615 108.801 106.708 108.708C106.8 108.615 106.874 108.505 106.924 108.383C106.975 108.262 107.001 108.132 107.001 108C107.001 107.869 106.975 107.739 106.924 107.617C106.874 107.496 106.8 107.386 106.708 107.293ZM83 94.0003C83 92.2203 83.5279 90.4802 84.5168 89.0002C85.5057 87.5201 86.9114 86.3666 88.5559 85.6854C90.2004 85.0042 92.01 84.826 93.7559 85.1732C95.5017 85.5205 97.1053 86.3777 98.364 87.6363C99.6227 88.895 100.48 90.4986 100.827 92.2445C101.174 93.9903 100.996 95.7999 100.315 97.4444C99.6338 99.089 98.4802 100.495 97.0002 101.484C95.5201 102.472 93.7801 103 92 103C89.6139 102.998 87.3263 102.049 85.639 100.361C83.9517 98.6741 83.0027 96.3864 83 94.0003Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2815_10646"
          x="-0.999878"
          y="24.0234"
          width="117.212"
          height="125.977"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2815_10646"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2815_10646"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_2815_10646"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2815_10646"
            result="effect2_dropShadow_2815_10646"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2815_10646"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_2815_10646"
          x="47.8562"
          y="24"
          width="91.8469"
          height="110.896"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2815_10646"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2815_10646"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_2815_10646"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2815_10646"
            result="effect2_dropShadow_2815_10646"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2815_10646"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_2815_10646"
          x="70.7885"
          y="24"
          width="117.212"
          height="125.977"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2815_10646"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2815_10646"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_2815_10646"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2815_10646"
            result="effect2_dropShadow_2815_10646"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2815_10646"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_2815_10646"
          x="58"
          y="60"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2815_10646"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2815_10646"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2815_10646"
          x1="50.5014"
          y1="108.483"
          x2="19.2336"
          y2="51.5828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2815_10646"
          x1="69.6618"
          y1="94.0571"
          x2="65.181"
          y2="29.4153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2815_10646"
          x1="92.7826"
          y1="88.2498"
          x2="115.854"
          y2="27.6256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default NoSearchResultsIcon
