import { Fragment, useEffect, useRef, useState } from 'react'

import { useFloating, flip, offset, autoUpdate, size } from '@floating-ui/react'
import {
  CheckIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import { useOnClickOutside } from 'usehooks-ts'

import { useFormContext } from '@redwoodjs/forms'

import Button from 'src/components/atoms/Button/Button'
import Checkbox, { CheckboxField } from 'src/components/atoms/Checkbox/Checkbox'
import { Input, InputFieldProps } from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

export type TypeAheadOption = {
  label: string
  value: string
  supportingLabel?: string
  headerKey?: string
  headerLabel?: string
  disabled?: boolean
  synonyms?: string[]
}

export type TypeaheadProps = InputFieldProps & {
  name?: string
  options: TypeAheadOption[]
  isFormElement?: boolean
  includeCheckbox?: boolean
  onSelectDropdownItem?: (event: Event, value: string) => void
  onRemoveDropdownItem?: (event: Event, value: string) => void
  selectedValues?: string[]
  multiOptionSelect?: boolean
}

type TypeaheadDropdownItemProps = TypeAheadOption & {
  includeCheckbox?: boolean
  name: string
  isFormElement: boolean
  onSelectDropdownItem?: (event: Event, value: string) => void
  onRemoveDropdownItem?: (event: Event, value: string) => void
  setShowDropdown?: (show: boolean) => void
  selectedValues?: string[]
  multiOptionSelect?: boolean
  closeDropdown?: () => void
}

export const TypeaheadField = ({ ...rest }: TypeaheadProps) => {
  return <Typeahead {...rest} isFormElement />
}

const dropdownClasses = [
  'py-base-space-selectable-inset-xs',
  'rounded-base-border-radius-container-l',
  'border-base-border-width-container-s',
  'border-base-color-border-subtle',
  'bg-base-color-bg-default',
  'bg-opacity-80 backdrop-blur',
  'shadow-base-box-shadow-container-m',
  'max-h-core-size-2000 overflow-y-auto',
  'z-50',
]

const dropdownItemClasses = [
  'cursor-pointer',
  'p-base-space-selectable-inset-s',
  'rounded-base-border-radius-selectable-s',
  'text-base-color-fg-muted',

  'hover:bg-base-color-bg-subtle',
  'hover:text-base-color-fg-default',
]

const selectedOptionClasses = [
  'rounded-base-border-radius-selectable-s',
  'border-base-border-width-container-s',
  'border-base-color-border-subtle',
  'bg-base-color-bg-default',
  'px-core-space-75',
  'py-core-space-25',
]

const TypeaheadDropdownHeader = ({ label }: { label: string }) => {
  return (
    <div className="px-base-space-selectable-inset-xs">
      <div className="px-base-space-selectable-inset-s pb-base-space-selectable-inset-xs pt-base-space-selectable-inset-m">
        <Typography
          textStyle="interface-strong-xs"
          color="text-base-color-fg-subtle"
        >
          {label}
        </Typography>
      </div>
    </div>
  )
}

const TypeaheadDropdownItem = ({
  label,
  value,
  supportingLabel,
  includeCheckbox,
  name,
  disabled = false,
  isFormElement,
  selectedValues,
  setShowDropdown,
  onSelectDropdownItem,
  onRemoveDropdownItem,
  multiOptionSelect = true,
}: TypeaheadDropdownItemProps) => {
  const formMethods = useFormContext()

  let isChecked: boolean
  let formValues: string[] | string
  if (isFormElement) {
    formValues = formMethods.watch(name)
    isChecked = Array.isArray(formValues)
      ? formValues.some((o) => o === value)
      : formValues === value
  } else {
    isChecked = selectedValues?.some((o) => o === value)
  }

  const onClick = (event) => {
    if (disabled) return
    if (!isFormElement) {
      if (!isChecked) {
        onSelectDropdownItem?.(event, value)
      } else {
        onRemoveDropdownItem?.(event, value)
      }
      return
    }
    if (multiOptionSelect && Array.isArray(formValues)) {
      if (!isChecked) {
        formMethods.setValue(name, [...formValues, value])
      } else {
        formMethods.setValue(
          name,
          formValues.filter((v) => v !== value)
        )
      }
    } else {
      if (!isChecked) {
        formMethods.setValue(name, value)
        setShowDropdown(false)
      } else {
        formMethods.setValue(name, null)
      }
    }
  }

  return (
    <div className="px-base-space-selectable-inset-xs">
      <StackView
        testId={`${name}DropdownItem`}
        direction="row"
        alignItems="center"
        justifyContent="between"
        className={clsx(
          dropdownItemClasses,
          disabled && '!pointer-events-none'
        )}
        onClick={onClick}
      >
        <StackView
          direction="row"
          alignItems="center"
          space={50}
          className="grow"
        >
          {isFormElement && includeCheckbox ? (
            <CheckboxField
              name={name}
              value={value}
              checked={isChecked}
              // Intentionally empty function to prevent the checkbox from triggering a check on its own
              onChange={() => {}}
              disabled={disabled}
              className="!items-center"
            />
          ) : includeCheckbox ? (
            <Checkbox
              name={name}
              value={value}
              checked={isChecked}
              disabled={disabled}
              className="!items-center"
            />
          ) : undefined}

          <Typography textStyle="interface-default-s">{label}</Typography>

          {supportingLabel && (
            <Typography
              textStyle="interface-default-s"
              color="text-base-color-fg-subtle"
            >
              {supportingLabel}
            </Typography>
          )}
        </StackView>

        {isChecked && !includeCheckbox && (
          <div className="justify-self-end">
            <CheckIcon className="h-base-size-icon-xs w-base-size-icon-xs grow fill-base-color-fg-brand" />
          </div>
        )}
      </StackView>
    </div>
  )
}

const TypeaheadSelectedOption = ({
  label,
  value,
  supportingLabel,
  name,
  isFormElement,
  onRemoveDropdownItem,
}: TypeaheadDropdownItemProps) => {
  const formMethods = useFormContext()
  let formValues: string[]
  if (isFormElement) {
    formValues = formMethods.watch(name)
  }

  return (
    <StackView
      key={value}
      direction="row"
      alignItems="center"
      justifyContent="between"
      space={75}
      className={clsx(selectedOptionClasses)}
    >
      <Typography
        textStyle="interface-default-s"
        color="text-base-color-fg-muted"
      >
        {label}
      </Typography>
      {supportingLabel && (
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
          className="grow"
        >
          {supportingLabel}
        </Typography>
      )}

      <Button
        buttonStyle="ghost"
        icon={XMarkIcon}
        onClick={
          isFormElement
            ? () =>
                Array.isArray(formMethods.getValues(name))
                  ? formMethods.setValue(
                      name,
                      formValues.filter((v) => v !== value)
                    )
                  : formMethods.setValue(name, null)
            : () => onRemoveDropdownItem?.(event, value)
        }
      />
    </StackView>
  )
}

// parentheses, dashes, whitespace
const PHONE_NUMBER_SYMBOL_REGEX = /[\(\)\-\s]/g

export const doesOptionMatchFilterInput = (
  option: TypeAheadOption,
  filterInput: string
): boolean => {
  const lowerCaseInput = filterInput.toLocaleLowerCase()

  const { label, supportingLabel, headerLabel, synonyms } = option

  const lowerCaseLabels = [label, supportingLabel, headerLabel]
    .filter(Boolean)
    .map((label) => label.toLocaleLowerCase())

  const lowerCaseSynonyms = (synonyms ?? []).map((synonym) =>
    synonym.toLocaleLowerCase()
  )

  return (
    lowerCaseLabels.some((label) => label.includes(lowerCaseInput)) ||
    lowerCaseLabels.some((label) =>
      label
        .replaceAll(PHONE_NUMBER_SYMBOL_REGEX, '')
        .includes(lowerCaseInput.replaceAll(PHONE_NUMBER_SYMBOL_REGEX, ''))
    ) ||
    lowerCaseSynonyms.some((synonym) => synonym.includes(lowerCaseInput))
  )
}

const Typeahead = ({
  name,
  options,
  includeCheckbox,
  isFormElement,
  onSelectDropdownItem,
  onRemoveDropdownItem,
  selectedValues,
  multiOptionSelect = true,
  ...rest
}: TypeaheadProps) => {
  const formMethods = useFormContext()
  const [input, setInput] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [filteredOptions, setFilteredOptions] = useState<TypeAheadOption[]>([])
  const typeaheadRef = useRef(null)
  useOnClickOutside(typeaheadRef, () => setShowDropdown(false))

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      flip(),
      offset(8),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          })
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  useEffect(() => {
    if (showDropdown) {
      if (input !== '') {
        const filtered = options.filter((option) =>
          doesOptionMatchFilterInput(option, input)
        )
        setFilteredOptions(filtered)
      } else {
        setFilteredOptions(options)
      }
    } else {
      setFilteredOptions(options)
    }
  }, [input, options, showDropdown])

  const handleChange = (e) => {
    setInput(e.target.value)
    setShowDropdown(true)
  }

  let selectedOptions: TypeAheadOption[] | TypeAheadOption

  if (isFormElement) {
    const formValues = formMethods.watch(name)
    formMethods.register(name, rest.validation)
    selectedOptions = Array.isArray(formValues)
      ? formValues
          ?.map((value) => options.find((o) => o.value === value))
          .filter(Boolean)
      : options.find((o) => o.value === formValues)
  } else {
    selectedOptions = selectedValues
      ?.map((value) => options.find((o) => o.value === value))
      .filter(Boolean)
  }

  const groupedOptions = groupBy(filteredOptions, ({ headerKey }) => headerKey)
  const groupedKeys = Object.keys(groupedOptions)

  return (
    <StackView>
      <div ref={typeaheadRef}>
        <div ref={refs.setReference}>
          <Input
            name={`${name}Input`}
            testId={`${name}Input`}
            onChange={handleChange}
            onFocus={handleChange}
            value={input}
            iconRight={MagnifyingGlassIcon}
            {...rest}
          />
        </div>
        {showDropdown && filteredOptions?.length > 0 && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={clsx(dropdownClasses)}
          >
            <StackView>
              {groupedKeys.map((key, index) => (
                <Fragment key={key}>
                  {shouldShowDropdownHeader({ index, key }) && (
                    <TypeaheadDropdownHeader
                      label={
                        key === 'undefined'
                          ? undefined
                          : groupedOptions[key][0]?.headerLabel
                      }
                    />
                  )}
                  {groupedOptions[key].map(
                    ({ label, value, supportingLabel, disabled }) => (
                      <TypeaheadDropdownItem
                        key={value}
                        label={label}
                        value={value}
                        name={name}
                        disabled={disabled}
                        includeCheckbox={includeCheckbox}
                        supportingLabel={supportingLabel}
                        isFormElement={isFormElement}
                        setShowDropdown={setShowDropdown}
                        onSelectDropdownItem={onSelectDropdownItem}
                        onRemoveDropdownItem={onRemoveDropdownItem}
                        selectedValues={selectedValues}
                        multiOptionSelect={multiOptionSelect}
                        closeDropdown={() => {
                          setFilteredOptions([])
                        }}
                      />
                    )
                  )}
                </Fragment>
              ))}
            </StackView>
          </div>
        )}
      </div>

      {selectedOptions && (
        <StackView space={25} className="mt-core-space-75">
          {Array.isArray(selectedOptions) ? (
            selectedOptions.map(({ label, value, supportingLabel }) => (
              <TypeaheadSelectedOption
                key={value}
                name={name}
                value={value}
                label={label}
                supportingLabel={supportingLabel}
                isFormElement={isFormElement}
                onRemoveDropdownItem={onRemoveDropdownItem}
              />
            ))
          ) : (
            <TypeaheadSelectedOption
              key={selectedOptions.value}
              name={name}
              value={selectedOptions.value}
              label={selectedOptions.label}
              supportingLabel={selectedOptions.supportingLabel}
              isFormElement={isFormElement}
              onRemoveDropdownItem={onRemoveDropdownItem}
            />
          )}
        </StackView>
      )}
    </StackView>
  )
}

const shouldShowDropdownHeader = ({
  key,
  index,
}: {
  key: string
  index: number
}) => {
  if (index > 0 || (index === 0 && key !== 'undefined')) return true
  return false
}

export default Typeahead
