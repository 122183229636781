import { useEffect } from 'react'

import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  DiagnosisCode,
  DiagnosisCodeFilter,
  GetEncounterForVisitDiagnosisEdit,
  GetEncounterForVisitDiagnosisEditVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { referenceToId } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/CompleteQuestionnaireOrderSidepanelForm'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import {
  useDeleteConditionMutation,
  useUpdateVisitDiagnosisMutation,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = {
  isPrimary: boolean
  code: DiagnosisCode
  codeSearchFilter: DiagnosisCodeFilter[]
  codeSearchInput: string
  comment: string
}

const QUERY = gql`
  query GetEncounterForVisitDiagnosisEdit($encounterId: String!) {
    encounter(id: $encounterId) {
      id
      patientReference
      primaryDiagnosis {
        id
        code {
          code
          display
          system
        }
        comment
      }
      additionalDiagnoses {
        id
        code {
          code
          display
          system
        }
        comment
      }
    }
  }
`

const SidepanelVisitDiagnosisEditHelper = ({
  encounter,
}: {
  encounter: GetEncounterForVisitDiagnosisEdit['encounter']
}) => {
  const { currentUser } = useEmrAuth()

  const { encounterId, conditionId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const patientId = referenceToId(encounter.patientReference)

  const { condition, isPrimary } =
    conditionId === encounter?.primaryDiagnosis?.id
      ? { condition: encounter?.primaryDiagnosis, isPrimary: true }
      : {
          condition: encounter?.additionalDiagnoses?.find(
            (diagnosis) => diagnosis.id === conditionId
          ),
          isPrimary: false,
        }

  const formMethods = useForm<FormState>({
    defaultValues: {
      code: {
        code: condition?.code?.code,
        description: condition?.code?.display,
      },
      isPrimary,
      comment: condition?.comment,
    },
  })

  const searchFilter = formMethods.watch('codeSearchFilter')

  useEffect(() => {
    formMethods.reset({
      code: {
        code: condition?.code?.code,
        description: condition?.code?.display,
      },
      isPrimary,
      codeSearchFilter: searchFilter,
      comment: condition?.comment,
    })
    // only reset form when conditionId changes (someone clicks on a different row )
    // form input updates should result in regular form interaction updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionId])

  const [updateVisitDiagnosis, { loading: updatingVisitDiagnosis }] =
    useUpdateVisitDiagnosisMutation()
  const [deleteVisitDiagnosis, { loading: isDeletingVisitDiagnosis }] =
    useDeleteConditionMutation()

  const onSubmit = (data: FormState) => {
    void updateVisitDiagnosis({
      variables: {
        encounterId,
        conditionId,
        diagnosisCodeId: data.code.id,
        isPrimary: data.isPrimary,
        comment: data.comment,
      },
      onCompleted: () => {
        toast.success('Visit diagnosis updated')
        closeSidePanel()
      },
      refetchQueries: ['FindPatientOrders'],
    })
  }

  const allowDiagnosisComment = currentUser.featureFlags.includes(
    'DIAGNOSIS_AND_ZCODE_COMMENTS'
  )
  return (
    <SidepanelPage header="Edit diagnosis" key={condition?.code?.code}>
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          cancelText: 'Cancel',
          submitting: updatingVisitDiagnosis,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={compact([
            {
              name: 'code',
              label: 'Diagnosis code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                encounterId,
                searchSet: 'ALL',
              },
            },
            {
              name: 'isPrimary',
              label: '',
              hideLabel: true,
              direction: 'col',
              formInputComponent: CheckboxField,
              inputProps: {
                label:
                  'This is the primary diagnosis. If there is an existing primary diagnosis, it will be replaced by this one.',
              },
            },
            allowDiagnosisComment && {
              name: 'comment',
              label: 'Comment',
              direction: 'col',
              formInputComponent: WysiwygField,
              inputProps: {
                testId: 'edit-visit-diagnosis-comment',
                defaultValue: condition?.comment,
              },
            },
          ])}
        />
        <DestructiveAction
          title="Destructive actions"
          description="These changes are permanent, use caution when removing."
          buttonText="Remove diagnosis"
          doDestructiveAction={async () => {
            await deleteVisitDiagnosis({
              variables: { conditionId },
              onCompleted: () => {
                toast.success('Visit diagnosis removed')
                closeSidePanel()
              },
            })
          }}
          destructiveActionIsLoading={isDeletingVisitDiagnosis}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

const SidepanelVisitDiagnosisEdit = () => {
  const { encounterId } = useParams()
  const { data } = useQuery<
    GetEncounterForVisitDiagnosisEdit,
    GetEncounterForVisitDiagnosisEditVariables
  >(QUERY, {
    variables: {
      encounterId,
    },
  })

  if (!data) return null

  return <SidepanelVisitDiagnosisEditHelper encounter={data.encounter} />
}

export default SidepanelVisitDiagnosisEdit
