import { useApolloClient } from '@apollo/client'
import { LocalDate } from '@js-joda/core'
import { match } from 'ts-pattern'
import {
  PatientItemizedBillingHistory,
  PatientItemizedBillingHistoryVariables,
  PatientPayments,
  PatientPaymentsVariables,
  PatientPortalPatientItemizedBillingHistory,
  PatientPortalPatientItemizedBillingHistoryVariables,
} from 'types/graphql'

import { PATIENT_ITEMIZED_BILLING_HISTORY_QUERY } from 'src/hooks/usePatientBillingStatement/usePatientBillingStatement'
import { PATIENT_PAYMENTS_QUERY } from 'src/hooks/usePatientPayments/usePatientPayments'

import PrintPatientDetailedPaymentHistory from './components/PrintPatientDetailedPaymentHistory'
import PrintPatientItemizedBillingHistory from './components/PrintPatientItemizedBillingHistory'
import { useGeneratePDF } from './useGeneratePDF'

type PatientDocumentInput = {
  type: 'PAYMENT_HISTORY' | 'BILLING_HISTORY' | 'PORTAL_BILLING_HISTORY'
  startDate: LocalDate
  endDate: LocalDate
}

export const useGeneratePatientDocument = () => {
  const generatePdf = useGeneratePDF()

  const client = useApolloClient()

  return async ({
    patientId,
    input,
  }: {
    patientId: string
    input: PatientDocumentInput
  }): Promise<URL> => {
    const component = await match(input)
      .with({ type: 'PAYMENT_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientPayments,
          PatientPaymentsVariables
        >({
          query: PATIENT_PAYMENTS_QUERY,
          variables: {
            id: patientId,
          },
        })

        return (
          <PrintPatientDetailedPaymentHistory
            paymentsData={data}
            startDate={args.startDate}
            endDate={args.endDate}
            generatedAt={new Date()}
            tenant={data.patient.tenant}
          />
        )
      })
      .with({ type: 'BILLING_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientItemizedBillingHistory,
          PatientItemizedBillingHistoryVariables
        >({
          query: PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
          variables: {
            patientId,
            filters: {
              startDate: args.startDate.toString(),
              endDate: args.endDate.toString(),
            },
          },
        })

        return (
          <PrintPatientItemizedBillingHistory
            billingStatement={data.billingStatement}
            endDate={args.endDate}
            generatedAt={new Date()}
            patient={data.patient}
            startDate={args.startDate}
          />
        )
      })
      .with({ type: 'PORTAL_BILLING_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientPortalPatientItemizedBillingHistory,
          PatientPortalPatientItemizedBillingHistoryVariables
        >({
          query: PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
          variables: {
            patientId,
            filters: {
              startDate: args.startDate.toString(),
              endDate: args.endDate.toString(),
            },
          },
        })

        return (
          <PrintPatientItemizedBillingHistory
            billingStatement={data.patientPortalBillingHistory}
            endDate={args.endDate}
            generatedAt={new Date()}
            patient={data.patientPortalPatient}
            startDate={args.startDate}
          />
        )
      })
      .exhaustive()

    return new Promise((resolve) => {
      void generatePdf({
        component,
        callback: (doc) => {
          resolve(doc.output('bloburl'))
        },
      })
    })
  }
}
