import clsx from 'clsx'
import { match } from 'ts-pattern'

import StackView from 'src/components/atoms/StackView/StackView'

export type ListRowItemSize = 's' | 'l'

export interface ListRowItemProps {
  size?: ListRowItemSize
  justifyContent?: 'between' | 'start' | 'end'
  alignItems?: 'center' | 'start'
  className?: string
  children: React.ReactNode
  testId?: string
}

export const ListRowItem = (props: ListRowItemProps) => {
  const { className, children, testId } = props
  const size = props.size ?? 'l'
  const justifyContent = props.justifyContent ?? 'between'
  const alignItems = props.alignItems ?? 'center'

  return (
    <StackView
      direction="row"
      testId={testId}
      gap={100}
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={clsx(
        'px-core-space-100',
        'min-h-core-space-400',
        match(size)
          .with('l', () => 'py-core-space-75')
          .with('s', () => 'py-core-space-50')
          .exhaustive(),
        className
      )}
    >
      {children}
    </StackView>
  )
}
