import { useState } from 'react'

import { PlusIcon, BoltIcon } from '@heroicons/react/20/solid'
import { caseStatusFilterOptions } from 'common/data/baseCases'
import { useParams as useSidepanelParams } from 'react-router-dom'
import { CaseStatusFilter } from 'types/graphql'

import { Form } from '@redwoodjs/forms'
import { useParams, navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import PatientTaskListCell from 'src/components/PatientTaskListCell'
import { TaskAssignmentMultiSelectDropdownField } from 'src/components/TaskAssignmentMultiSelectDropdownField/TaskAssignmentMultiSelectDropdownField'
import { sidepanelRoute } from 'src/lib/routes'

const SidepanelPatientTasks = () => {
  const location = useLocation()
  const params = useParams()

  const { patientId } = useSidepanelParams()
  const [isStatusFilterOpen, setStatusFilterOpen] = useState(false)
  const [selectedStatuses, setSelectedStatuses] = useState<CaseStatusFilter[]>([
    'DUE',
    'OVERDUE',
    'UPCOMING',
  ])

  const [isUserFilterOpen, setUserFilterOpen] = useState(false)

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [selectedUserPoolIds, setSelectedUserPoolIds] = useState<string[]>([])

  return (
    <SidepanelPage
      header="Patient tasks"
      description="Overview of all selected tasks for the patient."
    >
      <StackView
        direction="col"
        space={100}
        className="px-core-space-150 py-core-space-100 "
      >
        <StackView direction="row" justifyContent="end" gap={50} wrap>
          <Form>
            <MultiSelectDropdownField
              testId="task-status-filter"
              name="caseStatusFilter"
              icon={BoltIcon}
              urlParamName="caseStatuses"
              emptyDisplayText="Case Status"
              options={caseStatusFilterOptions}
              defaultValue={selectedStatuses}
              open={isStatusFilterOpen}
              onToggle={setStatusFilterOpen}
              onToggleFilter={(values) =>
                setSelectedStatuses(values as CaseStatusFilter[])
              }
              onClearFilter={() => setSelectedStatuses([])}
              optionsClassName="w-core-space-1000"
            />
          </Form>
          <Form>
            <TaskAssignmentMultiSelectDropdownField
              isOpen={isUserFilterOpen}
              setIsOpen={setUserFilterOpen}
              defaultValue={[]}
              onSelect={(data) => {
                setSelectedUserIds(data.userIds)
                setSelectedUserPoolIds(data.userPoolIds)
              }}
              onClear={() => {
                setSelectedUserIds([])
                setSelectedUserPoolIds([])
              }}
            />
          </Form>
          <Button
            testId="create-case-btn"
            text="Create task"
            icon={PlusIcon}
            buttonStyle="secondary"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: '/task/create',
                    patientId,
                  },
                  location,
                  params
                )
              )
            }
          />
        </StackView>
        <PatientTaskListCell
          filters={{
            patientId,
            statuses: selectedStatuses,
            userIds: selectedUserIds,
            taskUserPoolIds: selectedUserPoolIds,
          }}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientTasks
