import { useEffect, useState } from 'react'

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useDebounceCallback } from 'usehooks-ts'

import { Controller, RegisterOptions, useFormContext } from '@redwoodjs/forms'

import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import {
  DropdownV2Option,
  Menu,
  MenuProps,
} from 'src/components/atoms/DropdownV2/DropdownV2'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

import SidepanelPortal from '../SidepanelPortal/SidepanelPortal'

const DEBOUNCE_MS = 400

type AdvancedSearchV2Props = {
  addMoreButtonText?: string
  defaultSearching?: boolean
  name: string
  options: DropdownV2Option[]
  selectedOptionDisplayProps?: Omit<
    SelectedOptionDisplayProps,
    'onClickEdit' | 'option'
  >
  sidepanelProps?: Omit<
    SidepanelProps,
    'isOpen' | 'onClosePortal' | 'options' | 'setValue'
  >
  testId?: string
  validation?: RegisterOptions
}

type SidepanelProps = {
  defaultMenuFilters?: string[]
  defaultValues?: string[]
  isOpen: boolean
  onCloseSidepanelGoToPreviousRoute?: boolean
  onClosePortal: () => void
  onUpdateMenuFilters?: (values: string[]) => void
  onUpdateSearchInput?: (value: string) => void
  options: DropdownV2Option[]
  setValue: (values: string[]) => void
  sidepanelDescription?: string
  sidepanelHeader: string
  testId?: string
  menuProps?: Omit<MenuProps, 'isOpen' | 'options' | 'selectedOptions'>
}

type SelectedOptionDisplayProps = {
  editText?: string
  onClickEdit: () => void
  onClickRemoveSelectedOption: (value: string) => void
  option: DropdownV2Option
}

const SelectedOptionDisplay = ({
  editText = 'Edit',
  onClickEdit,
  onClickRemoveSelectedOption,
  option,
}: SelectedOptionDisplayProps) => {
  return (
    <StackView
      alignItems="center"
      className="max-w-full"
      direction="row"
      gap={50}
    >
      <Card className="w-full" padding={100}>
        <StackView alignItems="center" direction="row" gap={25}>
          <StackView>
            <Typography
              className="truncate"
              color="text-comp-textfield-color-clean-enabled-content"
              textStyle="interface-default-s"
            >
              {option.label}
            </Typography>
            {option.secondaryLabel && (
              <Typography
                className="truncate"
                color="text-comp-textfield-color-clean-enabled-placeholder"
                textStyle="interface-default-xs"
              >
                {option.secondaryLabel}
              </Typography>
            )}
          </StackView>
          <Button
            className="!p-core-space-0"
            buttonStyle="link"
            onClick={onClickEdit}
            text={editText}
          />
        </StackView>
      </Card>
      <Button
        buttonStyle="ghost"
        icon={XMarkIcon}
        onClick={() => onClickRemoveSelectedOption(option.value)}
      />
    </StackView>
  )
}

const Sidepanel = ({
  defaultMenuFilters,
  defaultValues,
  isOpen,
  menuProps,
  onClosePortal,
  onCloseSidepanelGoToPreviousRoute = false,
  onUpdateMenuFilters,
  onUpdateSearchInput,
  options,
  setValue,
  sidepanelDescription,
  sidepanelHeader,
  testId,
}: SidepanelProps) => {
  const formMethods = useFormContext()
  const [stagedValues, setStagedValues] = useState<string[]>(
    defaultValues ?? []
  )
  const [selectedMenuFilterValues, setSelectedMenuFilterValues] = useState<
    string[]
  >(defaultMenuFilters ?? [])

  const debouncedSearchInput = useDebounceCallback(
    (value) => onUpdateSearchInput?.(value),
    DEBOUNCE_MS
  )
  const debouncedSelectedMenuFilters = useDebounceCallback(
    (values) => onUpdateMenuFilters?.(values),
    DEBOUNCE_MS
  )

  const stagedOptions =
    stagedValues
      ?.map((value) => options.find((o) => o.value === value))
      .filter(Boolean) ?? []

  const onClickMenuItem = (value: string) => {
    const isSelectedOption = stagedValues?.includes(value)

    if (!isSelectedOption) {
      setStagedValues([...stagedValues, value])
    } else {
      setStagedValues(stagedValues.filter((v) => v !== value))
    }
  }

  const onClickMenuFilter = (value: string) => {
    const isSelectedMenuFilter = selectedMenuFilterValues?.includes(value)

    let values: string[] = []
    if (!isSelectedMenuFilter) {
      values = [...selectedMenuFilterValues, value]
    } else {
      values = selectedMenuFilterValues.filter((v) => v !== value)
    }

    setSelectedMenuFilterValues(values)
  }

  useEffect(() => {
    if (isOpen) {
      setStagedValues(defaultValues ?? [])
    } else {
      setStagedValues([])
    }
  }, [defaultValues, isOpen])

  useEffect(() => {
    debouncedSelectedMenuFilters(selectedMenuFilterValues)
  }, [debouncedSelectedMenuFilters, selectedMenuFilterValues])

  return (
    <SidepanelPortal
      portalContext={{
        closePortal: onClosePortal,
        onCloseSidepanelGoToPreviousRoute,
        width: 'small',
      }}
      showSidepanelPortal={isOpen}
    >
      <SidepanelPage
        description={sidepanelDescription}
        header={sidepanelHeader}
        testId={`${testId}-sidepanel`}
      >
        <SidepanelForm
          containerClassName="!px-core-space-0"
          footerElement={
            <StackView direction="row" gap={50} justifyContent="end">
              <Button
                buttonStyle="secondary"
                onClick={onClosePortal}
                text="Cancel"
              />
              <Button onClick={() => setValue(stagedValues)} text="Save" />
            </StackView>
          }
          formMethods={formMethods}
        >
          <Menu
            floatSelectedToTop
            isMultiSelect
            isOpen
            menuClassName={clsx([
              'max-h-full',
              'max-w-full',
              'rounded-none',
              'border-x-0',
            ])}
            onClickMenuFilter={onClickMenuFilter}
            onClickMenuItem={onClickMenuItem}
            onUpdateSearchInput={debouncedSearchInput}
            options={options}
            selectedMenuFilterValues={selectedMenuFilterValues}
            selectedOptions={stagedOptions}
            showEmptySearchResultsMessage
            showMenuItemCheckbox
            showMenuItemSelectedOptionIcon={false}
            showMenuSearch
            {...menuProps}
          />
        </SidepanelForm>
      </SidepanelPage>
    </SidepanelPortal>
  )
}

export const AdvancedSearchV2Field = ({
  addMoreButtonText = 'Add more',
  defaultSearching,
  name,
  options,
  selectedOptionDisplayProps,
  sidepanelProps,
  testId,
  validation,
}: AdvancedSearchV2Props) => {
  const formMethods = useFormContext()
  const [isEditing, setIsEditing] = useState(defaultSearching ?? false)

  const onClosePortal = () => setIsEditing(false)

  return (
    <Controller
      control={formMethods.control}
      name={name}
      rules={validation}
      render={({ field }) => {
        const value = Array.isArray(field.value)
          ? field.value
          : field.value
          ? [field.value]
          : []
        const selectedOptions = value
          .map((value) => options.find((o) => o.value === value))
          .filter(Boolean)

        return (
          <>
            {selectedOptions?.length ? (
              <StackView gap={50}>
                {selectedOptions?.map((option) => (
                  <SelectedOptionDisplay
                    key={option.value}
                    onClickEdit={() => setIsEditing(true)}
                    onClickRemoveSelectedOption={(valueToRemove) => {
                      field.onChange(value.filter((v) => v !== valueToRemove))
                    }}
                    option={option}
                    {...selectedOptionDisplayProps}
                  />
                ))}
              </StackView>
            ) : undefined}

            <Button
              buttonStyle="secondary"
              className="mt-core-space-50 w-full"
              icon={PlusIcon}
              onClick={() => setIsEditing(true)}
              text={addMoreButtonText}
            />

            <Sidepanel
              defaultValues={value}
              isOpen={isEditing}
              onClosePortal={onClosePortal}
              options={options}
              setValue={(values) => {
                field.onChange(values)
                onClosePortal()
              }}
              testId={testId}
              {...sidepanelProps}
            />
          </>
        )
      }}
    />
  )
}
