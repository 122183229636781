import { useEffect, useState } from 'react'

import { DocumentNode } from '@apollo/client'
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
  TagIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { RegisterOptions, get, useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import { Option } from 'src/components/atoms/Select/Select'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField/MultiSelectDropdownField'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { useEffectOnce } from 'src/utils'

export type AdvancedSearchFieldProps = {
  placeholder: string
  valueRenderer: (...args: unknown[]) => JSX.Element
  validation?: RegisterOptions
  defaultFilters?: string[]
  defaultSearching?: boolean
} & Omit<TakeoverProps, 'closeSearch'>

type TakeoverProps = {
  name: string
  query: DocumentNode
  queryKey: string
  queryInput?: Record<string, unknown>
  emptyResultMessage: string
  searchPlaceholder: string
  resultRenderer: (...args: unknown[]) => JSX.Element
  filterOptions: Option[]
  closeSearch: () => void
}

const SearchTakeover = ({
  name,
  closeSearch,
  query,
  queryKey,
  searchPlaceholder,
  emptyResultMessage,
  resultRenderer,
  filterOptions,
  queryInput = {},
}: TakeoverProps) => {
  const { closeSidePanel } = useSidepanel()
  const { watch, setValue, setFocus } = useFormContext()
  const [debounceTimer, setDebounceTimer] = useState(undefined)
  const [searchQuery, setSearchQuery] = useState('')
  const [typing, setIsTyping] = useState(false)

  const searchInputName = `${name}SearchInput`
  const searchFilterName = `${name}SearchFilter`
  const searchFilter = watch(searchFilterName)
  const searchInput = watch(searchInputName)

  useEffect(() => {
    if (searchInput === searchQuery) return

    setIsTyping(true)

    if (debounceTimer) {
      clearInterval(debounceTimer)
    }
    setSearchQuery(searchInput)
    setDebounceTimer(
      setTimeout(() => {
        setIsTyping(false)
      }, 400)
    )
  }, [debounceTimer, searchInput, searchInputName, searchQuery])

  useEffectOnce(() => {
    setFocus(searchInputName)
  })

  const { data: searchResults, loading } = useQuery(query, {
    skip: typing,
    variables: {
      input: { searchQuery, ...queryInput },
      filters: searchFilter ?? [],
    },
  })

  return (
    <StackView
      divider
      className="absolute left-0 top-0 z-10 h-full w-full bg-white"
      data-testid="search-takeover"
    >
      <StackView
        direction="row"
        alignItems="center"
        justifyContent="between"
        className="h-12 px-4"
      >
        <Button
          buttonStyle="secondary"
          className="h-6 !px-2"
          onClick={closeSearch}
        >
          <ChevronLeftIcon className="h-4 w-4 pr-1" />
          <span className="whitespace-nowrap">Back</span>
        </Button>
        <Button buttonStyle="ghost" icon={XMarkIcon} onClick={closeSidePanel} />
      </StackView>
      <StackView className="p-4" space={50}>
        <InputField
          autoComplete="off"
          placeholder={searchPlaceholder}
          iconRight={MagnifyingGlassIcon}
          name={searchInputName}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        />
        {!!filterOptions && filterOptions.length > 0 && (
          <StackView fullWidth={false} alignItems="end">
            <MultiSelectDropdownField
              name={searchFilterName}
              options={filterOptions}
              emptyDisplayText="Filter"
              multiSelectDisplayText="filters"
              icon={TagIcon}
            />
          </StackView>
        )}
      </StackView>
      {searchResults?.[queryKey]?.length ? (
        <StackView className="h-full overflow-y-auto">
          {searchResults[queryKey].map((result) => (
            <StackView
              key={result.id}
              data-testid="search-result"
              direction="row"
              className="cursor-pointer px-4 py-3 hover:bg-gray-300"
              alignItems="center"
              onClick={() => {
                setValue(name, result)
                setValue(searchInputName, '')
                closeSearch()
              }}
            >
              {resultRenderer(result)}
            </StackView>
          ))}
        </StackView>
      ) : (
        <StackView
          space={50}
          justifyContent="start"
          alignItems="center"
          className="h-full pt-24"
        >
          {searchQuery && (loading || !searchResults) ? (
            <AreaLoader />
          ) : (
            <>
              <NoSymbolIcon className="h-8 w-8 text-gray-400" />
              <Typography textStyle="subtitle">{emptyResultMessage}</Typography>
              <Typography textStyle="description">
                Sorry, your keyword search and/or filters have returned no
                results.
              </Typography>
            </>
          )}
        </StackView>
      )}
    </StackView>
  )
}

/**
 * @deprecated Use the AdvancedSearchV2Field instead
 */
const AdvancedSearchField = ({
  placeholder,
  valueRenderer,
  name,
  validation,
  defaultFilters,
  defaultSearching,
  ...rest
}: AdvancedSearchFieldProps) => {
  const [isSearching, setIsSearching] = useState(defaultSearching ?? false)
  const { formState, register, watch, setValue } = useFormContext()
  const value = watch(name)

  register(name, validation)

  const hasError = !!get(formState.errors, name)

  useEffectOnce(() => {
    if (defaultFilters) {
      setValue(`${name}SearchFilter`, defaultFilters)
    }
  })

  if (isSearching) {
    return (
      <SearchTakeover
        name={name}
        closeSearch={() => setIsSearching(false)}
        {...rest}
      />
    )
  }

  return (
    <Box
      border
      grow
      className={clsx('rounded-md', hasError && 'border-red-300 text-red-900')}
    >
      <StackView
        data-testid="advanced-search-value"
        direction="row"
        className="cursor-pointer px-3 py-4"
        justifyContent="between"
        alignItems="center"
        onClick={() => setIsSearching(true)}
        space={75}
      >
        {value ? (
          <>
            {valueRenderer(value)}
            <Typography
              color="text-base-color-fg-brand"
              fontWeight="medium"
              size="s"
            >
              Change
            </Typography>
          </>
        ) : (
          <>
            <Typography textStyle="description" size="s">
              {placeholder}
            </Typography>
            <Typography
              color="text-base-color-fg-brand"
              fontWeight="medium"
              size="s"
            >
              Search
            </Typography>
          </>
        )}
      </StackView>
    </Box>
  )
}

export default AdvancedSearchField
