import { GetAutomationRuleSection } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Divider from 'src/components/atoms/Divider/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import EmptyDataContent from 'src/components/molecules/EmptyDataContent'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { automationRuleGroupValidationInstructions } from 'src/data/automationRuleGroupValidation'

import ChargeCaptureRule from './ChargeCaptureRule'

const GET_AUTOMATION_RULE_SECTION = gql`
  query GetAutomationRuleSection($id: String!) {
    automationRuleSection(id: $id) {
      id
      title
      description
      subSections {
        id
        title
        groups {
          id
          validation
          rules {
            id
            title
            isEnabled
          }
        }
      }
    }
  }
`

const useGetAutomationRuleSection = (sectionId: string) =>
  useQuery(GET_AUTOMATION_RULE_SECTION, { variables: { id: sectionId } })

const ChargeCaptureGroup = ({
  group,
}: {
  group: GetAutomationRuleSection['automationRuleSection']['subSections'][0]['groups'][0]
}) => {
  const hasAtLeastOneRuleEnabled = group.rules.some((rule) => rule.isEnabled)
  const disableNonSelectedRules =
    group.validation === 'CHOOSE_ONE' && hasAtLeastOneRuleEnabled
  return (
    <StackView key={group.id} gap={50}>
      <Typography color="text-base-color-fg-muted">
        {automationRuleGroupValidationInstructions[group.validation]}
      </Typography>
      {group.rules.map((rule) => (
        <ChargeCaptureRule
          key={rule.id}
          rule={rule}
          disabled={disableNonSelectedRules && !rule.isEnabled}
        />
      ))}
    </StackView>
  )
}

const ChargeCaptureSubSection = ({
  subSection,
}: {
  subSection: GetAutomationRuleSection['automationRuleSection']['subSections'][number]
}) => {
  return (
    <StackView key={subSection.id} gap={100}>
      <Typography textStyle="title-xs">{subSection.title}</Typography>
      {subSection.groups.map((group) => (
        <ChargeCaptureGroup key={group.id} group={group} />
      ))}
    </StackView>
  )
}

const ChargeCaptureSection = ({ sectionId }: { sectionId: string }) => {
  const { data, loading } = useGetAutomationRuleSection(sectionId)

  if (loading) {
    return <LoadingSpinner />
  }
  if (!data || data.automationRuleSection.subSections.length === 0) {
    return <EmptyDataContent message="No rules found for this section" />
  }

  return (
    <StackView gap={150}>
      <SectionHeader
        title={data.automationRuleSection.title}
        description={data.automationRuleSection.description}
      />
      <StackView gap={150}>
        {data.automationRuleSection.subSections.map((subSection, i) => (
          <>
            <ChargeCaptureSubSection
              key={subSection.id}
              subSection={subSection}
            />
            {i !== data.automationRuleSection.subSections.length - 1 ? (
              <Divider />
            ) : null}
          </>
        ))}
      </StackView>
    </StackView>
  )
}

export default ChargeCaptureSection
