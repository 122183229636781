import { ReactElement } from 'react'

import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { isActiveNestedRoute, routeTo, sidepanelRoute } from 'src/lib/routes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

interface TabNavItemProps {
  name: string
  to: string
  key?: string
  icon?: ReactElement
  extra?: ReactElement
}

interface TabNavProps {
  rootPath: string
  tabs: TabNavItemProps[]
  sidepanel?: boolean
  className?: string
  id?: string
  vertical?: boolean
  style?: 'button' | 'border'
}

export default function TabNav({
  tabs,
  rootPath,
  sidepanel,
  className,
  id,
  vertical,
  style = 'border',
}: TabNavProps): ReactElement {
  const location = useLocation()
  const params = useParams()
  const { sidepanelContext } = useSidepanel()

  return (
    <Tab.Group>
      <Tab.List
        id={id}
        className={clsx(
          'flex',
          vertical ? 'flex-col' : 'flex-row flex-wrap',
          !vertical &&
            style === 'border' &&
            '-mb-px space-x-8 border-b border-gray-200',
          !vertical && style === 'button' && 'space-x-4',
          className
        )}
      >
        {tabs.map((tab) => {
          const destinationRoute = sidepanel
            ? sidepanelRoute(
                {
                  ...sidepanelContext,
                  route: tab.to,
                },
                location,
                params
              )
            : routeTo(tab.to, location)
          const current = sidepanel ? sidepanelContext.route : location.pathname
          const isActive = isActiveNestedRoute(current, tab.to, rootPath)

          if (style === 'border') {
            return (
              <Tab
                disabled={isActive}
                onClick={(e) => {
                  if (e.metaKey) {
                    return window.open(destinationRoute, '_blank')
                  }

                  navigate(destinationRoute)
                }}
                key={tab.key || tab.name}
                data-testid={`${tab.key}-tab`}
                className={clsx(
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                  isActive
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                )}
              >
                <StackView
                  direction="row"
                  space={50}
                  fullWidth={false}
                  justifyContent={vertical ? 'start' : undefined}
                >
                  {tab.icon}
                  <Box>{tab.name}</Box>
                  {tab.extra}
                </StackView>
              </Tab>
            )
          }

          if (style === 'button') {
            return (
              <Tab
                disabled={isActive}
                onClick={(e) => {
                  if (e.metaKey) {
                    return window.open(destinationRoute, '_blank')
                  }

                  navigate(destinationRoute)
                }}
                key={tab.key || tab.name}
                data-testid={`${tab.key}-tab`}
                className={clsx(
                  'whitespace-nowrap text-sm font-medium',
                  isActive
                    ? 'bg-gray-100 !text-gray-900'
                    : 'border-transparent !text-gray-600 hover:text-gray-700'
                )}
                as={Button}
                buttonStyle="ghost"
                justifyContent={vertical ? 'start' : undefined}
              >
                {tab.icon}
                <Typography className="text-left">{tab.name}</Typography>
                {tab.extra}
              </Tab>
            )
          }
        })}
      </Tab.List>
    </Tab.Group>
  )
}
