import { GetAutomationRule } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import Automation from './Automation'

const Automations = ({
  automations,
}: {
  automations: GetAutomationRule['automationRule']['automations']
}) => {
  return (
    <StackView space={75} className="py-6">
      <Typography textStyle="title-xs">Automation</Typography>
      <StackView space={25}>
        {automations?.map((automation, index) => (
          <Automation key={index} automation={automation} />
        ))}
      </StackView>
    </StackView>
  )
}

export default Automations
