import { deliveryMethodConceptCodings } from 'common/cdr/concepts/observations/birthHistories/index'
import { DeliveryMethod } from 'types/graphql'

import { codeToEnumBase, nameValueArrayFromObject } from './utils'

export const deliveryMethodDisplay: {
  [key in DeliveryMethod]: string
} = {
  VAGINAL: 'Vaginal birth',
  ASSISTED_VAGINAL: 'Assisted vaginal birth',
  CESAREAN: 'Cesarean section',
  VBAC: 'Vaginal birth after C-section (VBAC)',
}

export const deliveryMethodDisplayToEnum = Object.keys(
  deliveryMethodDisplay
).reduce((acc, key) => {
  acc[deliveryMethodDisplay[key]] = key
  return acc
}, {})

export const deliveryMethodCodeToEnum = deliveryMethodConceptCodings.reduce(
  (acc, coding) => {
    if (deliveryMethodDisplayToEnum[coding.display]) {
      acc[coding.code] = deliveryMethodDisplayToEnum[coding.display]
    }
    return acc
  },
  codeToEnumBase
)

export const deliveryMethods = nameValueArrayFromObject<DeliveryMethod>(
  deliveryMethodDisplay
)
