import { useParams } from 'react-router-dom'
import { GetAutomationRule, TextItemWithBulletPoints } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge'
import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { CATEGORY_INFO_BY_CODE } from 'src/pages/ChargeCaptureSettingsPage/ruleCategoryDefinitions'

import Automations from './components/Automations'

const GET_AUTOMATION_RULE = gql`
  query GetAutomationRule($id: AutomationRuleCode!, $v2: Boolean!) {
    automationRule(id: $id) {
      id
      title
      isEnabled
      category @skip(if: $v2)
      triggerDescription {
        text
        bulletPoints
      }
      automationActionDescriptions @skip(if: $v2) {
        text
        bulletPoints
      }
      automations @include(if: $v2) {
        __typename
        ... on ModifierAutomation {
          modifier
        }
        ... on DiagnosisAutomation {
          diagnosis {
            id
            code
            description
          }
        }
        ... on RemoveChargeItemAutomation {
          billingCode {
            id
            code
            description
          }
        }
        ... on ChargeItemAutomation {
          billingCode {
            id
            code
            description
          }
          diagnoses {
            id
            code
            description
          }
          modifiers
          countDescription
        }
      }
      group @include(if: $v2) {
        id
        subSection {
          id
          title
          section {
            id
            title
          }
        }
      }
    }
  }
`
const useChargeCaptureRuleQuery = (ruleId) => {
  const { currentUser } = useEmrAuth()

  const isV2Enabled = currentUser?.featureFlags.includes('CHARGE_CAPTURE_UI_V2')
  const { data, loading } = useQuery<GetAutomationRule>(GET_AUTOMATION_RULE, {
    variables: { id: ruleId, v2: isV2Enabled },
  })

  return { rule: data?.automationRule, loading }
}

type SectionContents = {
  title: string
  body: string | TextItemWithBulletPoints | TextItemWithBulletPoints[]
}

const Section = ({ title, body }: SectionContents) => {
  const bodyIsArray = Array.isArray(body)
  const includeBulletPointsAtTopLevel = bodyIsArray
  const bodyAsTextItemWithBulletPoints = bodyIsArray
    ? body
    : typeof body === 'string'
    ? [{ text: body, bulletPoints: [] }]
    : [body]

  return (
    <StackView space={50} className="py-4">
      <Typography textStyle="subtitle" className="text-black">
        {title}
      </Typography>
      {bodyAsTextItemWithBulletPoints.map((item) => (
        <>
          <StackView key={item.text} direction="row" space={25}>
            {includeBulletPointsAtTopLevel && (
              <Typography textStyle="normal" color="text-base-color-fg-muted">
                •
              </Typography>
            )}
            <Typography textStyle="normal" color="text-base-color-fg-muted">
              {item.text}
            </Typography>
          </StackView>
          {item.bulletPoints && (
            <StackView className="pl-4">
              {item.bulletPoints.map((bulletPoint) => (
                <Typography
                  key={bulletPoint}
                  textStyle="normal"
                  color="text-base-color-fg-muted"
                >
                  • {bulletPoint}
                </Typography>
              ))}
            </StackView>
          )}
        </>
      ))}
    </StackView>
  )
}

const TextWithBulletPoints = ({
  text,
  bulletPoints,
}: TextItemWithBulletPoints) => {
  return (
    <StackView space={25} className="py-4">
      <Typography textStyle="normal" color="text-base-color-fg-muted">
        {text}
      </Typography>
      {bulletPoints && (
        <StackView className="pl-4">
          {bulletPoints.map((bulletPoint) => (
            <Typography
              key={bulletPoint}
              textStyle="normal"
              color="text-base-color-fg-muted"
            >
              • {bulletPoint}
            </Typography>
          ))}
        </StackView>
      )}
    </StackView>
  )
}

const SidepanelChargeCaptureRuleView = () => {
  const { ruleId } = useParams()
  const { rule, loading } = useChargeCaptureRuleQuery(ruleId)

  return (
    <SidepanelPage
      testId="sidepanel-charge-capture-rule-view"
      header={
        <StackView space={25}>
          {rule?.isEnabled ? (
            <Badge showDot color="green" className="w-fit">
              Active
            </Badge>
          ) : (
            <Badge showDot color="dark-gray" className="w-fit">
              Inactive
            </Badge>
          )}
          <Typography textStyle="title-s">{rule?.title}</Typography>
        </StackView>
      }
      loading={loading}
    >
      <FeatureFlagged
        flagName="CHARGE_CAPTURE_UI_V2"
        childrenIfFlagNotActive={
          <StackView className="px-6 py-4" space={50} divider>
            <Section
              title="Category"
              body={CATEGORY_INFO_BY_CODE[rule?.category]?.title}
            />
            <Section title="Trigger" body={rule?.triggerDescription} />
            <Section
              title="Automation action"
              body={rule?.automationActionDescriptions}
            />
          </StackView>
        }
      >
        <StackView className="px-6 py-6" divider>
          <DataDisplayList
            title="Details"
            data={[
              {
                // Should this be the section name? (Match the seleted tab? Or maybe the subsection within that tab?)
                label: 'Category',
                value: rule?.group?.subSection?.section?.title,
              },
              {
                label: 'Trigger',
                value: rule?.triggerDescription ? (
                  <TextWithBulletPoints {...rule.triggerDescription} />
                ) : null,
              },
            ]}
          />
          <Divider />
          <Automations automations={rule?.automations} />
        </StackView>
      </FeatureFlagged>
    </SidepanelPage>
  )
}

export default SidepanelChargeCaptureRuleView
