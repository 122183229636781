import { useQuery } from '@apollo/client'
import { GetVisitDiagnoses } from 'types/graphql'

const QUERY = gql`
  query GetVisitDiagnoses($encounterId: String!) {
    encounter(id: $encounterId) {
      id
      primaryDiagnosis {
        id
        comment
      }
      additionalDiagnoses {
        id
        comment
      }
    }
  }
`

export const useGetVisitDiagnoses = (encounterId: string) => {
  const { data, error, loading } = useQuery<GetVisitDiagnoses>(QUERY, {
    variables: {
      encounterId,
    },
  })

  return {
    primaryDiagnosis: data?.encounter?.primaryDiagnosis,
    additionalDiagnoses: data?.encounter?.additionalDiagnoses,
    error,
    loading,
  }
}
