import {
  CalendarDaysIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CogIcon,
  CreditCardIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { compact } from 'lodash'
import { Role } from 'types/graphql'

import { NavLink, routes, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import MedicinesIcon from 'src/components/icons/MedicinesIcon/MedicinesIcon'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'
import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'
import useMedicationNotifications from 'src/hooks/useMedicationNotifications/useMedicationNotifications'
import usePollTaskNotificationCount from 'src/hooks/usePollTaskNotificationCount/usePollTaskNotificationCount'
import useQueryParamToast from 'src/hooks/useQueryParamToast/useQueryParamToast'
import { isActiveRoute, sidepanelRoute } from 'src/lib/routes'
import {
  SIDEBAR_SIZE,
  useLocalSettings,
} from 'src/providers/context/LocalSettingsContext'

import ApplicationLayout from '../ApplicationLayout/ApplicationLayout'

interface PrimaryLayoutProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

type NavConfigItem = {
  name: string
  icon: React.FunctionComponent<React.ComponentProps<'svg'>>
  showStatusIndicator?: boolean
  tooltipText?: string | string[]
  mobileExtraText?: string
  to: string
}

interface SidebarProps {
  className?: string
  navigationConfig: NavConfigItem[]
}

const Sidebar = ({ navigationConfig, className }: SidebarProps) => {
  const { sidebarSize, saveSettings } = useLocalSettings()
  const location = useLocation()
  const params = useParams()
  const { currentUser } = useEmrAuth()
  const isDefaultSidebarSize = sidebarSize === SIDEBAR_SIZE.DEFAULT
  const toggleSidebar = () =>
    saveSettings({
      sidebarSize: isDefaultSidebarSize
        ? SIDEBAR_SIZE.SMALL
        : SIDEBAR_SIZE.DEFAULT,
    })

  return (
    <div
      className={clsx(
        isDefaultSidebarSize ? 'w-40' : 'w-16',
        'overflow-y-auto border-r bg-white transition-[width]',
        className
      )}
    >
      <div className="flex h-full w-full flex-col py-6">
        <img
          className={clsx('h-6 w-auto transition')}
          src="/img/develo-logo-square.svg"
          alt="Develo"
        />
        <div
          className={clsx(
            isDefaultSidebarSize ? 'px-4' : 'px-2',
            'mt-6 flex w-full flex-1 flex-col transition-[padding]'
          )}
        >
          <StackView space={25} className="grow">
            {navigationConfig.map((item) => (
              <NavItem
                isDefaultSidebarSize={isDefaultSidebarSize}
                key={item.name}
                {...item}
              />
            ))}

            <Button
              data-testid="toggle-sidebar-button"
              buttonStyle="ghost"
              className="text-gray-500 focus:!ring-0 focus:!ring-offset-0"
              onClick={toggleSidebar}
            >
              {isDefaultSidebarSize ? (
                <ChevronDoubleLeftIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  data-testid="default-sidebar-icon"
                />
              ) : (
                <ChevronDoubleRightIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  data-testid="small-sidebar-icon"
                />
              )}
            </Button>
          </StackView>
          <NavItem
            key="product-guides"
            name="Guides"
            isDefaultSidebarSize={isDefaultSidebarSize}
            icon={QuestionMarkCircleIcon}
            to={
              currentUser?.featureFlags.includes('KNOWLEDGE_BASE')
                ? '/knowledge-base'
                : sidepanelRoute(
                    {
                      route: `/product-guides`,
                    },
                    location,
                    params
                  )
            }
            ignoreActive
          />
        </div>
      </div>
    </div>
  )
}

const NavItem = ({
  isDefaultSidebarSize,
  showStatusIndicator = false,
  tooltipText: providedTooltipText,
  ...item
}: NavConfigItem & {
  isDefaultSidebarSize?: boolean
  ignoreActive?: boolean
}) => {
  const { pathname } = useLocation()
  const isActive = isActiveRoute(pathname, item.to) && !item.ignoreActive

  const tooltipText = Array.isArray(providedTooltipText)
    ? providedTooltipText
    : `${!isDefaultSidebarSize ? item.name + ' ' : ''}${
        providedTooltipText ?? ''
      }`

  return (
    <Tooltip text={tooltipText} placement="right" padding={25}>
      <NavLink
        data-testid={item.name}
        activeClassName=""
        to={item.to}
        className={clsx(
          isActive
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-600 hover:bg-gray-500 hover:text-white',
          'group flex items-center gap-1 rounded-md p-3 text-xs font-medium'
        )}
      >
        <div className="relative flex-none">
          <item.icon
            aria-hidden="true"
            className={clsx(
              isActive
                ? 'fill-gray-500 text-gray-500'
                : 'fill-gray-400 text-gray-400 group-hover:text-white',
              'h-6 w-6 flex-none'
            )}
          />
          {showStatusIndicator && (
            <div className="absolute -right-core-size-12 top-core-size-0">
              <StatusIndicator
                color="purple"
                testId={`notification-dot-${item.name}`}
              />
            </div>
          )}
        </div>
        {isDefaultSidebarSize && <span>{item.name}</span>}
      </NavLink>
    </Tooltip>
  )
}

const MobileMenu = (props: { navigationConfig: NavConfigItem[] }) => {
  return (
    <>
      {props.navigationConfig.map((item) => (
        <MobileNavItem key={item.name} {...item} />
      ))}
    </>
  )
}

const MobileNavItem = (item: NavConfigItem) => {
  const { pathname } = useLocation()
  const isActive = isActiveRoute(pathname, item.to)
  const menuText = [item.name, item.mobileExtraText].filter(Boolean).join(' ')

  return (
    <NavLink
      key={item.name}
      className={clsx(
        isActive
          ? 'bg-gray-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-500 hover:text-white',
        'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
      )}
      activeClassName="bg-gray-100 text-gray-900"
      to={item.to}
    >
      <div className="relative flex-none">
        <item.icon
          className={clsx(
            isActive
              ? 'fill-gray-500 text-gray-500'
              : 'fill-gray-400 text-gray-400 group-hover:text-white',
            'mr-3 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {item.showStatusIndicator && (
          <div className="absolute -right-core-size-12 top-core-size-0 h-core-size-50 w-core-size-50 rounded-full bg-base-color-fg-brand"></div>
        )}
      </div>
      <span className="mt-2">{menuText}</span>
    </NavLink>
  )
}

export default function PrimaryLayout({ children }: PrimaryLayoutProps) {
  useQueryParamToast()
  const {
    count: medicationNotificationCount,
    tooltipText: medicationNotificationTooltipText,
  } = useMedicationNotifications()
  const taskNotificationCount = usePollTaskNotificationCount()
  const taskNotificationTooltipText = notificationsText(taskNotificationCount)

  const { currentUser } = useEmrAuth()

  const externalPayerUserTypesEnabled = currentUser?.featureFlags.includes(
    'EXTERNAL_PAYER_USER_TYPES'
  )

  const baseNavigationConfig: NavConfigItem[] = compact([
    { name: 'Home', icon: HomeIcon, to: routes.home() },
    {
      name: 'Schedule',
      icon: CalendarDaysIcon,
      to: routes.schedule(),
    },
    {
      name: 'Tasks',
      icon: CheckCircleIcon,
      showStatusIndicator: !!taskNotificationCount,
      tooltipText: taskNotificationTooltipText,
      mobileExtraText: taskNotificationTooltipText,
      to: routes.cases({ glob: 'schedule' }),
    },
    {
      name: 'Patients',
      icon: UserGroupIcon,
      to: routes.patients(),
    },
    {
      name: 'Medications',
      icon: MedicinesIcon,
      showStatusIndicator: !!medicationNotificationCount,
      tooltipText: medicationNotificationTooltipText,
      mobileExtraText: notificationsText(medicationNotificationCount),
      to: routes.medications(),
    },
    {
      name: 'Financials',
      icon: CreditCardIcon,
      to: routes.financials(),
    },
    {
      name: 'Reports',
      icon: ChartBarIcon,
      to: routes.reports(),
    },
    {
      name: 'Settings',
      icon: CogIcon,
      to:
        (externalPayerUserTypesEnabled &&
          determineRouteAccessAllowed({
            lookup: primaryLayoutRequiredUserRoles,
            route: 'Staff members',
            roles: currentUser?.roles ?? [],
          })) ||
        !externalPayerUserTypesEnabled
          ? routes.staff()
          : routes.profile(),
    },
  ])

  let navigationConfig: NavConfigItem[] = baseNavigationConfig

  if (externalPayerUserTypesEnabled) {
    navigationConfig = baseNavigationConfig.filter((route) => {
      return determineRouteAccessAllowed({
        lookup: primaryLayoutRequiredUserRoles,
        route: route.name,
        roles: currentUser?.roles ?? [],
      })
    })
  }

  return (
    <ApplicationLayout
      sidebar={
        <Sidebar
          navigationConfig={navigationConfig}
          className="hidden md:block"
        />
      }
      mobileMenu={<MobileMenu navigationConfig={navigationConfig} />}
    >
      {children}
    </ApplicationLayout>
  )
}

const notificationsText = (count: number) => {
  return count > 0 ? `(${count} notification${count > 1 ? 's' : ''})` : null
}

type PrimaryLayoutNavConfigName =
  | 'Home'
  | 'Schedule'
  | 'Tasks'
  | 'Patients'
  | 'Medications'
  | 'Financials'
  | 'Reports'
  | 'Settings'

export type SettingsLayoutGroup = 'Practice' | 'Documents' | 'Account'

export type SettingsLayoutGroupItem =
  | 'Staff members'
  | 'Clinic schedule'
  | 'Practitioner schedule'
  | 'Visit types'
  | 'Vaccine inventory'
  | 'Templates'
  | 'Orders'
  | 'Macro phrases'
  | 'Charge capture'
  | 'Tasks'
  | 'Billing'
  | 'Family portal admin'
  | 'Memberships'
  | 'Resources'
  | 'Contacts'
  | 'Outbound Efax'
  | 'Profile'
  | 'Logout'

export type PrimaryLayoutUserRolesConfig = {
  [key in PrimaryLayoutNavConfigName]:
    | Role[]
    | {
        [key in SettingsLayoutGroup]: {
          [key in SettingsLayoutGroupItem]?: Role[]
        }
      }
}

export const primaryLayoutRequiredUserRoles: PrimaryLayoutUserRolesConfig = {
  Home: [],
  Schedule: [],
  Tasks: [],
  Patients: [],
  Medications: ['VIEW_MEDICATIONS'],
  Financials: ['VIEW_FINANCIALS'],
  Reports: ['REPORTS'],
  Settings: {
    Practice: {
      'Staff members': ['VIEW_TENANT_SETTINGS'],
      'Clinic schedule': ['VIEW_TENANT_SETTINGS'],
      'Practitioner schedule': ['VIEW_TENANT_SETTINGS'],
      'Visit types': ['VIEW_TENANT_SETTINGS'],
      'Vaccine inventory': ['VIEW_TENANT_SETTINGS'],
      Templates: ['VIEW_TENANT_SETTINGS'],
      Orders: ['VIEW_TENANT_SETTINGS'],
      'Macro phrases': ['VIEW_TENANT_SETTINGS'],
      'Charge capture': ['VIEW_TENANT_SETTINGS'],
      Billing: ['VIEW_TENANT_SETTINGS'],
      Tasks: ['VIEW_TENANT_SETTINGS'],
      'Family portal admin': ['VIEW_TENANT_SETTINGS'],
      Memberships: ['VIEW_TENANT_SETTINGS'],
    },
    Documents: {
      Resources: ['VIEW_TENANT_SETTINGS'],
      Contacts: ['VIEW_TENANT_SETTINGS'],
      'Outbound Efax': ['VIEW_TENANT_SETTINGS'],
    },
    Account: {
      Profile: [],
      Logout: [],
    },
  },
}

export const determineRouteAccessAllowed = ({
  lookup,
  route,
  roles,
}: {
  lookup: PrimaryLayoutUserRolesConfig
  route: string
  roles: Role[]
}): boolean => {
  if (lookup[route]) {
    if (Array.isArray(lookup[route])) {
      return (
        !lookup[route].length ||
        lookup[route].every((requiredRole) => roles.includes(requiredRole))
      )
    } else {
      // allow the parent route to be accessed if we have the roles for some sub-route
      return Object.keys(lookup[route]).some((subRoute) =>
        determineRouteAccessAllowed({
          lookup: lookup[route],
          route: subRoute,
          roles,
        })
      )
    }
  } else if (!Array.isArray(lookup)) {
    // some lookup result among the entries of lookup
    // resulted in access granted to 'route'
    return Object.keys(lookup).some((nestedLookup) =>
      determineRouteAccessAllowed({
        lookup: lookup[nestedLookup],
        route,
        roles,
      })
    )
  }
  return false
}
