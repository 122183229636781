import { AutomationRuleCode } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Toggle from 'src/components/molecules/Toggle/Toggle'
import { sidepanelRoute } from 'src/lib/routes'

const SET_RULE_IS_ENABLED = gql`
  mutation SetRuleIsEnabled($id: AutomationRuleCode!, $isEnabled: Boolean!) {
    setRuleIsEnabled(id: $id, isEnabled: $isEnabled) {
      id
      isEnabled
    }
  }
`

const ChargeCaptureRule = ({
  rule,
  disabled = false,
}: {
  rule: {
    id: AutomationRuleCode
    title: string
    isEnabled: boolean
  }
  disabled?: boolean
}) => {
  const { hasRole } = useEmrAuth()
  const location = useLocation()
  const params = useParams()
  const [toggleRuleEnabled, { loading }] = useMutation(SET_RULE_IS_ENABLED, {
    variables: { id: rule.id, isEnabled: !rule.isEnabled },
  })
  const canEdit = hasRole('CHARGE_CAPTURE_RULES') && !disabled

  return (
    <Box
      data-testid={`rule-${rule.id}`}
      className="hover:bg-gray-50"
      horizontalPadding={100}
      verticalPadding={75}
      rounded
      border
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: `/charge-capture-rule/${rule.id}`,
            },
            location,
            params
          )
        )
      }}
    >
      <StackView
        direction="row"
        alignItems="center"
        space={50}
        className={!canEdit ? 'opacity-base-opacity-disabled' : undefined}
      >
        <StackView space={25}>
          <Typography
            size="s"
            fontWeight="medium"
            color="text-base-color-fg-muted"
          >
            {rule.title}
          </Typography>
        </StackView>
        <Box>
          <Toggle
            testId={`rule-toggle-${rule.id}`}
            id={rule.id}
            checked={rule.isEnabled}
            onChange={toggleRuleEnabled}
            disabled={!canEdit || loading}
            stopPropagation
          />
        </Box>
      </StackView>
    </Box>
  )
}

export default ChargeCaptureRule
