import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

export interface ListItemTextProps {
  title: string
  titleIcon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  description?: string
}

export const ListItemText = ({
  title,
  titleIcon: TitleIcon,
  description,
}: ListItemTextProps) => {
  return (
    <StackView gap={25}>
      <StackView direction="row" gap={25}>
        <Typography
          textStyle="interface-strong-s"
          color="text-base-color-fg-muted"
        >
          {title}
        </Typography>

        {TitleIcon ? (
          <TitleIcon className="h-base-size-icon-s w-base-size-icon-s" />
        ) : null}
      </StackView>

      {description ? (
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {description}
        </Typography>
      ) : null}
    </StackView>
  )
}
