import { LocalDate } from '@js-joda/core'
import { dateTimeFormatter } from 'common/data/date'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { formatPhoneNumber } from 'react-phone-number-input'
import { match } from 'ts-pattern'
import type {
  PatientDetailsFragment,
  PatientItemizedBillingHistory,
} from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { sexDisplay } from 'src/data/sexes'
import { formatAddress } from 'src/lib/formatters'

import { LetterHead, SubHeading } from './common'
import {
  EncountersAndPaymentPlanSection,
  SummarySection,
} from './PrintPatientDetailedBillingStatement'

const MessageAndSummarySection = ({
  patientBalanceCents,
  patientName,
  charges,
  credits,
  startDate,
  endDate,
  generatedAt,
}: {
  patientBalanceCents: number
  patientName: string
  charges: number
  credits: number
  startDate: LocalDate
  endDate: LocalDate
  generatedAt: Date
}) => {
  return (
    <StackView space={150} direction="row" alignItems="stretch">
      <Box flex="7/12">
        <StackView className="h-full" alignItems="stretch" space={50}>
          <StackView className="grow" space={50}>
            <Typography textStyle="title-l">
              Billing history for {patientName}
            </Typography>
            <Typography textStyle="body-m" color="text-base-color-fg-muted">
              {startDate.format(dateTimeFormatter('MM/dd/yyyy'))} to{' '}
              {endDate.format(dateTimeFormatter('MM/dd/yyyy'))} dates of service
            </Typography>
          </StackView>

          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            Created on {format(generatedAt, "MM/dd/yyyy 'at' h:mma")}
          </Typography>
        </StackView>
      </Box>

      <SummarySection
        charges={charges}
        credits={credits}
        patientBalanceCents={patientBalanceCents}
        hasPaymentPlan={false}
      />
    </StackView>
  )
}

const PatientSummary = ({ patient }: { patient: PatientDetailsFragment }) => {
  const contactInformation =
    patient.contactInformation ??
    patient.patientRelatedPersonRelationships.find(
      (relationship) => relationship.doesResideWith
    )?.relatedPerson?.contactInformation

  return (
    <StackView className="p-core-space-75" gap={25}>
      <SubHeading>Patient</SubHeading>
      <Typography textStyle="interface-default-s">
        {formatDisplayName(patient)}
      </Typography>

      <StackView direction="row" gap={50}>
        <StackView gap={25}>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Date of birth:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {format(new Date(patient.birthDate), 'MM/dd/yyyy')}
            </Typography>
          </StackView>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Sex at birth:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {sexDisplay[patient.sexAtBirth]}
            </Typography>
          </StackView>
        </StackView>

        <StackView gap={25}>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Home address:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {formatAddress(contactInformation?.homeAddress)}
            </Typography>
          </StackView>
        </StackView>
      </StackView>
    </StackView>
  )
}

const GuardianSummary = ({
  guardian,
}: {
  guardian: PatientDetailsFragment['patientRelatedPersonRelationships'][number]
}) => {
  return (
    <StackView className="w-1/2 pb-core-space-75" fullWidth={false} gap={25}>
      <Typography textStyle="interface-default-s">
        {formatDisplayName(guardian.relatedPerson)}
      </Typography>
      <StackView gap={25}>
        <StackView direction="row" gap={50}>
          <Typography className="w-1/2" textStyle="interface-strong-xs">
            Relationship to patient:
          </Typography>
          <Typography textStyle="interface-default-xs">
            {relationshipTypeDisplay[guardian.relationshipType]}
          </Typography>
        </StackView>

        <StackView direction="row" gap={50}>
          <Typography className="w-1/2" textStyle="interface-strong-xs">
            Mobile number:
          </Typography>
          <Typography textStyle="interface-default-xs">
            {formatPhoneNumber(
              guardian.relatedPerson.contactInformation?.mobileNumber
            )}
          </Typography>
        </StackView>

        <StackView direction="row" gap={50} wrap>
          <Typography className="w-1/2" textStyle="interface-strong-xs">
            Email address:
          </Typography>
          <Typography textStyle="interface-default-xs">
            {guardian.relatedPerson.contactInformation?.emailAddress}
          </Typography>
        </StackView>
      </StackView>
    </StackView>
  )
}

const PatientAndGuardianSummarySection = ({
  patient,
}: {
  patient: PatientItemizedBillingHistory['patient']
}) => {
  const guardians = patient.patientRelatedPersonRelationships.filter(
    (relationship) => relationship.guardianshipType !== 'NON_GUARDIAN'
  )
  return (
    <StackView gap={75}>
      <Typography textStyle="title-xs">
        {match(guardians.length)
          .with(0, () => 'Patient summary')
          .otherwise(() => 'Patient and guardian summary')}
      </Typography>
      <Box border rounded>
        <StackView divider>
          <PatientSummary patient={patient} />
          {guardians.length > 0 && (
            <StackView className="p-core-space-75" gap={25}>
              <SubHeading>Guardian(s)</SubHeading>
              <StackView direction="row" wrap>
                {guardians.map((guardian) => (
                  <GuardianSummary key={guardian.id} guardian={guardian} />
                ))}
              </StackView>
            </StackView>
          )}
        </StackView>
      </Box>
    </StackView>
  )
}

const PrintPatientItemizedBillingHistory = ({
  billingStatement,
  endDate,
  generatedAt,
  patient,
  startDate,
  testId,
}: {
  billingStatement: PatientItemizedBillingHistory['billingStatement']
  endDate: LocalDate
  generatedAt: Date
  patient: PatientItemizedBillingHistory['patient']
  startDate: LocalDate
  testId?: string
}) => {
  return (
    <StackView space={150} data-testid={testId}>
      <LetterHead tenant={billingStatement.tenant} />
      <MessageAndSummarySection
        charges={billingStatement.summaryChargeCents}
        credits={-billingStatement.summaryCreditCents}
        endDate={endDate}
        generatedAt={generatedAt}
        patientBalanceCents={billingStatement.summaryBalanceCents}
        patientName={formatDisplayName(patient)}
        startDate={startDate}
      />
      <PatientAndGuardianSummarySection patient={patient} />
      <EncountersAndPaymentPlanSection
        billingStatementEncounters={billingStatement.billingStatementEncounters}
        currentPaymentPlan={null}
        endDate={endDate}
        startDate={startDate}
        tenantName={billingStatement.tenant.name}
      />
    </StackView>
  )
}

export default PrintPatientItemizedBillingHistory
