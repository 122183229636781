import merge from 'lodash/merge'

import { CheckboxFieldProps as RWCheckboxFieldProps } from '@redwoodjs/forms/dist/CheckboxField'
import { InputFieldProps } from '@redwoodjs/forms/dist/InputComponents'
import { LabelProps } from '@redwoodjs/forms/dist/Label'
import { TextAreaFieldProps } from '@redwoodjs/forms/dist/TextAreaField'

import { AddressFormInputListProps } from 'src/components/Address/AddressField/AddressField'
import { InheritedAddressFieldProps } from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import { BooleanSelectFieldProps } from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import {
  FormControlledCheckboxProps,
  MultiCheckboxFieldProps,
} from 'src/components/atoms/Checkbox'
import { ChiefComplaintSelectFieldProps } from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import { ComboboxFieldProps } from 'src/components/atoms/ComboboxField/ComboboxField'
import { DocumentTypeSelectFieldProps } from 'src/components/atoms/DocumentTypeSelectField/DocumentTypeSelectField'
import { DropdownFieldProps } from 'src/components/atoms/Dropdown/Dropdown'
import { DropdownV2FieldProps } from 'src/components/atoms/DropdownV2/DropdownV2'
import { FieldLabel } from 'src/components/atoms/Label/Label'
import { PatientResidesWithSelectFieldProps } from 'src/components/atoms/PatientResidesWithSelectField/PatientResidesWithSelectField'
import { PaymentLineItemSelectFieldProps } from 'src/components/atoms/PaymentLineItemSelectField.tsx/PaymentLineItemSelectField'
import { PhoneInputFieldProps } from 'src/components/atoms/PhoneInputField/PhoneInputField'
import PractitionerClinicalDetailSelectField from 'src/components/atoms/PractitionerClinicalDetailSelectField/PractitionerClinicalDetailSelectField'
import { SelectFieldProps } from 'src/components/atoms/SelectField'
import { SocialSecurityNumberFieldProps } from 'src/components/atoms/SocialSecurityNumberField/SocialSecurityNumberField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TypographyColor,
} from 'src/components/atoms/Typography/Typography'
import { VaccineInventorySelectFieldProps } from 'src/components/atoms/VaccineInventorySelectField/VaccineInventorySelectField'
import { VaccineCVXSelectFieldProps } from 'src/components/atoms/VaccineSelectField/VaccineCVXSelectField'
import { WysiwygFieldProps } from 'src/components/atoms/WysiwygField/WysiwygField'
import FieldError from 'src/components/FieldError/FieldError'
import { LocationSelectFieldProps } from 'src/components/Location/LocationsSelectField/LocationsSelectField'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import { GenericFormFieldArrayProps } from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import { AdvancedSearchFieldProps } from 'src/components/organisms/AdvancedSearchField/AdvancedSearchField'
import { BillingCodeSearchFieldProps } from 'src/components/organisms/BillingCodeSearchField/BillingCodeSearchField'
import { DiagnosisSearchFieldProps } from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import { DiagnosisSearchV2FieldProps } from 'src/components/organisms/DiagnosisSearchV2Field/DiagnosisSearchV2Field'
import { EnhancedPatientSearchProps } from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { PractitionerDropdownV2FieldProps } from 'src/components/Practitioner/PractitionerDropdownV2Field/PractitionerDropdownV2Field'
import { PractitionerSelectFieldProps } from 'src/components/Practitioner/PractitionerSelectField'
import { BodyWeightInputProps } from 'src/components/VisitIntakeVital/BodyWeightInput'
import { TravelHistoryEditProps } from 'src/pages/Sidepanel/SidepanelSocialHistoryEdit/SidepanelSocialHistoryEdit'

import Alert, { AlertProps } from '../Alert/Alert'
import { BillingEncounterSelectFieldProps } from '../BillingEncounterSelectField/BillingEncounterSelectField'
import {
  DateRangeField,
  FormControlledDatePickerProps,
} from '../DatePicker/DatePicker'
import { FlatFieldArrayProps } from '../GenericFormFieldArray/FlatFieldArray'
import { InputDropdownProps } from '../InputDropdownField/InputDropdownField'
import { InputFieldWithSelectableUnitProps } from '../InputFieldWithUnits/InputFieldWithUnits'
import { MultiSelectComboBoxFieldArrayProps } from '../MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import { PublicPayerSelectFieldProps } from '../PayerSelectField/PublicPayerSelectField'
import { QueryBackendSearchInputFieldProps } from '../QueryBackedSearchInputField/QueryBackedSearchInputField'
import {
  MultiRadioButtonFieldProps,
  RadioButtonCardTableFieldProps,
  RadioButtonFieldProps,
} from '../RadioButton'
import { TypeaheadProps } from '../Typeahead'

export type InputProps =
  | (InputFieldProps & { unit: string; units: string[]; optionName: string })
  | (SelectFieldProps & { patientId: string })
  | BooleanSelectFieldProps
  | RWCheckboxFieldProps
  | TextAreaFieldProps
  | DropdownFieldProps
  | FormControlledDatePickerProps
  | MultiSelectComboBoxFieldArrayProps
  | PatientResidesWithSelectFieldProps
  | AddressFormInputListProps
  | InheritedAddressFieldProps
  | ComboboxFieldProps
  | PhoneInputFieldProps
  | PractitionerSelectFieldProps
  | RadioButtonFieldProps
  | MultiRadioButtonFieldProps
  | RadioButtonCardTableFieldProps
  | LocationSelectFieldProps
  | FormControlledCheckboxProps
  | MultiCheckboxFieldProps
  | SocialSecurityNumberFieldProps
  | QueryBackendSearchInputFieldProps
  | DocumentTypeSelectFieldProps
  | VaccineCVXSelectFieldProps
  | VaccineInventorySelectFieldProps
  | InputDropdownProps
  | AdvancedSearchFieldProps
  | DiagnosisSearchFieldProps
  | DiagnosisSearchV2FieldProps
  | BillingCodeSearchFieldProps
  | GenericFormFieldArrayProps
  | InputFieldWithSelectableUnitProps
  | ChiefComplaintSelectFieldProps
  | TravelHistoryEditProps
  | PaymentLineItemSelectFieldProps
  | BodyWeightInputProps
  | WysiwygFieldProps
  | FlatFieldArrayProps
  | BillingEncounterSelectFieldProps
  | React.ComponentProps<typeof DateRangeField>
  | EnhancedPatientSearchProps
  | TypeaheadProps
  | AddressFormInputListProps
  | PublicPayerSelectFieldProps
  | React.ComponentProps<typeof PractitionerClinicalDetailSelectField>
  | PractitionerDropdownV2FieldProps
  | DropdownV2FieldProps

export interface Item {
  name: string
  fieldErrorName?: string
  label: string
  labelProps?: Partial<LabelProps>
  fieldErrorLabel?: string
  formInputComponent: React.FC<InputProps>
  required?: boolean
  message?: string
  subtitle?: string
  subtitleProps?: Partial<LabelProps> & { color?: TypographyColor }
  inputProps?: Partial<InputProps>
  hide?: boolean
  alignItems?: 'start' | 'center'
  inputContainerAlignItems?: 'end'
  direction?: 'row' | 'col'
  hideLabel?: boolean
  showFieldError?: boolean
  alert?: AlertProps
}

export interface FormInputListProps {
  title?: string
  subtitle?: string
  items: Item[]
  className?: string
  divider?: boolean
  leftColumnWidth?: 'sm' | 'md' | 'lg'
  testId?: string
  action?: React.ReactNode
}

export const FormInputList: React.FC<FormInputListProps> = ({
  title,
  subtitle,
  items,
  className = 'py-core-space-75',
  divider = true,
  leftColumnWidth = 'md',
  testId,
  action,
}) => (
  <StackView divider={divider} className={className} data-testid={testId}>
    {title && (
      <StackView
        direction="row"
        justifyContent="between"
        className="pb-core-space-75"
        space={100}
      >
        <StackView direction="col" space={25}>
          <Typography textStyle="title-xs">{title}</Typography>
          {subtitle && (
            <Typography color="text-base-color-fg-muted" textStyle="body-s">
              {subtitle}
            </Typography>
          )}
        </StackView>
        {action && action}
      </StackView>
    )}
    {items.map(
      ({
        name,
        fieldErrorName,
        label,
        labelProps,
        required,
        inputProps,
        hide,
        message,
        subtitle,
        subtitleProps,
        alignItems,
        inputContainerAlignItems,
        formInputComponent: FormInputComponent,
        direction,
        hideLabel = false,
        showFieldError = true,
        alert,
      }) => {
        if (hide) return null

        return (
          <DataRow
            alignItems={alignItems}
            key={label}
            direction={direction}
            leftColumnWidth={leftColumnWidth}
            label={
              <>
                {!hideLabel && (
                  <StackView space={50}>
                    <FieldLabel
                      name={name}
                      required={!!required}
                      {...labelProps}
                    >
                      {label}
                    </FieldLabel>
                  </StackView>
                )}
              </>
            }
            value={
              <StackView space={50} alignItems={inputContainerAlignItems}>
                {subtitle && (
                  <Typography
                    color="text-base-color-fg-subtle"
                    textStyle="body-xs"
                    {...subtitleProps}
                  >
                    {subtitle}
                  </Typography>
                )}
                {alert ? <Alert {...alert} /> : null}
                <FormInputComponent
                  name={name}
                  {...merge(inputProps, {
                    validation: {
                      required: required && `${label} is required`,
                      ...(inputProps?.validation && inputProps.validation),
                    },
                  })}
                />
                {message && (
                  <Typography
                    color="text-base-color-fg-subtle"
                    textStyle="body-xs"
                  >
                    {message}
                  </Typography>
                )}
                {showFieldError && (
                  <FieldError
                    name={fieldErrorName || name}
                    label={label}
                    defaultErrorMessage={`${label} has an error`}
                  />
                )}
              </StackView>
            }
          />
        )
      }
    )}
  </StackView>
)

export default FormInputList
