import { FC } from 'react'

import { MetaTags } from '@redwoodjs/web'

import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'

import { SectionHeader } from '../SectionHeader/SectionHeader'

interface PageProps {
  tag?: {
    title: string
    description?: string
  }
  title: string
  description?: string
  actions?: React.ReactNode[]
  divider?: boolean
}

const Page: FC<React.PropsWithChildren<PageProps>> = ({
  children,
  tag,
  title,
  description,
  actions,
  divider,
}) => {
  return (
    <>
      {tag && <MetaTags title={tag.title} description={tag.description} />}
      <StackView className="grow p-core-space-150" gap={100}>
        <SectionHeader
          title={title}
          description={description}
          size="l"
          actions={
            actions?.length ? (
              <StackView direction="row" gap={100} justifyContent="end">
                {actions}
              </StackView>
            ) : null
          }
        />
        {divider ? <Divider /> : null}
        <StackView className="grow">{children}</StackView>
      </StackView>
    </>
  )
}

export default Page
