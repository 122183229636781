import { ReactElement } from 'react'

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import BaseModal from './BaseModal'

interface ModalButton {
  text: string
  onClick: () => void
  testId?: string
  loading?: boolean
  disabled?: boolean
}

interface ModalProps {
  title: string
  content: string | React.ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  modalStyle?: 'warning' | 'danger'
  onClose?: () => void
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  testId?: string
  hideIcon?: boolean
  primaryButton: ModalButton
  secondaryButton?: ModalButton
  tertiaryButton?: ModalButton
  contentClassName?: string
}

export default function Modal({
  title,
  content,
  isOpen,
  modalStyle,
  setIsOpen,
  onClose,
  testId,
  hideIcon = false,
  primaryButton: primaryButtonProps,
  secondaryButton: secondaryButtonProps,
  tertiaryButton,
  contentClassName,
}: ModalProps): ReactElement {
  const isWarning = modalStyle === 'warning'

  const primaryButton = {
    testId: 'modal-confirm-btn',
    ...primaryButtonProps,
  }

  const secondaryButton = secondaryButtonProps ?? {
    text: 'Cancel',
    testId: 'modal-cancel-btn',
    onClick: () => setIsOpen(false),
  }

  return (
    <BaseModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testId={testId}
      onClose={() => {
        onClose()
        setIsOpen(false)
      }}
    >
      <Card
        className={contentClassName ?? 'max-w-md'}
        footer={
          <StackView direction="row" justifyContent="between" gap={100}>
            {tertiaryButton ? (
              <Button {...tertiaryButton} buttonStyle="secondary" />
            ) : null}
            <StackView direction="row" space={100} justifyContent="end">
              <Button
                type="button"
                {...secondaryButton}
                buttonStyle="secondary"
              />
              <Button
                type="button"
                {...primaryButton}
                buttonStyle={isWarning ? 'primary' : 'danger'}
                onClick={() => {
                  primaryButton.onClick()
                  setIsOpen(false)
                }}
              />
            </StackView>
          </StackView>
        }
      >
        <Box padding={100}>
          <StackView space={75} alignItems="start">
            {!hideIcon && (
              <Box
                className="flex h-16 w-16 items-center justify-center rounded-full"
                color={`${
                  isWarning
                    ? 'bg-base-color-bg-attention'
                    : 'bg-base-color-bg-danger'
                }`}
              >
                {isWarning ? (
                  <ExclamationCircleIcon className="h-8 w-8 text-warning" />
                ) : (
                  <ExclamationTriangleIcon className="h-8 w-8 text-danger" />
                )}
              </Box>
            )}
            <Box className="flex justify-start">
              <Typography textStyle="title-xs" className="text-left">
                {title}
              </Typography>
            </Box>
            <Box className="text-left">
              {typeof content === 'string' ? (
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  {content}
                </Typography>
              ) : (
                content
              )}
            </Box>
          </StackView>
        </Box>
      </Card>
    </BaseModal>
  )
}
