import {
  CreateDocumentCaseInput,
  CreateFamilyMessageCaseFromEmr,
  CreateFamilyMessageCaseFromEmrVariables,
  FamilyMessageCaseSubject,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { TaskAssignmentComboboxField } from 'src/components/TaskAssignmentComboboxField/TaskAssignmentComboboxField'
import { useFeatureFlagIsEnabled } from 'src/hooks/useFeatureFlagIsEnabled'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import { FamilyMessageCaseFormFields } from './FamilyMessageCaseFormFields'

const CREATE_DOCUMENT_CASE_MUTATION = gql`
  mutation CreateDocumentCase($input: CreateDocumentCaseInput!) {
    createDocumentCase(input: $input) {
      id
      baseCase {
        id
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

const CREATE_FAMILY_MESSAGE_CASE_MUTATION = gql`
  mutation CreateFamilyMessageCaseFromEmr(
    $input: CreateFamilyMessageCaseFromEmrInput!
  ) {
    createFamilyMessageCaseFromEmr(input: $input) {
      id
      baseCase {
        id
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

const CREATE_STAFF_MESSAGE_CASE_MUTATION = gql`
  mutation CreateStaffMessageCase($input: CreateStaffMessageCaseInput!) {
    createStaffMessageCase(input: $input) {
      id
      baseCase {
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

type FormProps =
  | {
      caseType: 'family-message'
      userOrUserPoolId: string
      patientId: string
      subject: FamilyMessageCaseSubject
      message: string
      assignToPortalUserId: string
      documentReferenceId?: string
    }
  | {
      caseType: 'document' | 'staff-message'
      userOrUserPoolId: string
      documentBinaries: FileList
      caseNotes?: string
      patientId?: string
    }

const SidepanelCreateTask = () => {
  const canCreateFamilyMessageCase = useFeatureFlagIsEnabled('PATIENT_PORTAL')
  const [createDocumentCase, { loading: creatingDocumentCase }] = useMutation(
    CREATE_DOCUMENT_CASE_MUTATION
  )
  const [createStaffMessageCase, { loading: creatingStaffMessageCase }] =
    useMutation(CREATE_STAFF_MESSAGE_CASE_MUTATION)
  const [createFamilyMessageCase, { loading: creatingFamilyMessageCase }] =
    useMutation<
      CreateFamilyMessageCaseFromEmr,
      CreateFamilyMessageCaseFromEmrVariables
    >(CREATE_FAMILY_MESSAGE_CASE_MUTATION)
  const { closeSidePanel, sidepanelContext } = useSidepanel()
  const formMethods = useForm<FormProps>()
  const isDocumentCase = formMethods.watch('caseType') === 'document'
  const isStaffMessage = formMethods.watch('caseType') === 'staff-message'
  const isFamilyMessage = formMethods.watch('caseType') === 'family-message'
  const documentBinaries = formMethods.watch('documentBinaries')

  const defaultPatientId =
    typeof sidepanelContext?.patientId === 'string'
      ? sidepanelContext.patientId
      : undefined

  const onSubmit = async (formData: FormProps) => {
    const [idType, id] = formData.userOrUserPoolId.split(':')
    const assignedTaskUserPoolId = idType === 'pool' ? id : null
    const assignedUserId = idType === 'user' ? id : null

    if (formData.caseType === 'document') {
      const binaries = formData.documentBinaries
      let index = 0
      for (const binary of binaries) {
        const binaryInput = {
          content: await base64EncodeFile(binary),
          contentType: binary.type,
        }
        const input: CreateDocumentCaseInput = {
          binaryInput,
          caseNotes: formData.caseNotes,
          assignedTaskUserPoolId,
          assignedUserId,
          patientId: formData.patientId || null,
        }
        index++
        await createDocumentCase({
          variables: { input },
          onCompleted: () => {
            toast(`${binary.name} uploaded`, {
              position: 'top-right',
              duration: 2000,
            })
            if (index === binaries.length) {
              closeSidePanel()
            }
          },
          refetchQueries:
            index === binaries.length
              ? [
                  'FindDocumentCases',
                  'GetNotResolvedCasesCountByType',
                  'GetTaskNotificationCount',
                ]
              : ['GetTaskNotificationCount'],
        })
      }
    }

    if (formData.caseType === 'staff-message') {
      void createStaffMessageCase({
        variables: {
          input: {
            assignedTaskUserPoolId,
            assignedUserId,
            caseNotes: formData.caseNotes,
            patientId: formData.patientId || null,
          },
        },
        refetchQueries: [
          'FindStaffMessageCases',
          'GetNotResolvedCasesCountByType',
          'GetTaskNotificationCount',
        ],
        onCompleted: () => {
          closeSidePanel()
        },
      })
    }

    if (formData.caseType === 'family-message') {
      void createFamilyMessageCase({
        variables: {
          input: {
            patientId: formData.patientId,
            subject: formData.subject,
            message: formData.message,
            assignToPortalUser:
              formData.assignToPortalUserId === 'patient'
                ? { patient: true }
                : { relatedPersonId: formData.assignToPortalUserId },
            documentReferenceId: formData.documentReferenceId,
          },
        },
        refetchQueries: [
          'FindFamilyMessageCases',
          'GetNotResolvedCasesCountByType',
          'GetTaskNotificationCount',
        ],
        onCompleted: () => {
          closeSidePanel()
        },
      })
    }
  }

  return (
    <SidepanelPage
      header="Create task"
      description="Manually create a new task."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          submitText: 'Create task',
          submitting:
            creatingStaffMessageCase ||
            creatingDocumentCase ||
            creatingFamilyMessageCase,
          submitButtonTestId: 'sidepanel-create-case-btn',
        }}
      >
        <StackView className="h-full">
          <FormInputList
            title="Task details"
            items={[
              {
                name: 'caseType',
                label: 'Task type',
                required: true,
                formInputComponent: DropdownField,
                inputProps: {
                  options: [
                    {
                      name: 'Document',
                      value: 'document',
                    },
                    {
                      name: 'Staff message',
                      value: 'staff-message',
                    },
                    canCreateFamilyMessageCase
                      ? {
                          name: 'Family message',
                          value: 'family-message',
                        }
                      : null,
                  ].filter(Boolean),
                },
              },
              {
                name: 'userOrUserPoolId',
                label: 'User or user pool',
                required: true,
                formInputComponent: TaskAssignmentComboboxField,
              },
            ]}
          />
          {isDocumentCase && (
            <>
              <Divider />
              <FormInputList
                title="Document details"
                items={[
                  {
                    name: 'documentBinaries',
                    label: 'Document upload',
                    required: true,
                    formInputComponent: FileInput,
                    inputProps: {
                      draggable: true,
                      multiple: true,
                    },
                  },
                  {
                    name: 'patient',
                    formInputComponent: EnhancedPatientSearch,
                    label: 'Patient',
                    required: false,
                    inputProps: {
                      placeholder: '',
                      defaultPatientId,
                    },
                  },
                  {
                    label: 'Task notes',
                    name: 'caseNotes',
                    hide: documentBinaries?.length > 1,
                    formInputComponent: TextAreaField,
                  },
                ]}
              />
            </>
          )}
          {isStaffMessage && (
            <>
              <Divider />
              <FormInputList
                items={[
                  {
                    name: 'patient',
                    formInputComponent: EnhancedPatientSearch,
                    label: 'Patient',
                    required: false,
                    inputProps: {
                      placeholder: '',
                      defaultPatientId,
                    },
                  },
                  {
                    name: 'caseNotes',
                    label: 'Task notes',
                    required: false,
                    formInputComponent: TextAreaField,
                    alignItems: 'start',
                    inputProps: {
                      rows: 6,
                    },
                  },
                ]}
              />
            </>
          )}
          {isFamilyMessage && (
            <>
              <Divider />
              <FamilyMessageCaseFormFields
                defaultPatientId={defaultPatientId}
              />
            </>
          )}
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelCreateTask
