import mapValues from 'lodash/mapValues'
import { PlaceOfService } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const placeOfServices: {
  [key in PlaceOfService]: {
    display: string
    code: string
    isPriority?: boolean
  }
} = {
  TELEHEALTH_OTHER_LOCATION: {
    code: '02',
    display: 'Telehealth provided other than in patient’s home',
    isPriority: true,
  },
  TELEHEALTH_HOME: {
    code: '10',
    display: 'Telehealth provided in patient’s home',
    isPriority: true,
  },
  OFFICE: {
    code: '11',
    display: 'Office',
    isPriority: true,
  },
  URGENT_CARE_FACILITY: {
    code: '20',
    display: 'Urgent care facility',
    isPriority: true,
  },
  INPATIENT_HOSPITAL: {
    code: '21',
    display: 'Inpatient hospital',
    isPriority: true,
  },
  PHARMACY: {
    code: '01',
    display: 'Pharmacy',
  },
  SCHOOL: {
    code: '03',
    display: 'School',
  },
  HOMELESS_SHELTER: {
    code: '04',
    display: 'Homeless shelter',
  },
  INDIAN_FREESTANDING: {
    code: '05',
    display: 'Indian Health Service free-standing facility',
  },
  INDIAN_PROVIDER_BASED: {
    code: '06',
    display: 'Indian Health Service provider-based facility',
  },
  TRIBAL_FREESTANDING: {
    code: '07',
    display: 'Tribal 638 free-standing facility',
  },
  TRIBAL_PROVIDER_BASED: {
    code: '08',
    display: 'Tribal 638 provider-based facility',
  },
  PRISON: {
    code: '09',
    display: 'Prison/correctional facility',
  },
  HOME: {
    code: '12',
    display: 'Home',
  },
  ASSISTED_LIVING: {
    code: '13',
    display: 'Assisted living facility',
  },
  GROUP_HOME: {
    code: '14',
    display: 'Group home',
  },
  MOBILE_UNIT: {
    code: '15',
    display: 'Mobile unit',
  },
  TEMPORARY_LODGING: {
    code: '16',
    display: 'Temporary lodging',
  },
  WALK_IN: {
    code: '17',
    display: 'Walk-in retail health clinic',
  },
  WORKSITE: {
    code: '18',
    display: 'Place of employment- worksite',
  },
  OFF_CAMPUS_OUTPATIENT_HOSPITAL: {
    code: '19',
    display: 'Off campus- outpatient hospital',
  },
  ON_CAMPUS_OUTPATIENT_HOSPITAL: {
    code: '22',
    display: 'On campus- outpatient hospital',
  },
  EMERGENCY_ROOM: {
    code: '23',
    display: 'Emergency room- hospital',
  },
  AMBULATORY_SURGICAL_CENTER: {
    code: '24',
    display: 'Ambulatory surgical center',
  },
  BIRTHING_CENTER: {
    code: '25',
    display: 'Birthing center',
  },
  MILITARY: {
    code: '26',
    display: 'Military treatment facility',
  },
  SKILLED_NURSING_FACILITY: {
    code: '31',
    display: 'Skilled nursing facility',
  },
  NURSING_FACILITY: {
    code: '32',
    display: 'Nursing facility',
  },
  CUSTODIAL_CARE: {
    code: '33',
    display: 'Custodial care facility',
  },
  HOSPICE: {
    code: '34',
    display: 'Hospice',
  },
  AMBULANCE_LAND: {
    code: '41',
    display: 'Ambulance- land',
  },
  AMBULANCE_AIR_OR_WATER: {
    code: '42',
    display: 'Ambulance- air or water',
  },
  INDEPENDENT_CLINIC: {
    code: '49',
    display: 'Independent clinic',
  },
  FEDERALLY_QUALIFIED_HEALTH_CENTER: {
    code: '50',
    display: 'Federally qualified health center',
  },
  INPATIENT_PSYCHIATRIC_FACILITY: {
    code: '51',
    display: 'Inpatient psychiatric facility',
  },
  PSYCHIATRIC_FACILITY_PARTIAL_HOSPITALIZATION: {
    code: '52',
    display: 'Psychiatric facility- partial hospitalization',
  },
  COMMUNITY_MENTAL_HEALTH_CENTER: {
    code: '53',
    display: 'Community mental health center',
  },
  INTERMEDIATE_CARE_FACILITY: {
    code: '54',
    display:
      'Intermediate care facility/individuals with intellectual disabilities',
  },
  RESIDENTIAL_SUBSTANTIVES_TREATMENT_FACILITY: {
    code: '55',
    display: 'Residential substance abuse treatment facility',
  },
  PSYCHIATRIC_RESIDENTIAL_TREATMENT_CENTER: {
    code: '56',
    display: 'Psychiatric residential treatment center',
  },
  NONRESIDENTIAL_SUBSTANCE_ABUSE_TREATMENTS_FACILITY: {
    code: '57',
    display: 'Non-residential substance abuse treatment facility',
  },
  NON_RESIDENTIAL_OPIOID_TREATMENT_FACILITY: {
    code: '58',
    display: 'Non-residential opioid treatment facility',
  },
  MASS_IMMUNIZATION_CENTER: {
    code: '60',
    display: 'Mass immunization center',
  },
  COMPREHENSIVE_INPATIENT_REHABITATION_FACILITY: {
    code: '61',
    display: 'Comprehensive inpatient rehabilitation facility',
  },
  COMPREHENSIVE_OUTPATIENT_REHABILITATION_FACILITY: {
    code: '62',
    display: 'Comprehensive outpatient rehabilitation facility',
  },
  END_STAGE_RENAL_DISEASE_TREATMENT_FACILITY: {
    code: '65',
    display: 'End-stage renal disease treatment facility',
  },
  PUBLIC_HEALTH_CLINIC: {
    code: '71',
    display: 'Public health clinic',
  },
  RURAL_HEALTH_CLINIC: {
    code: '72',
    display: 'Rural health clinic',
  },
  INDEPENDENT_LABORATORY: {
    code: '81',
    display: 'Independent laboratory',
    isPriority: true,
  },
  OTHER: {
    code: '99',
    display: 'Other place of service',
  },
}

export const placeOfServiceDisplay = mapValues(
  placeOfServices,
  ({ display, code }) => `${code}: ${display}`
)
export const placeOfServiceCode = mapValues(placeOfServices, ({ code }) => code)

export const placeOfServiceOptions = nameValueArrayFromObject(
  placeOfServiceDisplay
)
