import { ReactElement, useEffect } from 'react'

import { createPortal } from 'react-dom'

import SidepanelLayout from 'src/layouts/SidepanelLayout/SidepanelLayout'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { SidepanelPortalContext } from 'src/utils/sidepanel'

const SidepanelPortal: React.FC<{
  showSidepanelPortal?: boolean
  portalContext?: SidepanelPortalContext
  children: ReactElement
}> = ({ showSidepanelPortal = false, portalContext, children }) => {
  const { isSidepanelOpen, closeSidePanel, setSidepanelContext } =
    useSidepanel()

  useEffect(() => {
    if (showSidepanelPortal && portalContext) {
      setSidepanelContext(portalContext)
    }
  }, [portalContext, setSidepanelContext, showSidepanelPortal])

  useEffect(() => {
    if (showSidepanelPortal && isSidepanelOpen) {
      closeSidePanel()
    }
  }, [
    showSidepanelPortal,
    isSidepanelOpen,
    closeSidePanel,
    setSidepanelContext,
  ])

  return (
    <>
      {showSidepanelPortal &&
        createPortal(
          <SidepanelLayout
            isPortal={true}
            isPortalOpen={showSidepanelPortal}
            portalContext={portalContext}
          >
            {children}
          </SidepanelLayout>,
          document.getElementById('application-layout')
        )}
    </>
  )
}

export default SidepanelPortal
