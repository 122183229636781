import { FeatureFlag } from 'types/graphql'

import { Redirect, routes } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Typography from 'src/components/atoms/Typography/Typography'

const FeatureFlagged: React.FC<
  React.PropsWithChildren<{
    flagName: FeatureFlag | 'DUMMY_FEATURE_FLAG_FOR_TESTING'
    childrenIfFlagNotActive?: React.ReactNode
    showFlagNotActiveText?: boolean
    flagNotActiveText?: string
    redirect?: boolean
  }>
> = ({
  flagName,
  children,
  childrenIfFlagNotActive,
  showFlagNotActiveText = false,
  flagNotActiveText = 'It looks like you are trying to access content that is not available.',
  redirect,
}) => {
  const { currentUser } = useEmrAuth()
  const isAllowed = currentUser.featureFlags.includes(flagName as FeatureFlag)
  if (!isAllowed) {
    if (childrenIfFlagNotActive) {
      return <>{childrenIfFlagNotActive}</>
    } else if (showFlagNotActiveText) {
      return <Typography>{flagNotActiveText}</Typography>
    } else if (redirect) {
      return <Redirect to={routes.featureFlagDisabled()} />
    } else {
      return null
    }
  }
  return <>{children}</>
}

export default FeatureFlagged
