import {
  metricToImperialDisplayMap,
  metricToImperialMap,
  ucumUnitToUnitEnumMap,
} from 'common/unitConverter/unitConverter'
import { differenceInYears } from 'date-fns'
import { match } from 'ts-pattern'
import {
  FindPatientBirthHistory,
  Observation,
  UnitInput,
  UpsertBirthHistory,
  UpsertBirthHistoryInputWithUnitInputs,
  UpsertBirthHistoryV2,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import { bloodTypeCodeToEnum } from 'src/data/bloodTypes'
import { deliveryMethodCodeToEnum } from 'src/data/deliveryMethods'
import {} from 'src/data/interpretations'
import { labTestResultCodeToEnum } from 'src/data/labTestResults'
import { presenceIndicatorCodeToEnum } from 'src/data/presenceIndicators'
import {
  FrontendConfiguration,
  codeToEnumBase,
  deepOmit,
  getExistingFormValues,
  getExistingValuesForFrontend,
  useGetFrontendMappings,
} from 'src/data/utils'
import { formatDateFieldValue } from 'src/lib/formatters'

import { MINIMAL_OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

const BIRTH_HISTORY_FRAGMENT = gql`
  fragment BirthHistoryFragment on BirthHistory {
    id
    gestationalAgeAtBirth {
      ...MinimalObservationFragment
    }
    birthWeight {
      ...MinimalObservationFragment
    }
    birthLength {
      ...MinimalObservationFragment
    }
    birthHeadCircumference {
      ...MinimalObservationFragment
    }
    bloodType {
      ...MinimalObservationFragment
    }
    deliveryMethod {
      ...MinimalObservationFragment
    }
    dischargeWeight {
      ...MinimalObservationFragment
    }
    dischargeDate {
      ...MinimalObservationFragment
    }
    lastTotalSerumBilirubin {
      ...MinimalObservationFragment
    }
    totalSerumBilirubinDate {
      ...MinimalObservationFragment
    }
    birthFacility {
      ...MinimalObservationFragment
    }
    newbornHearingScreeningResult {
      ...MinimalObservationFragment
    }
    naturalMotherHistory {
      bloodType {
        ...MinimalObservationFragment
      }
      ageAtPatientBirth {
        ...MinimalObservationFragment
      }
      hadRoutinePrenatalCare {
        ...MinimalObservationFragment
      }
      hadPregnancyComplications {
        ...MinimalObservationFragment
      }
      directAntiglobulinTestResult {
        ...MinimalObservationFragment
      }
      prenatalLabResults {
        streptococcusGroupB {
          ...MinimalObservationFragment
        }
        hiv {
          ...MinimalObservationFragment
        }
        chlamydia {
          ...MinimalObservationFragment
        }
        gonorrhoeae {
          ...MinimalObservationFragment
        }
        syphilis {
          ...MinimalObservationFragment
        }
        rubella {
          ...MinimalObservationFragment
        }
      }
    }
  }
  ${MINIMAL_OBSERVATION_FRAGMENT}
`

const BIRTH_HISTORY_QUERY = gql`
  query FindPatientBirthHistory($id: String!) {
    patient(id: $id) {
      id
      birthDate
      birthHistory {
        ...BirthHistoryFragment
      }
    }
  }
  ${BIRTH_HISTORY_FRAGMENT}
`

const UPSERT_BIRTH_HISTORY_MUTATION = gql`
  mutation UpsertBirthHistory($input: UpsertBirthHistoryInput!) {
    upsertBirthHistory(input: $input) {
      id
      birthHistory {
        ...BirthHistoryFragment
      }
    }
  }
  ${BIRTH_HISTORY_FRAGMENT}
`

const UPSERT_BIRTH_HISTORY_V2_MUTATION = gql`
  mutation UpsertBirthHistoryV2(
    $input: UpsertBirthHistoryInputWithUnitInputs!
  ) {
    upsertBirthHistoryV2(input: $input) {
      id
      birthHistory {
        ...BirthHistoryFragment
      }
    }
  }
  ${BIRTH_HISTORY_FRAGMENT}
`

export const useBirthHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientBirthHistory>(
    BIRTH_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const { currentUser } = useEmrAuth()

  const birthHistory = data?.patient?.birthHistory

  const shouldUseBirthHistoryV2 = currentUser.featureFlags.includes(
    'BIRTH_HISTORY_UNIT_INPUTS'
  )

  const hasRequiredBirthMeasurements = data?.patient?.birthDate
    ? differenceInYears(new Date(), new Date(data?.patient?.birthDate)) <= 2
    : false

  if (shouldUseBirthHistoryV2) {
    return {
      id: patientId,
      birthHistory,
      hasRequiredBirthMeasurements,
      birthHistoryFormValues:
        buildUpsertBirthHistoryInputWithUnitInputs(birthHistory),
      loading,
      error,
    }
  } else {
    const { codeToEnumMap, enumToDisplayMap } = useGetFrontendMappings(
      patientId,
      birthHistory?.__typename
    )

    if (!codeToEnumMap || !enumToDisplayMap) return {}

    const birthHistoryForFrontend = deepOmit(
      getBirthHistoryForFrontend(
        birthHistory,
        codeToEnumMap,
        enumToDisplayMap,
        {
          unitInputs: {
            birthWeight: { unit: 'g', displayUnits: ['lbs', 'oz'] },
            birthLength: { unit: 'cm', displayUnits: ['in'] },
            birthHeadCircumference: { unit: 'cm', displayUnits: ['in'] },
            ageAtPatientBirth: {
              unit: 'a',
              displayUnits: ['a'],
            },
            gestationalAgeAtBirth: {
              unit: 'wk',
              displayUnits: ['wk'],
            },
            dischargeWeight: { unit: 'g', displayUnits: ['lbs', 'oz'] },
            lastTotalSerumBilirubin: {
              unit: 'MILLIGRAMS_PER_DECILITER',
              displayUnits: ['mg/dL'],
            },
          },
        }
      ),
      ['id', '__typename']
    )

    const birthHistoryFormValues = getExistingFormValues(
      {},
      birthHistoryForFrontend
    )

    return {
      id: patientId,
      birthHistory: birthHistoryForFrontend,
      hasRequiredBirthMeasurements,
      birthHistoryFormValues,
      loading,
      error,
    }
  }
}

export const useUpsertBirthHistory = () => {
  const { currentUser } = useEmrAuth()

  const upsertBirthHistory = useMutation<UpsertBirthHistory>(
    UPSERT_BIRTH_HISTORY_MUTATION,
    {
      onCompleted: () => {
        toast.success('Birth history saved')
      },
    }
  )

  const upsertBirthHistoryV2 = useMutation<UpsertBirthHistoryV2>(
    UPSERT_BIRTH_HISTORY_V2_MUTATION,
    {
      onCompleted: () => {
        toast.success('Birth history saved')
      },
    }
  )

  return currentUser.featureFlags.includes('BIRTH_HISTORY_UNIT_INPUTS')
    ? upsertBirthHistoryV2
    : upsertBirthHistory
}

const getBirthHistoryForFrontend = (
  birthHistory,
  codeToEnumMap,
  enumToDisplayMap,
  config: FrontendConfiguration
) => {
  return getExistingValuesForFrontend({
    obj: birthHistory,
    codeToEnumMap,
    enumToDisplayMap,
    config,
  })
}

const birthHistoryDefaultValues: Omit<
  UpsertBirthHistoryInputWithUnitInputs,
  'patientId'
> = {
  gestationalAgeAtBirth: {
    value: undefined,
    unit: 'wk',
  },
  birthWeight: {
    wholeUnit: {
      value: undefined,
      unit: 'g',
    },
    // whole unit will update from component -- no mismatch between metric / imperial
    partialUnit: {
      value: undefined,
      unit: 'oz',
    },
  },
  birthLength: {
    value: undefined,
    unit: 'cm',
  },
  birthHeadCircumference: {
    value: undefined,
    unit: 'cm',
  },
  bloodType: undefined,
  deliveryMethod: undefined,
  dischargeWeight: {
    wholeUnit: {
      value: undefined,
      unit: 'g',
    },
    // whole unit will update from component -- no mismatch between metric / imperial
    partialUnit: {
      value: undefined,
      unit: 'oz',
    },
  },
  dischargeDate: undefined,
  lastTotalSerumBilirubin: {
    value: undefined,
    unit: 'MILLIGRAMS_PER_DECILITER',
  },
  totalSerumBilirubinDate: undefined,
  birthFacility: undefined,
  newbornHearingScreeningResult: undefined,
  naturalMotherHistory: {
    bloodType: undefined,
    ageAtPatientBirth: {
      value: undefined,
      unit: 'a',
    },
    hadRoutinePrenatalCare: undefined,
    hadPregnancyComplications: undefined,
    directAntiglobulinTestResult: undefined,
    prenatalLabResults: {
      streptococcusGroupB: undefined,
      hiv: undefined,
      chlamydia: undefined,
      gonorrhoeae: undefined,
      syphilis: undefined,
      rubella: undefined,
    },
  },
}

type BirthHistoryObservationType =
  | 'UNIT'
  | 'SPLIT_UNIT'
  | 'DATE'
  | 'CODE'
  | 'STRING'

const buildInput = ({
  type,
  observation,
  codeToEnum,
}: {
  type: BirthHistoryObservationType
  observation: Pick<Observation, 'value'>
  codeToEnum?: typeof codeToEnumBase
}):
  | UnitInput
  | { wholeUnit: UnitInput; partialUnit?: UnitInput }
  | Date
  | string => {
  return match(type)
    .with('UNIT', () => buildUnitInput(observation))
    .with('SPLIT_UNIT', () => ({
      wholeUnit: buildUnitInput(observation),
      partialUnit: {
        unit: metricToImperialDisplayMap[observation.value.unit].find(
          (unit) => unit !== metricToImperialMap[observation.value.unit]
        ),
        value: '',
      },
    }))
    .with('DATE', () => formatDateFieldValue(observation.value.value))
    .with('CODE', () =>
      codeToEnum
        ? codeToEnum[observation.value.value]
        : observation.value.value.toString()
    )
    .with('STRING', () => observation.value.value)
    .exhaustive()
}

export const VALUE_DISPLAY_PRECISION = 2

const buildUnitInput = (
  observation: Pick<Observation, 'value'>
): UnitInput | null => {
  if (!observation) return null

  return {
    value:
      Number(observation.value.value) === parseInt(observation.value.value)
        ? Number(observation.value.value).toFixed(0)
        : Number(observation.value.value).toFixed(VALUE_DISPLAY_PRECISION),
    unit:
      ucumUnitToUnitEnumMap[observation.value.unit] ?? observation.value.unit,
  }
}

const generateBuildInputInputs = (
  birthHistory: FindPatientBirthHistory['patient']['birthHistory']
): {
  topLevelBirthHistory: BuildInputInput[]
  topLevelNaturalMotherHistory: BuildInputInput[]
  prenatalLabResults: BuildInputInput[]
} => {
  const topLevelBirthHistory: BuildInputInput[] = [
    {
      type: 'UNIT',
      field: 'gestationalAgeAtBirth',
      observation: birthHistory.gestationalAgeAtBirth,
    },
    {
      type: 'UNIT',
      field: 'birthLength',
      observation: birthHistory.birthLength,
    },
    {
      type: 'UNIT',
      field: 'birthHeadCircumference',
      observation: birthHistory.birthHeadCircumference,
    },
    {
      type: 'SPLIT_UNIT',
      field: 'birthWeight',
      observation: birthHistory.birthWeight,
    },
    {
      type: 'SPLIT_UNIT',
      field: 'dischargeWeight',
      observation: birthHistory.dischargeWeight,
    },
    {
      type: 'CODE',
      field: 'bloodType',
      observation: birthHistory.bloodType,
      codeToEnum: bloodTypeCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'deliveryMethod',
      observation: birthHistory.deliveryMethod,
      codeToEnum: deliveryMethodCodeToEnum,
    },
    {
      type: 'DATE',
      field: 'dischargeDate',
      observation: birthHistory.dischargeDate,
    },
    {
      type: 'DATE',
      field: 'totalSerumBilirubinDate',
      observation: birthHistory.totalSerumBilirubinDate,
    },
    {
      type: 'STRING',
      field: 'birthFacility',
      observation: birthHistory.birthFacility,
    },
    {
      type: 'CODE',
      field: 'newbornHearingScreeningResult',
      observation: birthHistory.newbornHearingScreeningResult,
    },
    {
      type: 'CODE',
      field: 'bloodType',
      observation: birthHistory.bloodType,
      codeToEnum: bloodTypeCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'deliveryMethod',
      observation: birthHistory.deliveryMethod,
      codeToEnum: deliveryMethodCodeToEnum,
    },
    {
      type: 'DATE',
      field: 'dischargeDate',
      observation: birthHistory.dischargeDate,
    },
    {
      type: 'UNIT',
      field: 'lastTotalSerumBilirubin',
      observation: birthHistory.lastTotalSerumBilirubin,
    },
    {
      type: 'STRING',
      field: 'birthFacility',
      observation: birthHistory.birthFacility,
    },
    {
      type: 'CODE',
      field: 'newbornHearingScreeningResult',
      observation: birthHistory.newbornHearingScreeningResult,
    },
  ]

  const topLevelNaturalMotherHistory: BuildInputInput[] = [
    {
      type: 'CODE',
      field: 'bloodType',
      observation: birthHistory.naturalMotherHistory?.bloodType,
      codeToEnum: bloodTypeCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'hadRoutinePrenatalCare',
      observation: birthHistory.naturalMotherHistory?.hadRoutinePrenatalCare,
      codeToEnum: presenceIndicatorCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'hadPregnancyComplications',
      observation: birthHistory.naturalMotherHistory?.hadPregnancyComplications,
      codeToEnum: presenceIndicatorCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'directAntiglobulinTestResult',
      observation:
        birthHistory.naturalMotherHistory?.directAntiglobulinTestResult,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'UNIT',
      field: 'ageAtPatientBirth',
      observation: birthHistory.naturalMotherHistory?.ageAtPatientBirth,
    },
  ]

  const prenatalLabResults: BuildInputInput[] = [
    {
      type: 'CODE',
      field: 'streptococcusGroupB',
      observation:
        birthHistory.naturalMotherHistory?.prenatalLabResults
          ?.streptococcusGroupB,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'hiv',
      observation: birthHistory.naturalMotherHistory?.prenatalLabResults?.hiv,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'chlamydia',
      observation:
        birthHistory.naturalMotherHistory?.prenatalLabResults?.chlamydia,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'gonorrhoeae',
      observation:
        birthHistory.naturalMotherHistory?.prenatalLabResults?.gonorrhoeae,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'syphilis',
      observation:
        birthHistory.naturalMotherHistory?.prenatalLabResults?.syphilis,
      codeToEnum: labTestResultCodeToEnum,
    },
    {
      type: 'CODE',
      field: 'rubella',
      observation:
        birthHistory.naturalMotherHistory?.prenatalLabResults?.rubella,
      codeToEnum: labTestResultCodeToEnum,
    },
  ]

  return {
    topLevelBirthHistory,
    topLevelNaturalMotherHistory,
    prenatalLabResults,
  }
}

type Path =
  | Omit<UpsertBirthHistoryInputWithUnitInputs, 'patientId'>
  | UpsertBirthHistoryInputWithUnitInputs['naturalMotherHistory']
  | UpsertBirthHistoryInputWithUnitInputs['naturalMotherHistory']['prenatalLabResults']

type BuildInputInputBase = {
  observation: Pick<Observation, 'value'> | null
  type: BirthHistoryObservationType
  codeToEnum?: typeof codeToEnumBase
}

type BuildInputInput =
  | (BuildInputInputBase & {
      field: keyof UpsertBirthHistoryInputWithUnitInputs
    })
  | (BuildInputInputBase & {
      field: keyof UpsertBirthHistoryInputWithUnitInputs['naturalMotherHistory']
    })
  | (BuildInputInputBase & {
      field: keyof UpsertBirthHistoryInputWithUnitInputs['naturalMotherHistory']['prenatalLabResults']
    })

const buildUpsertBirthHistoryInputWithUnitInputs = (
  birthHistory: FindPatientBirthHistory['patient']['birthHistory']
) => {
  if (!birthHistory) return null
  const existingBirthHistory: Omit<
    UpsertBirthHistoryInputWithUnitInputs,
    'patientId'
  > = {
    naturalMotherHistory: {
      prenatalLabResults: {},
    },
  }

  const {
    topLevelBirthHistory,
    topLevelNaturalMotherHistory,
    prenatalLabResults,
  } = generateBuildInputInputs(birthHistory)

  const buildInputInputs: {
    path: Path
    defaultPath: Path
    inputs: BuildInputInput[]
  }[] = [
    {
      path: existingBirthHistory,
      defaultPath: birthHistoryDefaultValues,
      inputs: topLevelBirthHistory,
    },
    {
      path: existingBirthHistory.naturalMotherHistory,
      defaultPath: birthHistoryDefaultValues.naturalMotherHistory,
      inputs: topLevelNaturalMotherHistory,
    },
    {
      path: existingBirthHistory.naturalMotherHistory.prenatalLabResults,
      defaultPath:
        birthHistoryDefaultValues.naturalMotherHistory.prenatalLabResults,
      inputs: prenatalLabResults,
    },
  ]

  for (const { path, defaultPath, inputs } of buildInputInputs) {
    for (const { field, observation, type, codeToEnum } of inputs) {
      if (!observation) {
        path[field] = defaultPath[field]
        continue
      }
      path[field] = buildInput({ type, observation, codeToEnum })
    }
  }

  return existingBirthHistory
}
