import { useMemo } from 'react'

import { UserCircleIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { GetPractitionersAndUserPools } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import { parseTaskAssignee, serializeTaskAssignee } from 'src/utils/task'

const QUERY = gql`
  query GetPractitionersAndUserPools {
    taskUserPools {
      id
      displayName
    }
    users {
      id
      givenName
      familyName
      status
    }
  }
`

export const TaskAssignmentMultiSelectDropdownField = ({
  isOpen,
  setIsOpen,
  onSelect,
  onClear = () => {},
  defaultValue,
}: {
  isOpen
  setIsOpen: (isOpen: boolean) => void
  onSelect: (data: { userIds: string[]; userPoolIds: string[] }) => void
  onClear?: () => void
  defaultValue: string[]
}) => {
  const { data: usersData } = useQuery<GetPractitionersAndUserPools>(QUERY)

  const practitionerAndUserPoolsData = useMemo(() => {
    const activeUsers =
      usersData?.users?.filter((user) => user.status === 'ACTIVE') ?? []
    const inactiveUsers =
      usersData?.users?.filter((user) => user.status === 'INACTIVE') ?? []
    const archivedUsers =
      usersData?.users?.filter((user) => user.status === 'ARCHIVED') ?? []

    return [
      { name: 'User pools', value: 'user-pools', disabled: true },
      ...(usersData?.taskUserPools || []).map((pool) => ({
        name: pool.displayName,
        value: serializeTaskAssignee({ type: 'pool', id: pool.id }),
      })),
    ]
      .concat([{ name: 'Active users', value: 'activeUsers', disabled: true }])
      .concat(
        activeUsers.map(({ id: userId, givenName, familyName }) => {
          return {
            name: formatDisplayName({ givenName, familyName }),
            value: serializeTaskAssignee({ type: 'user', id: userId }),
          }
        })
      )
      .concat(
        inactiveUsers.length
          ? [{ name: 'Inactive users', value: 'inactiveUsers', disabled: true }]
          : []
      )
      .concat(
        inactiveUsers.map(({ id: userId, givenName, familyName }) => {
          return {
            name: formatDisplayName({ givenName, familyName }),
            value: serializeTaskAssignee({ type: 'user', id: userId }),
          }
        })
      )
      .concat(
        archivedUsers.length
          ? [{ name: 'Archived users', value: 'archivedUsers', disabled: true }]
          : []
      )
      .concat(
        archivedUsers.map(({ id: userId, givenName, familyName }) => {
          return {
            name: formatDisplayName({ givenName, familyName }),
            value: serializeTaskAssignee({ type: 'user', id: userId }),
          }
        })
      )
  }, [usersData?.users, usersData?.taskUserPools])

  if (!usersData) return null

  return (
    <MultiSelectDropdownField
      testId="user-user-pool-filter"
      open={isOpen}
      onToggle={setIsOpen}
      name="userPoolOrUserIdFilter"
      icon={UserCircleIcon}
      urlParamName="userUserPoolId"
      emptyDisplayText="User or Pool"
      options={practitionerAndUserPoolsData}
      defaultValue={defaultValue}
      onToggleFilter={(values) => {
        const userIds = values.flatMap((value) => {
          const assignee = parseTaskAssignee(value)

          return assignee?.type === 'user' ? [assignee.id] : []
        })

        const poolIds = values.flatMap((value) => {
          const assignee = parseTaskAssignee(value)

          return assignee?.type === 'pool' ? [assignee.id] : []
        })

        onSelect({
          userIds,
          userPoolIds: poolIds,
        })
      }}
      onClearFilter={onClear}
    />
  )
}
