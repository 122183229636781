import { useContext, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  LocalDate,
  LocalTime,
  ZoneId,
  DateTimeFormatter,
  nativeJs,
} from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import { displayAppointmentTimeRange } from 'common/data/appointments'
import { intervalsOverlap } from 'common/data/interval'
import { enumToCode } from 'common/data/timezones'
import { formatDisplayName } from 'common/utils'
import { parse } from 'date-fns'
import { match } from 'ts-pattern'
import {
  AppointmentDefinition,
  AppointmentType,
  FindConflictingAppointments,
  FindConflictingAppointmentsVariables,
  GetAllAppointmentDefinitions,
  GetAllAppointmentDefinitionsVariables,
  FindConflictingOfficeTimeSlots,
  FindConflictingOfficeTimeSlotsVariables,
  GetLocationForWarningCheck,
  GetLocationForWarningCheckVariables,
  GetPractitionerAvailability,
  GetPractitionerAvailabilityVariables,
} from 'types/graphql'

import {
  EM_AVAILABILITY,
  WELL_CHILD_AVAILABILITY,
} from 'src/data/availabilityTypes'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import { useLazyGetPractitionerPreferredAppointmentType } from 'src/hooks/useLazyGetPractitionerPreferredAppointmentType/useLazyGetPractitionerPreferredAppointmentType'
import { createNamedContext } from 'src/utils'

import StackView from '../atoms/StackView'
import Typography from '../atoms/Typography'
import Modal from '../molecules/Modal'

const GET_APPOINTMENT_DEFINITIONS = gql`
  query GetAllAppointmentDefinitions {
    appointmentDefinitions {
      duration
      id
      code
      name
    }
  }
`

const GET_LOCATION_QUERY = gql`
  query GetLocationForWarningCheck($id: String!) {
    location(id: $id) {
      id
      name
      timezone
    }
  }
`

const FIND_CONFLICTING_APPOINTMENTS_QUERY = gql`
  query FindConflictingAppointments($input: ConflictingAppointmentsInput!) {
    conflictingAppointments(input: $input) {
      id
      start
      end
      patient {
        id
        givenName
        familyName
      }
      patientRegistrationIntent {
        id
        givenName
        familyName
      }
      practitioner {
        id
        givenName
        familyName
      }
      location {
        id
        timezone
      }
    }
  }
`

const GET_PRACTITIONER_AVAILABILITY = gql`
  query GetPractitionerAvailability($input: PractitionerAvailabilityInput!) {
    practitionerAvailability(input: $input) {
      appointmentType
      availabilityType
      start
      end
    }
  }
`

const timeFormatter = DateTimeFormatter.ofPattern('h:mm a').withLocale(
  Locale.US
)

const dateFormatter = DateTimeFormatter.ofPattern('MMMM d, yyyy').withLocale(
  Locale.US
)

const FIND_CONFLICTING_OFFICE_TIME_SLOTS = gql`
  query FindConflictingOfficeTimeSlots(
    $input: ConflictingOfficeTimeSlotsInput!
    $practitionerId: String!
  ) {
    conflictingOfficeTimeSlots(input: $input) {
      day
      start
      end
    }
    practitioner(id: $practitionerId) {
      id
      givenName
      familyName
    }
  }
`

const AppointmentBookingWarningsContext = createNamedContext<{
  checkForAppointmentBookingWarnings: (data: {
    startTime: string
    date: Date
    locationId: string
    practitionerId: string
    visitTypes: string[]
  }) => Promise<{ confirmed: boolean }>
}>('AppointmentBookingWarningsContext')

export const useAppointmentBookingWarningsContext = () =>
  useContext(AppointmentBookingWarningsContext)

export const AppointmentBookingsWarningProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [getLocation] = useLazyQuery<
    GetLocationForWarningCheck,
    GetLocationForWarningCheckVariables
  >(GET_LOCATION_QUERY)
  const [findConflictingAppointments] = useLazyQuery<
    FindConflictingAppointments,
    FindConflictingAppointmentsVariables
  >(FIND_CONFLICTING_APPOINTMENTS_QUERY)
  const [findConflictingOfficeTimeSlots] = useLazyQuery<
    FindConflictingOfficeTimeSlots,
    FindConflictingOfficeTimeSlotsVariables
  >(FIND_CONFLICTING_OFFICE_TIME_SLOTS)
  const [getPractitionerAvailability] = useLazyQuery<
    GetPractitionerAvailability,
    GetPractitionerAvailabilityVariables
  >(GET_PRACTITIONER_AVAILABILITY)

  const [getAppointmentDefinitions] = useLazyQuery<
    GetAllAppointmentDefinitions,
    GetAllAppointmentDefinitionsVariables
  >(GET_APPOINTMENT_DEFINITIONS)
  const [getPractitionerPreferredAppointmentType] =
    useLazyGetPractitionerPreferredAppointmentType()
  const [conflictingAppointments, setConflictingAppointments] =
    useState<FindConflictingAppointments['conflictingAppointments']>(null)
  const [doubleBookingModalState, waitForDoubleBookingConfirmation] =
    useConfirmation({
      onCancel: () => setConflictingAppointments(null),
    })
  const [conflictingOfficeTimeSlots, setConflictingOfficeTimeSlots] =
    useState<FindConflictingOfficeTimeSlots>(null)
  const [
    conflictingOfficeTimeModalState,
    waitForConflictingOfficeTimeConfirmation,
  ] = useConfirmation({
    onCancel: () => setConflictingOfficeTimeSlots(null),
  })
  const [wrongAppointmentTypeModalState, waitForAppointmentTypeConfirmation] =
    useConfirmation({
      onCancel: () => setConflictingAppointments(null),
    })

  const checkForAppointmentBookingWarnings = async ({
    startTime,
    date,
    locationId,
    practitionerId,
    visitTypes,
  }: {
    startTime: string
    date: Date
    locationId: string
    practitionerId: string
    visitTypes: string[]
  }) => {
    const locationResult = await getLocation({
      variables: {
        id: locationId,
      },
    })
    const { location } = locationResult.data

    const zonedDateTime = nativeJs(date)
    const appointmentDate = zonedDateTime.toLocalDate()
    const dateString = appointmentDate.toString()
    const startOfAppointment = parse(startTime, 'h:mm a', date)
    const appointmentTime = LocalTime.of(
      startOfAppointment.getHours(),
      startOfAppointment.getMinutes()
    )

    const locationTimezone = ZoneId.of(enumToCode[location.timezone])
    const start = appointmentDate
      .atTime(appointmentTime)
      .atZone(locationTimezone)

    const preferredAppointmentTypeResult =
      await getPractitionerPreferredAppointmentType({
        variables: {
          input: {
            startDate: dateString,
            endDate: dateString,
            practitionerIds: [practitionerId],
          },
        },
      })

    const preferredAppointmentTypeDate =
      preferredAppointmentTypeResult?.data?.practitionerPreferredAppointmentTypeDates?.find(
        (preferredAppointmentType) =>
          preferredAppointmentType.date === dateString &&
          preferredAppointmentType.practitionerId === practitionerId
      )
    const preferredAppointmentType =
      preferredAppointmentTypeDate?.appointmentType ?? 'UNASSIGNED'

    const appointmentDefinitions =
      preferredAppointmentTypeResult?.data?.appointmentDefinitions

    const appointmentTypes = visitTypes.map(
      (id) =>
        appointmentDefinitions.find((definition) => definition.id === id)?.type
    )

    const appointmentDefinitionsQueryResult = await getAppointmentDefinitions()
    const allAppointmentDefinitions =
      appointmentDefinitionsQueryResult.data.appointmentDefinitions

    const initialAppointmentDefinitionsById: {
      [id: string]: AppointmentDefinition
    } = {}
    const appointmentDefinitionsById = allAppointmentDefinitions.reduce(
      (acc, def) => {
        return {
          ...acc,
          [def.id]: def,
        }
      },
      initialAppointmentDefinitionsById
    )
    const duration = visitTypes.reduce(
      (acc, id) => acc + appointmentDefinitionsById[id].duration,
      0
    )

    const wellChildAppointmentTypes: AppointmentType[] = [
      'WELL_CHILD',
      'TELEMEDICINE_WC',
    ]

    const isWrongAppointmentType =
      ((preferredAppointmentType === 'WELL_CHILD' ||
        preferredAppointmentType === 'SCHOOL') &&
        !appointmentTypes.some((appointmentType) =>
          wellChildAppointmentTypes.includes(appointmentType)
        )) ||
      (preferredAppointmentType === 'EM' &&
        !appointmentTypes.some(
          (appointmentType) =>
            !wellChildAppointmentTypes.includes(appointmentType)
        ))

    if (isWrongAppointmentType) {
      const { confirmed } = await waitForAppointmentTypeConfirmation()

      if (!confirmed) return { confirmed }
    }

    const practitionerAvailabilityResult = await getPractitionerAvailability({
      variables: {
        input: {
          practitionerId,
          locationId,
          onDate: dateString,
        },
      },
    })

    const practitionerAvailability =
      practitionerAvailabilityResult?.data?.practitionerAvailability ?? []
    const availabilityOverlappingWithAppointment =
      practitionerAvailability.filter((availability) => {
        return intervalsOverlap(
          {
            start: LocalTime.parse(availability.start),
            end: LocalTime.parse(availability.end),
          },
          {
            start: appointmentTime,
            end: appointmentTime.plusMinutes(duration),
          }
        )
      })
    const availabilityTypesForAppointment =
      availabilityOverlappingWithAppointment.map(
        (availability) => availability.availabilityType
      )

    const isWrongAppointmentTypeForAvailability =
      availabilityTypesForAppointment.some((availabilityType) =>
        match(availabilityType)
          .with(
            WELL_CHILD_AVAILABILITY,
            () =>
              !appointmentTypes.some((appointmentType) =>
                wellChildAppointmentTypes.includes(appointmentType)
              )
          )
          .with(
            EM_AVAILABILITY,
            () =>
              !appointmentTypes.some(
                (appointmentType) =>
                  !wellChildAppointmentTypes.includes(appointmentType)
              )
          )
          .otherwise(() => false)
      )

    if (isWrongAppointmentTypeForAvailability) {
      const { confirmed } = await waitForAppointmentTypeConfirmation()

      if (!confirmed) return { confirmed }
    }

    const isWrongAppointmentTypeForAvailabilityV2 =
      availabilityOverlappingWithAppointment
        .filter((availability) => availability.appointmentType)
        .some(
          (availability) =>
            !appointmentTypes.some(
              (appointmentType) =>
                appointmentType === availability.appointmentType
            )
        )

    if (isWrongAppointmentTypeForAvailabilityV2) {
      const { confirmed } = await waitForAppointmentTypeConfirmation()

      if (!confirmed) return { confirmed }
    }

    const conflictingAppointmentsResult = await findConflictingAppointments({
      variables: {
        input: {
          startTime: start.format(DateTimeFormatter.ISO_DATE_TIME),
          endTime: start
            .plusMinutes(duration)
            .format(DateTimeFormatter.ISO_DATE_TIME),
          practitionerId,
        },
      },
    })

    if (conflictingAppointmentsResult.data.conflictingAppointments.length) {
      setConflictingAppointments(
        conflictingAppointmentsResult.data.conflictingAppointments
      )

      const { confirmed } = await waitForDoubleBookingConfirmation()

      if (!confirmed) return { confirmed }
    }

    const conflictingOfficeTimeSlots = await findConflictingOfficeTimeSlots({
      variables: {
        practitionerId,
        input: {
          targetDate: appointmentDate.toString(),
          start: appointmentTime.toString(),
          end: appointmentTime.plusMinutes(duration).toString(),
          locationId,
          practitionerId,
        },
      },
    })
    if (conflictingOfficeTimeSlots.data.conflictingOfficeTimeSlots.length) {
      setConflictingOfficeTimeSlots(conflictingOfficeTimeSlots.data)

      const { confirmed } = await waitForConflictingOfficeTimeConfirmation()

      if (!confirmed) return { confirmed }
    }

    return { confirmed: true }
  }

  return (
    <AppointmentBookingWarningsContext.Provider
      value={{
        checkForAppointmentBookingWarnings,
      }}
    >
      {children}
      <Modal
        isOpen={wrongAppointmentTypeModalState.isConfirming}
        title="Visit type does not match the practitioner's selected visit type availability."
        content={
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            You are trying to schedule a visit with a visit type that does not
            match the visit type availability selected for the practitioner on
            the target visit date. You can continue or cancel and adjust visit
            scheduling details.
          </Typography>
        }
        modalStyle="warning"
        primaryButton={{
          text: 'Continue',
          onClick: wrongAppointmentTypeModalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          wrongAppointmentTypeModalState.cancel()
        }}
      />

      <Modal
        isOpen={doubleBookingModalState.isConfirming}
        title="Double-booked time slot"
        content={
          conflictingAppointments?.length ? (
            <StackView>
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                If you proceed with scheduling this visit,{' '}
                {formatDisplayName(conflictingAppointments?.[0]?.practitioner)}{' '}
                will have double-booking conflicts with the following visits:
              </Typography>
              <ul>
                {conflictingAppointments.map((appointment) => (
                  <li key={appointment.id}>
                    <Typography
                      textStyle="body-s"
                      color="text-base-color-fg-subtle"
                    >
                      {formatDisplayName(
                        appointment.patient ??
                          appointment.patientRegistrationIntent
                      )}
                      , {displayAppointmentTimeRange(appointment)}
                    </Typography>
                  </li>
                ))}
              </ul>
            </StackView>
          ) : null
        }
        modalStyle="warning"
        primaryButton={{
          text: 'Schedule visit',
          onClick: doubleBookingModalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          doubleBookingModalState.cancel()
        }}
      />

      <Modal
        isOpen={conflictingOfficeTimeModalState.isConfirming}
        title="Overlap with office time block"
        content={
          conflictingOfficeTimeSlots ? (
            <StackView>
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                If you proceed with scheduling this visit,{' '}
                {formatDisplayName(conflictingOfficeTimeSlots.practitioner)}{' '}
                will be booked over the following office time blocks:
              </Typography>
              <ul>
                {conflictingOfficeTimeSlots.conflictingOfficeTimeSlots.map(
                  (officeSlot, i) => (
                    <li key={i}>
                      <Typography
                        textStyle="body-s"
                        color="text-base-color-fg-subtle"
                      >
                        {[
                          LocalDate.parse(officeSlot.day).format(dateFormatter),
                          'from',
                          LocalTime.parse(officeSlot.start).format(
                            timeFormatter
                          ),
                          '-',
                          LocalTime.parse(officeSlot.end).format(timeFormatter),
                        ].join(' ')}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </StackView>
          ) : null
        }
        modalStyle="warning"
        primaryButton={{
          text: 'Schedule visit',
          onClick: conflictingOfficeTimeModalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          conflictingOfficeTimeModalState.cancel()
        }}
      />
    </AppointmentBookingWarningsContext.Provider>
  )
}
