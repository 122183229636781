import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { format, parseISO } from 'date-fns'
import { match } from 'ts-pattern'
import { GetImmunizations } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { formatDateDisplay } from 'src/lib/formatters'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { vfcOptions } from '../../../data/VFCOptions'
import { fundingSourceDescriptionMap } from '../SidepanelVaccineInventory/AddInventoryTable'

const QUERY = gql`
  query GetImmunizations($immunizationIds: [String]) {
    immunizations(immunizationIds: $immunizationIds) {
      id
      occurredAt
      develoVaccineName
      groupVaccineName
      lotNumber
      expirationDate
      verbalRefusalByPatient
      verbalRefusalByCaregiver
      refusalFormCompleted
      patientEligibilityForVFC
      verbalVaccineConsentObtained
      visProvided
      receivedCounseling
      patientWasHealthy
      vaccineConsentFormCompleted
      performerName
      locationId
      locationName
      occurredAt
      doseQuantity {
        value
        unit
      }
      route
      routeDisplay
      site
      siteDisplay
      orderId
      fundingSource
      ndcDetails {
        brandName
        manufacturer
        ndcSale
        ndcUse
        cvxCodeAndDescription
      }
      visVersionDate
    }
  }
`

const ImmunizationDetails = ({ immunization }) => {
  return (
    <>
      <DataDisplayList
        data={[
          { label: 'Vaccine name', value: immunization.develoVaccineName },
          { label: 'Group vaccine name', value: immunization.groupVaccineName },
          {
            label: 'Dose date',
            value: immunization.orderId
              ? format(
                  parseISO(immunization.occurredAt),
                  'MM/dd/yyyy \u2022 h:mmaaa'
                )
              : format(parseISO(immunization.occurredAt), 'MM/dd/yyyy'),
          },
        ]}
        rowHeight="sm"
      />

      {immunization.orderId && (
        <>
          <DataDisplayList
            title="Inventory details"
            data={[
              { label: 'Lot number', value: immunization.lotNumber },
              {
                label: 'Expiration date',
                value: jsDateToLocalDate(immunization.expirationDate).format(
                  dateTimeFormatter('MM/dd/yyyy')
                ),
              },
              {
                label: 'Funding source',
                value: fundingSourceDescriptionMap[immunization.fundingSource],
              },
              {
                label: 'Brand name',
                value: immunization.ndcDetails?.brandName,
              },
              {
                label: 'Manufacturer',
                value: immunization.ndcDetails?.manufacturer,
              },
              {
                label: 'National Drug Code, sale',
                value: immunization.ndcDetails?.ndcSale,
              },
              {
                label: 'National Drug Code, use',
                value: immunization.ndcDetails?.ndcUse.join(','),
              },
              {
                label: 'CVX code & description',
                value: immunization.ndcDetails?.cvxCodeAndDescription,
              },
            ]}
            rowHeight="sm"
          />

          <DataDisplayList
            title="Vaccine administration details"
            data={[
              { label: 'Linked vaccine order', value: immunization.orderId },
              {
                label: 'Practice staff',
                value: immunization.performerName,
              },
              { label: 'Clinic location', value: immunization.locationName },
              {
                label: 'Dose amount & unit',
                value: `${immunization.doseQuantity.value} ${immunization.doseQuantity.unit}`,
              },
              { label: 'Route', value: immunization.routeDisplay },
              { label: 'Site', value: immunization.siteDisplay },
              {
                label: 'Eligibility for Vaccines for Children (VFC)',
                value: vfcOptions.find(
                  (o) => o.value === immunization.patientEligibilityForVFC
                ).name,
              },
              {
                label: 'Verbal vaccine consent obtained',
                value: match(immunization.verbalVaccineConsentObtained)
                  .with(true, () => 'Yes')
                  .with(false, () => 'No')
                  .otherwise(() => '-'),
              },
              {
                label: 'Vaccine consent form',
                value: match(immunization.vaccineConsentFormCompleted)
                  .with(true, () => 'Completed')
                  .with(false, () => 'Not completed')
                  .otherwise(() => '-'),
              },
              {
                label: 'CDC vaccine information statement(s) provided',
                value: (
                  <StackView>
                    <Typography>
                      {match(immunization.visProvided)
                        .with(true, () => 'Yes')
                        .with(false, () => 'No')
                        .otherwise(() => '-')}
                    </Typography>
                    {immunization.visVersionDate && (
                      <Typography
                        color="text-base-color-fg-subtle"
                        textStyle="interface-default-xs"
                      >
                        Publication date:{' '}
                        {formatDateDisplay(immunization.visVersionDate)}
                      </Typography>
                    )}
                  </StackView>
                ),
              },
              {
                label: 'Received counseling from physician or QHP',
                value: match(immunization.receivedCounseling)
                  .with(true, () => 'Yes')
                  .with(false, () => 'No')
                  .otherwise(() => '-'),
              },
              {
                label: 'Healthy and not sick with moderate/severe illness',
                value: match(immunization.patientWasHealthy)
                  .with(true, () => 'Yes')
                  .with(false, () => 'No')
                  .otherwise(() => '-'),
              },
            ]}
            rowHeight="sm"
          />
        </>
      )}
    </>
  )
}

const SidepanelVaccineDoseOverview = () => {
  const {
    sidepanelContext: { administeredImmunizationIds: immunizationIdsParam },
  } = useSidepanel()

  const immunizationIds: string[] | undefined = immunizationIdsParam
    ? JSON.parse(immunizationIdsParam as string)
    : undefined

  const { data, loading } = useQuery<GetImmunizations>(QUERY, {
    variables: {
      immunizationIds,
    },
  })

  if (loading)
    return (
      <StackView className="pt-4">
        <LoadingSpinner />
      </StackView>
    )

  return (
    <SidepanelPage header="Vaccine dose overview">
      <StackView space={75} className="p-4">
        <Tabs
          tabs={data.immunizations.map((immunization) => {
            return {
              name:
                data.immunizations.length > 1
                  ? format(parseISO(immunization.occurredAt), 'M/d/yy')
                  : 'Vaccine Information',
              content: <ImmunizationDetails immunization={immunization} />,
            }
          })}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelVaccineDoseOverview
