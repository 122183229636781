import { formatDate } from '@mobiscroll/react'
import {
  convertMetricValueToImperialValue,
  evaluate,
  extractValue,
  Unit,
  imperialToMetricDisplayMap,
  imperialToMetricMap,
} from 'common/unitConverter/unitConverter'
import { ucumUnitToUnitEnumMap } from 'common/unitConverter/unitConverter'
import {
  isObject,
  keyBy,
  transform,
  cloneDeep,
  map,
  isPlainObject,
  mapValues,
  isArray,
  isEmpty,
  isNil,
} from 'lodash'
import { GetFrontendMappings, ObservationComponent } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { EMPTY_OPTION } from 'src/components/atoms/SelectField'

import { contraceptivePresenceIndicatorDisplayToEnum } from './contraceptivePresenceIndicators'
import { presenceIndicatorDisplayToEnum } from './presenceIndicators'

export const nameValueArrayFromObject = <T extends string>(object: {
  [key in T]: string
}): { name: string; value: T }[] => {
  return Object.entries<string>(object).map(([key, value]) => ({
    name: value,
    value: key as T,
  }))
}

export const deepOmit = (obj: unknown, keysToOmit: string | string[]) => {
  const keysToOmitIndex = keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  )

  function omitFromObject(obj) {
    return transform(obj, (result, value, key) => {
      if (key in keysToOmitIndex) {
        return
      }

      result[key] = isObject(value) ? omitFromObject(value) : value
    })
  }

  return omitFromObject(obj)
}

export const deepOmitBy = (
  obj,
  omitPredicate: (value) => boolean
): Record<string, unknown> => {
  function omitFromObject(obj): Record<string, unknown> {
    return transform(obj, (result, value, key) => {
      if (omitPredicate(value)) {
        return
      }
      const toSet = isObject(value) ? omitFromObject(value) : value
      if (!omitPredicate(toSet)) {
        result[key as string] = toSet
      }
    })
  }

  return omitFromObject(obj)
}

export const FRONTEND_MAPPINGS_QUERY = gql`
  query GetFrontendMappings($input: FrontendMappingsInput!) {
    frontendMappings(input: $input) {
      id
      codeToEnumMap
      enumToDisplayMap
    }
  }
`

export const useGetFrontendMappings = (patientId, resultType) => {
  const { data } = useQuery<GetFrontendMappings>(FRONTEND_MAPPINGS_QUERY, {
    variables: { input: { patientId, resultType } },
  })

  return {
    codeToEnumMap: data?.frontendMappings?.codeToEnumMap,
    enumToDisplayMap: data?.frontendMappings.enumToDisplayMap,
  }
}

export const flattenObject = (obj, flattened) => {
  for (const key in obj) {
    if (isObject(obj[key])) {
      flattened = flattenObject(obj[key], flattened)
    } else {
      flattened[key] = obj[key]
    }
  }
  return flattened
}

export const mapValuesDeep = (obj, fn, key) =>
  isArray(obj)
    ? map(obj, (innerObj, idx) => mapValuesDeep(innerObj, fn, idx))
    : isPlainObject(obj)
    ? mapValues(obj, (val, key) => mapValuesDeep(val, fn, key))
    : isObject(obj)
    ? obj
    : fn(obj, key)

type CheckboxInputNameProps = string
export interface FrontendConfiguration {
  unitInputs?: Record<string, Record<string, string | string[]>>
  checkboxInputs?: CheckboxInputNameProps[]
  // list of name props for checkbox inputs e.g. ['birthWeight','birthLength']
}

// algorithm:
// recursively traverse birthHistory results
// mapping observations to their enum values as described by codeToEnumMap
const checkedCheckboxEnumValues = new Set([
  contraceptivePresenceIndicatorDisplayToEnum['Yes'],
  presenceIndicatorDisplayToEnum['Yes'],
])

const accumulateSplitUnitInputs = (acc, key, value, displayUnits) => {
  if (!acc || displayUnits.length < 1) return
  const [integerDisplayUnit, afterDecimalDisplayUnit] = displayUnits

  const imperialValue = convertMetricValueToImperialValue(
    value,
    imperialToMetricDisplayMap[integerDisplayUnit]
  )

  const imperialParts = imperialValue?.split('.')
  const [integer, afterDecimal] = imperialParts
  const afterDecimalDisplayValue = extractValue(
    evaluate(
      `0.${afterDecimal}`,
      integerDisplayUnit as Unit,
      afterDecimalDisplayUnit as Unit
    ),
    2
  )

  acc[key] = {
    display: {
      [integerDisplayUnit]: integer,
      [afterDecimalDisplayUnit]: afterDecimalDisplayValue,
    },
    value: value.toString(),
    enumValue: undefined,
  }
}

const accumulateUnitInput = (acc, key, value, units) => {
  if (!acc || units.length > 1) return

  const [unit] = units

  // convert to metric if a single imperial unit, if possible
  const displayUnitKey = unit

  const displayValue =
    // might not be something numerical, like 'wk' (week) or 'a' (annum / year)
    unit in imperialToMetricMap
      ? convertMetricValueToImperialValue(value, imperialToMetricMap[unit])
      : !isNil(value)
      ? value.toString()
      : ''

  acc[key] = {
    display: {
      [displayUnitKey]: displayValue,
    },
    value: value.toString(),
    enumValue: undefined,
  }
}

export const getExistingValuesForFrontend = ({
  obj,
  codeToEnumMap,
  enumToDisplayMap,
  config,
  acc = {},
  path = [],
}: {
  obj
  codeToEnumMap: Record<string, string>
  enumToDisplayMap: Record<string, string>
  config: FrontendConfiguration
  acc?
  path?: string[]
}) => {
  if (!obj) return
  let _acc = cloneDeep(acc)
  for (const entry of path) {
    if (!_acc[entry]) _acc[entry] = {}
    _acc = _acc[entry]
  }
  if (!_acc) return

  for (const field in obj) {
    if (!obj[field]) continue
    if (!_acc[field]) _acc[field] = obj[field]

    if (isObject(obj[field])) {
      _acc[field] = getExistingValuesForFrontend({
        obj: obj[field],
        acc: _acc,
        path: [...path, field],
        codeToEnumMap,
        enumToDisplayMap,
        config,
      })

      if (obj[field].__typename === 'Observation') {
        const answerValue = obj[field]?.value?.value
        const answerDisplay = obj[field]?.value?.display
        if (!answerValue && !answerDisplay) continue

        if (obj[field].components) {
          _acc[field] = obj[field].components.map(
            (component: ObservationComponent) => {
              return {
                display: component.value?.display,
                value: component.value?.value,
                enumValue: component.value?.value, // by convention
              }
            }
          )
          continue
        }

        if (config?.unitInputs?.[field]) {
          const _originalUnit = config.unitInputs[field].unit.toString()
          if (config.unitInputs[field].displayUnits.length > 1) {
            accumulateSplitUnitInputs(
              _acc,
              field,
              answerValue,
              config.unitInputs[field].displayUnits
            )
          } else {
            accumulateUnitInput(
              _acc,
              field,
              answerValue,
              config.unitInputs[field].displayUnits
            )
          }
        } else if (config?.checkboxInputs?.includes(field)) {
          _acc[field] = checkedCheckboxEnumValues.has(
            codeToEnumMap?.[answerValue]
          )
            ? true
            : false
        } else {
          // date or enum
          const enumDisplayValue =
            enumToDisplayMap?.[codeToEnumMap?.[answerValue]]

          _acc[field] = {
            display: enumDisplayValue,
            value: answerValue?.toString(),
            enumValue: codeToEnumMap?.[answerValue],
          }
        }
      }
    }
  }

  return _acc
}

const formatDateFieldValue = (value: string | Date | undefined) => {
  if (!value) {
    return ''
  }

  if (typeof value == 'string') {
    const match = value.match(
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    )
    return isEmpty(match) ? '' : match[0]
  } else {
    return formatDate('YYYY-MM-DD', value)
  }
}

export const getExistingFormValues = (acc, obj, path = []) => {
  if (!obj) return
  // do not mutate original map
  let _acc = cloneDeep(acc)
  // walk to current nesting depth
  for (const entry of path) {
    _acc = _acc[entry]
  }
  if (!_acc) return

  for (const field in obj) {
    if (!isObject(obj[field])) {
      // checkboxes
      _acc[field] = obj[field] || false
      continue
    }

    if (isArray(obj[field])) {
      // components
      _acc[field] = obj[field].map((entry) => {
        return entry.value // because frontend inputs go by code
      })
      continue
    }
    if (obj[field].display && obj[field].enumValue) {
      // allow enumValues to supercede display values
      _acc[field] = obj[field].enumValue
      continue
    } else if (obj[field].display) {
      if (isObject(obj[field].display)) {
        accumulateDisplayUnits(
          _acc,
          obj,
          field,
          Object.keys(obj[field].display)
        )
      } else {
        // 'DisplayString'
        _acc[field] = obj[field].display
      }
    } else if (obj[field].value) {
      const formattedDate = formatDateFieldValue(obj[field].value.toString())
      if (formattedDate) {
        _acc[field] = formattedDate
      } else {
        _acc[field] = obj[field].value || EMPTY_OPTION.value
      }
    } else {
      // some other nested structure
      // recurse on that structure
      // e.g. birthHistory.naturalMotherHistory
      if (!_acc[field]) _acc[field] = {}
      const childExistingFormValues = getExistingFormValues(_acc, obj[field], [
        field,
      ])

      if (isEmpty(childExistingFormValues)) {
        _acc[field] = null
      } else {
        _acc[field] = getExistingFormValues(_acc, childExistingFormValues, [
          field,
        ])
      }
    }
  }

  return _acc
}

const accumulateDisplayUnits = (acc, obj, key, displayUnits) => {
  if (!displayUnits) return
  if (displayUnits.length > 1) {
    // assume these are imperial inputs
    // e.g. {lbs: '7', oz: '9'}
    // this assumes we would not split a metric value
    // into multiple metric units
    for (const unit of displayUnits) {
      if (!acc[key]) acc[key] = {}
      if (!acc[key][unit]) acc[key][unit] = {}
      acc[key][unit] = obj[key].display?.[unit]
    }
  } else {
    // a single imperial unit form input
    // values are stored in metric so we should convert
    const [displayUnit] = displayUnits
    if (!acc[key]) acc[key] = {}
    if (!(displayUnit in ucumUnitToUnitEnumMap) && !acc[key][displayUnit])
      acc[key][displayUnit] = {}

    if (
      displayUnit in imperialToMetricMap &&
      imperialToMetricMap[displayUnit]
    ) {
      acc[key][displayUnit] = Number(obj[key].display[displayUnit])
        .toFixed(2)
        .toString()
    } else {
      // a single unit input that is not metric or imperial
      // e.g. 'wk', 'a'
      acc[key][ucumUnitToUnitEnumMap[displayUnit] ?? displayUnit] =
        obj[key].display[displayUnit]
    }
  }
}

export const compactObject = (obj, shouldSetNull = true) => {
  return Object.keys(obj).reduce((acc, key) => {
    const entry = obj[key]
    if (!entry) {
      if (shouldSetNull) {
        acc[key] = null
      }
      return acc
    }
    acc[key] = isObject(entry) ? compactObject(entry) : entry
    return acc
  }, {})
}

// this is needed because we use reduce in other places, and the results
// of that recursion may return the accumulator {}
// this will mutate the original input
export const replaceEmptyObjectEntriesWithNull = (obj) => {
  for (const field in obj) {
    if (!isObject(obj[field])) continue

    if (isEmpty(obj[field])) {
      obj[field] = null
    } else {
      // case: Object of empty object(s) after compactObject is used
      // e.g. {naturalMotherHistory: {  bloodType: {}, ageAtPatientBirth: {}, prenatalLabResults: {}...} }
      replaceEmptyObjectEntriesWithNull(obj[field])
      if (isEmpty(obj[field])) {
        obj[field] = null
      }
    }
  }

  return obj
}

export const deleteEmptyObjectEntries = (obj) => {
  for (const field in obj) {
    if (!isObject(obj[field])) continue

    if (isEmpty(obj[field])) {
      delete obj[field]
    } else {
      // case: Object of empty object(s) after compactObject is used
      // e.g. {naturalMotherHistory: {  bloodType: {}, ageAtPatientBirth: {}, prenatalLabResults: {}...} }
      deleteEmptyObjectEntries(obj[field])
      if (isEmpty(obj[field])) {
        delete obj[field]
      }
    }
  }

  return obj
}

export const compactObjectDeep = (data) => {
  const filteredEntries = Object.entries(data).filter(([_key, value]) => {
    return value !== null && value !== undefined
  })
  const compacted = filteredEntries.map(([key, value]) => {
    return [key, isObject(value) ? compactObjectDeep(value) : value]
  })
  return Object.fromEntries(compacted)
}

export type Mapping = {
  [key: string]: MapEntry
}
export type MapEntry =
  | { display: string; value: string; enumValue: string }
  | {
      [key: string]: Mapping
    }

export const startsWithVowel = (str: string): boolean => {
  if (!str) return false
  return ['A', 'E', 'I', 'O', 'U'].includes(str[0].toUpperCase())
}

export const isValueNumeric = (value: string) => !isNaN(Number(value))

export const getNumericInputValidationFn = (
  errorMsg = 'Please enter numeric values.'
): {
  validate: (value: string) => boolean | string
} => {
  return {
    validate: (value: string) => {
      if (isValueNumeric(value)) return true
      return errorMsg
    },
  }
}

export const codeToEnumBase: Record<string, string> = { EMPTY_OPTION: 'EMPTY' }
