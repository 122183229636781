import { match } from 'ts-pattern'
import { MembershipPlan, MembershipPlanPaymentFrequency } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const membershipPaymentFrequencyDisplay: {
  [Frequency in MembershipPlanPaymentFrequency]: string
} = {
  ANNUALLY: 'Annually',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
}

export const membershipPaymentFrequencyOptions = nameValueArrayFromObject(
  membershipPaymentFrequencyDisplay
)

export const costPerBillingPeriodInCents = ({
  plan,
  frequency,
}: {
  plan: Pick<
    MembershipPlan,
    'annualAmountCents' | 'quarterlyAmountCents' | 'monthlyAmountCents'
  >
  frequency: MembershipPlanPaymentFrequency
}): number =>
  match(frequency)
    .with('ANNUALLY', () => plan.annualAmountCents)
    .with('QUARTERLY', () => plan.quarterlyAmountCents)
    .with('MONTHLY', () => plan.monthlyAmountCents)
    .exhaustive()
