import { Redirect, routes, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import EmptyDataContent from 'src/components/molecules/EmptyDataContent/EmptyDataContent'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import Page from 'src/components/molecules/Page/Page'
import TabNav from 'src/components/molecules/TabNav/TabNav'

import ChargeCaptureSection from './components/ChargeCaptureSection'

const GET_AUTOMATION_RULE_SECTIONS = gql`
  query GetAutomationRuleSections {
    automationRuleSections {
      id
      slug
      title
    }
  }
`

const useGetAutomationRuleSections = () =>
  useQuery(GET_AUTOMATION_RULE_SECTIONS)

const ChargeCaptureSettingsPageV2Inner = () => {
  const { sectionSlug } = useParams()
  const { data, loading } = useGetAutomationRuleSections()

  if (loading) {
    return <LoadingSpinner />
  }
  if (!data || data.automationRuleSections.length === 0) {
    return (
      <EmptyDataContent
        message="No automation rules found"
        details="This is an error. Please try again later."
      />
    )
  }

  const sections = data.automationRuleSections
  const selectedSection = sections.find(
    (section) => section.slug === sectionSlug
  )
  if (!selectedSection) {
    return (
      <Redirect
        to={routes.chargeCaptureSettingsTab({
          sectionSlug: sections[0].slug,
        })}
      />
    )
  }

  return (
    <StackView space={75} direction="row">
      <TabNav
        tabs={sections.map(({ title, id, slug }) => ({
          name: title,
          to: `/charge-capture-settings/${slug}`,
          key: id,
        }))}
        rootPath="/charge-capture-settings"
        vertical
        style="button"
      />
      <ChargeCaptureSection
        key={selectedSection.id}
        sectionId={selectedSection.id}
      />
    </StackView>
  )
}

const ChargeCaptureSettingsPageV2 = () => (
  <FeatureFlagged
    flagName="CHARGE_CAPTURE_UI_V2"
    childrenIfFlagNotActive={
      <Redirect to={routes.chargeCapture()} options={{ replace: false }} />
    }
  >
    <Page
      tag={{ title: 'Charge capture automation rules' }}
      title="Charge capture automation rules"
      description="View the charge capture automation rules that have been configured for the practice to streamline visit billing. Charge capture automation rules can be applied in a number of billing areas, impacting diagnosis codes, billing codes (including units and modifiers), and claim summary details. Some automation rules may involve calculations off of underlying clinical inputs."
    >
      <Divider />
      <Box verticalPadding={75}>
        <ChargeCaptureSettingsPageV2Inner />
      </Box>
    </Page>
  </FeatureFlagged>
)

export default ChargeCaptureSettingsPageV2
