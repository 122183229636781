import { labTestInterpretationConceptCodings } from 'common/cdr/concepts/observations/index'
import { TestResult } from 'types/graphql'

import { nameValueArrayFromObject, codeToEnumBase } from './utils'

export const labTestResultDisplay: {
  [key in TestResult]: string
} = {
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative',
  INCONCLUSIVE: 'Inconclusive',
  NOT_APPLICABLE: 'N/A',
}

export const labTestResults =
  nameValueArrayFromObject<TestResult>(labTestResultDisplay)

export const labTestResultDisplayToEnum = Object.keys(
  labTestResultDisplay
).reduce((acc, key) => {
  acc[labTestResultDisplay[key]] = key
  return acc
}, {})

export const labTestResultCodeToEnum =
  labTestInterpretationConceptCodings.reduce((acc, coding) => {
    if (labTestResultDisplayToEnum[coding.display]) {
      acc[coding.code] = labTestResultDisplayToEnum[coding.display]
    }
    return acc
  }, codeToEnumBase)
