import {
  PatientPayments,
  FetchPatientPayment,
  CreatePatientPaymentsInput,
  CreatePatientTerminalPaymentInput,
  UpdatePatientPaymentV2Input,
  PatientBillingEncounters,
  GetPatientOutstandingBalance,
  GetPaymentTerminals,
  CreateManualTilledPatientPaymentInput,
  RefundCandidPatientPayment,
  RefundCandidPatientPaymentVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import {
  PATIENT_DETAILS_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'

export const paymentRefetchQueries = [
  'PatientPayments',
  'PatientBillingEncounters',
  'PatientCharges',
  'GetPatientOutstandingBalance',
  'GetCurrentPatientPaymentPlan',
]

const CREATE_PATIENT_PAYMENT_MUTATION = gql`
  mutation CreatePatientPayment($input: CreatePatientPaymentsInput!) {
    createPatientPayments(input: $input) {
      id
    }
  }
`

const CREATE_PATIENT_TERMINAL_PAYMENT_MUTATION = gql`
  mutation CreatePatientTerminalPayment(
    $input: CreatePatientTerminalPaymentInput!
  ) {
    createPatientTerminalPayment(input: $input) {
      id
    }
  }
`

const CREATE_MANUAL_TILLED_PATIENT_PAYMENT_MUTATION = gql`
  mutation CreateManualTilledPatientPayment(
    $input: CreateManualTilledPatientPaymentInput!
  ) {
    createManualTilledPatientPayment(input: $input) {
      id
    }
  }
`

const UPDATE_PATIENT_PAYMENT_MUTATION = gql`
  mutation UpdatePatientPayment(
    $id: String!
    $input: UpdatePatientPaymentV2Input!
  ) {
    updatePatientPaymentV2(id: $id, input: $input) {
      id
    }
  }
`

const DELETE_PATIENT_PAYMENT_MUTATION = gql`
  mutation DeletePatientPayment($id: String!) {
    deletePatientPayment(id: $id)
  }
`

export const PATIENT_PAYMENTS_QUERY = gql`
  query PatientPayments($id: String!) {
    patient(id: $id) {
      id
      ...PatientDetailsFragment
      tenant {
        ...TenantLetterheadFragment
      }
    }
    patientPayments(patientId: $id) {
      id
      amountCents
      paidAt
      note
      appointment {
        id
        start
        chiefComplaints
        appointmentDefinitions {
          id
          name
        }
      }
      directPatientChargeEncounter {
        id
        directPatientChargeBillingCode {
          id
          code
          description
        }
        createdAt
        dateOfService
      }
      paymentLineItemType
      paymentMethodType
    }
    develoCandidPatientRefunds(patientId: $id) {
      id
      amountCents
      createdAt
      status
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
      candidPatientPayment {
        id
        amountCents
        paidAt
        note
        patient {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
        appointment {
          id
          start
          chiefComplaints
          appointmentDefinitions {
            id
            name
          }
        }
        directPatientChargeEncounter {
          id
          directPatientChargeBillingCode {
            id
            code
            description
          }
          createdAt
          dateOfService
        }
        paymentLineItemType
        paymentMethodType
      }
    }
    paymentPlanPaymentsForPatient(patientId: $id) {
      id
      amountCents
      createdAt
      status
    }
    paymentPlanRefundsForPatient(patientId: $id) {
      id
      amountCents
      createdAt
      status
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
`

const PATIENT_PAYMENT_QUERY = gql`
  query FetchPatientPayment($id: String!) {
    patientPayment(id: $id) {
      id
      amountCents
      paidAt
      note
      encounterId
      paymentLineItemType
      comments
      paymentTransactionId
      paymentTransaction {
        id
        tilledPaymentIntentId
      }
      candidPatientRefunds {
        id
      }
      appointment {
        id
        start
        chiefComplaints
        appointmentDefinitions {
          id
          name
        }
      }
      directPatientChargeEncounter {
        id
        candidEncounterId
        directPatientChargeBillingCode {
          id
          code
          description
        }
        createdAt
        dateOfService
      }
      createdCandidPatientPaymentEvent {
        id
        occurredAt
        occurredBy {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
        occurredByUserType
      }
      candidPatientPaymentEvents {
        id
        occurredAt
        occurredBy {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
        occurredByUserType
        event
      }
    }
  }
`

const PATIENT_BILLING_ENCOUNTERS_QUERY = gql`
  query PatientBillingEncounters($id: String!) {
    patient(id: $id) {
      id
      candidPatientRollup {
        id
        candidEncounters {
          id
          claims {
            id
            status
            remainingPatientResponsibilityCents
          }
          appointment {
            id
            start
            chiefComplaints
            status
            appointmentDefinitions {
              id
              name
              type
            }
          }
          directPatientChargeEncounter {
            id
            createdAt
            candidEncounterId
            directPatientChargeBillingCode {
              id
              code
              description
            }
          }
        }
      }

      appointments {
        id
        start
        chiefComplaints
        status
        appointmentDefinitions {
          id
          type
        }
      }
    }
  }
`

const GET_PAYMENT_TERMINALS_QUERY = gql`
  query GetPaymentTerminals {
    getPaymentTerminals {
      id
      type
      description
    }
  }
`

const REFUND_CANDID_PATIENT_PAYMENT_MUTATION = gql`
  mutation RefundCandidPatientPayment(
    $input: RefundCandidPatientPaymentInput!
  ) {
    refundCandidPatientPayment(input: $input) {
      id
    }
  }
`

export const usePatientPaymentsQuery = (patientId: string) => {
  const { data, loading, error, refetch } = useQuery<PatientPayments>(
    PATIENT_PAYMENTS_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const candidPayments = data?.patientPayments
  const develoCandidPatientRefunds = data?.develoCandidPatientRefunds
  const paymentPlanPayments = data?.paymentPlanPaymentsForPatient
  const paymentPlanRefunds = data?.paymentPlanRefundsForPatient
  const patient = data?.patient

  return {
    patient,
    candidPayments,
    develoCandidPatientRefunds,
    paymentPlanPayments,
    paymentPlanRefunds,
    loading,
    error,
    refetch,
  }
}

export const usePatientPaymentQuery = (patientPaymentId: string) => {
  const { data, loading, error } = useQuery<FetchPatientPayment>(
    PATIENT_PAYMENT_QUERY,
    {
      variables: { id: patientPaymentId },
    }
  )

  const payment = data?.patientPayment

  return {
    payment,
    loading,
    error,
  }
}

export const usePatientBillingEncountersQuery = ({
  patientId,
  shouldSkipQuery,
}: {
  patientId: string
  shouldSkipQuery?: boolean
}) => {
  const { data, loading, error } = useQuery<PatientBillingEncounters>(
    PATIENT_BILLING_ENCOUNTERS_QUERY,
    {
      variables: { id: patientId },
      skip: shouldSkipQuery,
    }
  )

  const billingEncounters =
    data?.patient?.candidPatientRollup?.candidEncounters ?? []
  const encountersWithBalance = billingEncounters?.filter((encounter) => {
    const hasBalance = encounter?.claims?.some(
      (claim) => claim.remainingPatientResponsibilityCents > 0
    )
    return hasBalance
  })
  const appointments = data?.patient?.appointments ?? []
  const appointmentsWithoutBillingEncounters = appointments
    .filter((appointment) => {
      const hasBillingEncounter = billingEncounters?.some(
        (encounter) => encounter.appointment?.id === appointment.id
      )
      return !hasBillingEncounter
    })
    .filter(
      (appointment) => !['NO_SHOW', 'CANCELLED'].includes(appointment.status)
    )

  return {
    billingEncounters,
    encountersWithBalance,
    appointments,
    appointmentsWithoutBillingEncounters,
    loading,
    error,
  }
}

export const useCreatePatientPaymentMutation = () => {
  const [createPayment, { loading, error }] =
    useMutation<CreatePatientPaymentsInput>(CREATE_PATIENT_PAYMENT_MUTATION, {
      onCompleted: () => {
        toast.success(`Payment submitted`)
      },
      refetchQueries: paymentRefetchQueries,
    })
  return { createPayment, loading, error }
}

export const useCreatePatientTerminalPaymentMutation = () => {
  const [createTerminalPayment, { loading, error }] =
    useMutation<CreatePatientTerminalPaymentInput>(
      CREATE_PATIENT_TERMINAL_PAYMENT_MUTATION,
      {
        onCompleted: () => {
          toast.success(`Payment submitted`)
        },
        onError: (error) => {
          toast.error(error.message)
        },
        refetchQueries: paymentRefetchQueries,
      }
    )
  return { createTerminalPayment, loading, error }
}

export const useCreateManualTilledPatientPaymentMutation = () => {
  const [createManualTilledPayment, { loading, error }] =
    useMutation<CreateManualTilledPatientPaymentInput>(
      CREATE_MANUAL_TILLED_PATIENT_PAYMENT_MUTATION,
      {
        onCompleted: () => {
          toast.success(`Payment submitted`)
        },
        onError: (error) => {
          toast.error(error.message)
        },
        refetchQueries: paymentRefetchQueries,
      }
    )
  return { createManualTilledPayment, loading, error }
}

export const useUpdatePatientPaymentMutation = () => {
  return useMutation<UpdatePatientPaymentV2Input>(
    UPDATE_PATIENT_PAYMENT_MUTATION,
    {
      onCompleted: () => {
        toast.success('Payment updated')
      },
      refetchQueries: paymentRefetchQueries,
    }
  )
}

export const useDeletePatientPaymentMutation = () => {
  return useMutation(DELETE_PATIENT_PAYMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Payment deleted')
    },
    refetchQueries: paymentRefetchQueries,
  })
}

const OUTSTANDING_BALANCE_QUERY = gql`
  query GetPatientOutstandingBalance($id: String!) {
    patient: patient(id: $id) {
      id
      candidBalanceTotalCents
      totalOutstandingBalanceCents
      currentPaymentPlan {
        id
        termMonths
        termAmountCents
        totalBalanceCents
        paymentsRemaining
        totalPaidAmountCents
        remainingBalanceCents
        percentagePaid
        billingDayOfMonth
        paymentPlanPayments {
          id
          amountCents
        }
      }
    }
  }
`

export const usePatientOutstandingBalance = ({
  patientId,
}: {
  patientId: string
}) => {
  const { data, ...rest } = useQuery<GetPatientOutstandingBalance>(
    OUTSTANDING_BALANCE_QUERY,
    {
      variables: { id: patientId },
    }
  )
  const candidBalanceTotalCents = data?.patient?.candidBalanceTotalCents ?? 0
  const totalOutstandingBalanceCents =
    data?.patient?.totalOutstandingBalanceCents ?? 0
  const currentPaymentPlan = data?.patient?.currentPaymentPlan

  return {
    totalOutstandingBalanceCents,
    candidBalanceTotalCents,
    currentPaymentPlan,
    ...rest,
  }
}

export const usePaymentTerminalsQuery = () => {
  const { currentUser } = useEmrAuth()
  return useQuery<GetPaymentTerminals>(GET_PAYMENT_TERMINALS_QUERY, {
    skip: !currentUser.featureFlags.includes('TERMINAL_PAYMENTS'),
  })
}

export const useRefundCandidPatientPaymentMutation = () => {
  return useMutation<
    RefundCandidPatientPayment,
    RefundCandidPatientPaymentVariables
  >(REFUND_CANDID_PATIENT_PAYMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Refund has been initiated')
    },
    refetchQueries: paymentRefetchQueries,
  })
}
