import { ChangeEvent, ReactElement, useEffect, useRef } from 'react'

import clsx from 'clsx'
import { isNull, isUndefined } from 'lodash'

import {
  Controller,
  RegisterOptions,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'

import Label from '../Label/Label'
import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

export type CheckboxPosition = 'left' | 'right'
export type CheckboxStyle = 'default' | 'card'

interface DefaultCheckboxProps {
  id?: string
  label?: string
  description?: string
  name: string
  inline?: boolean
  checked?: boolean
  disabled?: boolean
  color?: string
  highlightColor?: string
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checkboxPosition?: CheckboxPosition
  checkboxStyle?: CheckboxStyle
}
interface CheckboxProps extends DefaultCheckboxProps {
  value?: string
  indeterminate?: boolean
}
export interface FormControlledCheckboxProps extends DefaultCheckboxProps {
  value?: string
  checked?: boolean
  keepDefaultValue?: boolean
  keepDirtyValues?: boolean
  isMultiple?: boolean
  validation?: RegisterOptions
  indeterminate?: boolean
}

const cardClassName = ({ checked }: { checked: boolean }): string =>
  clsx(
    'py-base-space-selectable-inset-m',
    'px-base-space-selectable-inset-l',
    'rounded-base-border-radius-selectable-s',
    'border-base-border-width-selectable-s',
    checked
      ? [
          'bg-comp-toggle-color-primary-selected-enabled-border',
          'border-comp-toggle-color-primary-selected-enabled-border',
          'hover:bg-comp-toggle-color-primary-selected-hover-bg',
          'hover:border-comp-toggle-color-primary-selected-hover-border',
          'focus-within:bg-comp-toggle-color-primary-selected-focused-bg',
          'focus-within:border-comp-toggle-color-primary-selected-focused-border',
        ]
      : [
          'bg-comp-toggle-color-primary-unselected-enabled-primary-bg',
          'border-comp-toggle-color-primary-unselected-enabled-border',
          'hover:bg-comp-toggle-color-primary-unselected-hover-bg',
          'hover:border-comp-toggle-color-primary-unselected-hover-border',
          'focus-within:bg-comp-toggle-color-primary-unselected-focused-bg',
          'focus-within:border-comp-toggle-color-primary-unselected-focused-border',
        ]
  )

const DEFAULT_CHECK_COLOR =
  'text-comp-toggle-color-primary-selected-enabled-border'
const DEFAULT_HIGHLIGHT_COLOR =
  'focus:ring-comp-toggle-color-primary-selected-enabled-border'
const Checkbox = ({
  id,
  inline = false,
  label,
  description,
  onChange,
  checked,
  name,
  disabled,
  value,
  color,
  highlightColor,
  indeterminate,
  checkboxPosition = 'left',
  checkboxStyle = 'default',
  className,
  ...rest
}: CheckboxProps): ReactElement => {
  const checkboxRef = useRef(null)
  const baseClasses = [
    'h-4 w-4',
    'rounded',
    'border-gray-300',
    color ?? DEFAULT_CHECK_COLOR,
    highlightColor ?? DEFAULT_HIGHLIGHT_COLOR,
  ]

  // Indeterminate cannot be set as an HTML attribute for checkboxes.
  // Therefore we have to set with JavaScript
  useEffect(() => {
    if (checkboxRef.current) {
      if (indeterminate) {
        checkboxRef.current.indeterminate = true
      } else {
        checkboxRef.current.indeterminate = false
      }
    }
  }, [indeterminate])

  return (
    <Label htmlFor={id} className={clsx(disabled && 'text-gray-400')}>
      <div
        className={clsx(
          'relative flex',
          disabled && 'pointer-events-none',
          checkboxPosition === 'right' && 'flex-row-reverse justify-between',
          checkboxStyle === 'card' && cardClassName({ checked }),
          checkboxStyle === 'card' ? 'items-center' : 'items-start',
          className
        )}
      >
        <div className="flex h-5 items-center">
          <input
            ref={checkboxRef}
            id={id}
            aria-describedby={`${id}-description`}
            name={name || id}
            type="checkbox"
            aria-checked={checked ? 'true' : 'false'}
            checked={checked}
            data-testid={`cb-test-${name || id}`}
            value={value}
            onChange={(e) => {
              if (!disabled && onChange) onChange(e)
            }}
            className={clsx(baseClasses)}
            readOnly={typeof onChange == 'undefined' || disabled}
            disabled={disabled}
            {...rest}
          />
        </div>
        <StackView
          className={clsx(
            inline && 'space-x-1',
            checkboxStyle !== 'card' && 'ml-3'
          )}
        >
          <Typography>{label}</Typography>
          {description &&
            (inline ? (
              <span id="comments-description" className="text-gray-500">
                <span className="sr-only">{label}</span>
                {description}
              </span>
            ) : (
              <Typography
                id={`${id}-description`}
                textStyle="interface-default-xs"
                color="text-comp-toggle-color-primary-unselected-enabled-primary-fg"
              >
                {description}
              </Typography>
            ))}
        </StackView>
      </div>
    </Label>
  )
}

export const CheckboxField = ({
  name,
  validation,
  value: multipleValueId,
  checked,
  onChange,
  ...rest
}: FormControlledCheckboxProps) => {
  const { register, setValue, unregister } = useFormContext()

  useEffect(() => {
    register(name, validation)
  })

  const value = useWatch({ name })

  useEffect(() => {
    return () => validation?.shouldUnregister && unregister(name)
  }, [name, unregister, validation?.shouldUnregister])

  const defaultOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    if (typeof value === 'object') {
      setValue(name, {
        ...value,
        [e.target.value]: checked,
      })
    } else {
      setValue(name, checked)
    }
  }

  const defaultChecked =
    typeof value === 'object' && !isNull(value) && !isUndefined(value)
      ? !!value[multipleValueId]
      : value

  return (
    <Checkbox
      {...rest}
      value={multipleValueId}
      checked={checked !== undefined ? checked : defaultChecked}
      onChange={onChange ? onChange : defaultOnChange}
      name={name}
      id={multipleValueId || name}
    />
  )
}

export interface MultiCheckboxFieldProps {
  name: string
  values: {
    value: string
    label: string
    description?: string
    disabled?: boolean
  }[]
  validation?: RegisterOptions
  checkboxStyle?: CheckboxStyle
  checkboxPosition?: CheckboxPosition
  showSelectAll?: boolean
  selectAllLabel?: string
  selectAllDisabled?: boolean
  onChange?: (values: string[]) => void
}

export const MultiCheckboxField = ({
  name,
  values,
  checkboxStyle,
  checkboxPosition,
  validation,
  showSelectAll,
  selectAllLabel = 'Select all',
  selectAllDisabled,
  onChange: onChangeProp,
}: MultiCheckboxFieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field }) => {
        const someAreSelected = field.value?.length
        const allAreSelected = values.length === field.value?.length

        const onChange = (values: string[]) => {
          onChangeProp?.(values)

          field.onChange(values)
        }

        return (
          <StackView space={50} direction="col">
            {showSelectAll ? (
              <Checkbox
                name={name}
                value={'selectAll'}
                label={selectAllLabel}
                checkboxPosition={checkboxPosition}
                checkboxStyle={checkboxStyle}
                checked={!!values.length && allAreSelected}
                indeterminate={someAreSelected && !allAreSelected}
                disabled={selectAllDisabled}
                onChange={() => {
                  const newState = allAreSelected
                    ? []
                    : values.map(({ value }) => value)

                  onChange(newState)
                }}
              />
            ) : null}
            {values.map(({ value, label, description, disabled }) => {
              return (
                <Checkbox
                  name={name}
                  key={value}
                  value={value}
                  label={label}
                  description={description}
                  checkboxPosition={checkboxPosition}
                  checkboxStyle={checkboxStyle}
                  checked={!!field.value?.includes(value)}
                  disabled={disabled}
                  onChange={(e) => {
                    const checked = e.target['checked']

                    const newState = checked
                      ? [value, ...(field.value ?? [])]
                      : field.value?.filter((val) => val !== value)

                    onChange(newState)
                  }}
                />
              )
            })}
          </StackView>
        )
      }}
    />
  )
}

export default Checkbox
