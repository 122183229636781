import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import {
  CreatePatientDocumentInput,
  PatientDocumentType,
  DocumentType,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import ClinicalResultTypeSelectField from 'src/components/atoms/ClinicalResultTypeSelectField/ClinicalResultTypeSelectField'
import Divider from 'src/components/atoms/Divider'
import DocumentTypeSelectField from 'src/components/atoms/DocumentTypeSelectField/DocumentTypeSelectField'
import { ExternalRequestTypeSelectField } from 'src/components/atoms/ExternalRequestTypeSelectField'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import MedicalRecordTypeSelectField from 'src/components/atoms/MedicalRecordTypeSelectField'
import MedicationDocumentTypeSelectField from 'src/components/atoms/MedicationDocumentTypeSelectField'
import PatientCaregiverInformationDocumentTypeSelectField from 'src/components/atoms/PatientCaregiverInformationDocumentTypeSelectField'
import { PatientFormTypeSelectField } from 'src/components/atoms/PatientFormTypeSelectField'
import PatientOrderDocumentTypeSelectField from 'src/components/atoms/PatientOrderDocumentTypeSelectField/PatientOrderDocumentTypeSelectField'
import PracticeFormTypeSelectField from 'src/components/atoms/PracticeFormTypeSelectField/PracticeFormTypeSelectField'
import { PracticeLetterTypeSelectField } from 'src/components/atoms/PracticeLetterTypeSelectField'
import { ScreeningToolTypeSelectField } from 'src/components/atoms/ScreeningToolTypeSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import FlatFieldArray from 'src/components/molecules/GenericFormFieldArray/FlatFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { PatientOrdersComboboxField } from 'src/components/PatientOrdersComboboxField/PatientOrdersComboBoxField'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { useCreateDocument } from 'src/pages/PatientChartsPage/PatientDocuments/usePatientDocuments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

export const documentSubTypeSelectFieldConfig: {
  [key in PatientDocumentType]: {
    selectField?: React.FC
    allowTitle: boolean
    titleRequired: boolean
    additionalInputItems?: Item[]
  }
} = {
  SIGNED_PRACTICE_FORM: {
    selectField: PracticeFormTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  COMPLETED_PATIENT_FORM: {
    selectField: PatientFormTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  COMPLETED_EXTERNAL_REQUEST: {
    selectField: ExternalRequestTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  COMPLETED_SCREENING_TOOL: {
    selectField: ScreeningToolTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  COMPLETED_PRACTICE_LETTER: {
    selectField: PracticeLetterTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  CLINICAL_RESULT: {
    selectField: ClinicalResultTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  MEDICAL_RECORD: {
    selectField: MedicalRecordTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  MEDICATION: {
    selectField: MedicationDocumentTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  PATIENT_OR_CAREGIVER_INFORMATION: {
    selectField: PatientCaregiverInformationDocumentTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  PATIENT_ORDER: {
    selectField: PatientOrderDocumentTypeSelectField,
    allowTitle: true,
    titleRequired: false,
  },
  OTHER: {
    allowTitle: true,
    titleRequired: true,
  },
}

export const patientDocumentFormInputListItems: (opts?: {
  type?: string
  patientId?: string
  defaultPatientId?: string
  isDocumentCase?: boolean
}) => Item[] = ({
  type,
  patientId,
  defaultPatientId,
  isDocumentCase = false,
}) => [
  {
    name: 'subType',
    label: 'Document sub-type',
    formInputComponent: documentSubTypeSelectFieldConfig[type]?.selectField,
    hide: isEmpty(type) || !documentSubTypeSelectFieldConfig[type]?.selectField,
    required: true,
    inputProps: {
      testId: 'select-subType',
    },
  },
  {
    name: 'patient',
    label: 'Patient',
    required: true,
    hide: !isDocumentCase,
    formInputComponent: EnhancedPatientSearch,
    inputProps: {
      defaultPatientId,
      disabled: !!defaultPatientId,
    },
  },
  {
    name: 'title',
    label: 'Document title',
    hide: !documentSubTypeSelectFieldConfig[type]?.allowTitle,
    required: documentSubTypeSelectFieldConfig[type]?.titleRequired,
    formInputComponent: InputField,
  },
  {
    name: 'serviceRequestIds',
    label: 'Order(s)',
    required: true,
    formInputComponent: FlatFieldArray,
    inputProps: {
      FormInputComponent: PatientOrdersComboboxField,
      addButtonLabel: 'Add an additional order',
      formInputComponentProps: {
        patientId,
      },
    },
    hide: type !== 'CLINICAL_RESULT',
  },
]

export const CreatePatientDocument: React.FC<{
  type: PatientDocumentType | DocumentType
}> = ({ type }) => {
  const { closeSidePanel, sidepanelContext } = useSidepanel()
  const encounterId = sidepanelContext?.encounterId
  const [createPatientDocument, { loading: creatingPatientDocument }] =
    useCreateDocument()
  const { isPatientChart, patientId: inferredPatientId } = useIsPatientChart()
  const { currentUser } = useEmrAuth()
  const formMethods = useForm<CreatePatientDocumentInput>({
    defaultValues: { signedByPractitionerId: currentUser.id },
  })
  const inputPatientId = formMethods.watch('patientId')
  const patientId = inferredPatientId ?? inputPatientId

  const onSubmit = async (data: CreatePatientDocumentInput) => {
    const { binary, ...input } = omit(data, ['patient', 'patientDisplayText'])

    if (isPatientChart) {
      createPatientDocument({
        variables: {
          input: {
            ...input,
            patientId,
            type,
            binary: {
              content: await base64EncodeFile(binary[0]),
              contentType: binary[0].type,
            },
            encounterId,
          },
        },
        onCompleted: closeSidePanel,
      })
    } else {
      formMethods.setError('binary', {
        message:
          'Can only create documents when in the context of a patient chart',
      })
    }
  }

  return (
    <SidepanelForm
      data-testid="create-patient-document-form"
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPatientDocument,
      }}
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <FormInputList
        items={[
          ...patientDocumentFormInputListItems({ type, patientId }),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

const SidepanelPatientDocumentUpload = () => {
  const formMethods = useForm<{
    documentType: PatientDocumentType | DocumentType
  }>()
  const documentType = formMethods.watch('documentType')

  return (
    <SidepanelPage
      header="Document upload"
      description="Upload a document to the patient's chart."
    >
      <StackView className="flex h-full">
        <Form className="flex-0 px-6" formMethods={formMethods}>
          <FormInputList
            title="Document Details"
            items={[
              {
                name: 'documentType',
                label: 'Document type',
                formInputComponent: DocumentTypeSelectField,
                inputProps: {
                  patient: true,
                },
                required: true,
              },
            ]}
          />
          <Divider />
        </Form>
        <CreatePatientDocument type={documentType} />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientDocumentUpload
