import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { MbscRecurrenceRule } from '@mobiscroll/react'
import clsx from 'clsx'
import { isAppointmentType } from 'common/data/appointmentTypes'
import { parse } from 'date-fns'
import { Options, RRule } from 'rrule'
import {
  AppointmentType,
  GroupedAvailabilityException,
  WeekdayKey,
} from 'types/graphql'

import {
  Form,
  get,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
  UseFormReturn,
} from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import { FieldLabel } from 'src/components/atoms/Label/Label'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import AvailabilityExceptionLocationCell from 'src/components/Location/AvailabilityExceptionLocationCell'
import {
  DatePickerField,
  DEFAULT_DATE_FORMAT,
} from 'src/components/molecules/DatePicker/DatePicker'
import Modal from 'src/components/molecules/Modal/Modal'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { TimeIntervalSelect } from 'src/components/molecules/TimeIntervalSelect/TimeIntervalSelect'
import { ToggleField } from 'src/components/molecules/Toggle/Toggle'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import {
  AvailabilityTypeOption,
  availabilityTypeOptions,
  VISIT_AVAILABILITY,
} from 'src/data/availabilityTypes'
import { formatDateFieldValue } from 'src/lib/formatters'
import {
  format12HoursTo24,
  format24HoursTo12,
  getEndRecurrenceValues,
  getEndsRecurrenceOptions,
  getMonthlyRecurrenceValue,
  getRecurrenceRule,
  getWeekdaysRecurrenceRule,
  getWeeklyRecurrenceValue,
  RecurrenceRuleType,
} from 'src/utils/recurrenceRulesHelpers'

import RepeatEventForm from '../RepeatEventForm/RepeatEventForm'

type AvailabilityType =
  | 'office-time'
  | 'visit-availability'
  | 'well-child-availability'
  | 'em-availability'

export type Hours = {
  start: string
  end: string
  availabilityType?: AvailabilityType
  appointmentType?: AppointmentType
}

export interface PractitionerAvailabilityExceptionConfig {
  allDay?: boolean
  visitHours?: Hours[]
  repeatActive: boolean
  recurrenceRule?: RecurrenceRuleType
}

type HoursForFormDefault = {
  start: string
  end: string
  availabilityType?: AvailabilityTypeOption
  appointmentType?: AppointmentType
}

interface PractitionerAvailabilityExceptionConfigForFormDefault {
  allDay?: boolean
  visitHours?: HoursForFormDefault[]
  repeatActive: boolean
  recurrenceRule?: RecurrenceRuleType
}

export type DayType = {
  [key in WeekdayKey]: boolean
}

export interface PractitionerAvailabilityExceptionLocationInputForm {
  working: boolean
  repeatActive: boolean
  recurrence: MbscRecurrenceRule
  location: string
  locations: { [locationId: string]: boolean }
  date: string
  weekdaysObj: DayType
  monthlyRecurrenceRule?: string
  everyCount: string
  endsOn?: string
  occurrences?: number
  days: DayType
  ends: 'never' | 'on' | 'occurrences'
  configuration: PractitionerAvailabilityExceptionConfigForFormDefault
  allDay?: boolean
  practitioner?: string
}

const CHECK_APPOINTMENTS_TO_CANCEL = gql`
  query CheckPractitionerAppointmentsToCancel(
    $input: CheckPractitionerAppointmentsToCancelInput!
  ) {
    checkPractitionerAppointmentsToCancel(input: $input) {
      id
    }
  }
`

export const CREATE_AVAILABILITY_EXCEPTION_MUTATION = gql`
  mutation CreateAvailabilityExceptions(
    $input: CreateAvailabilityExceptionsInput!
  ) {
    createGroupedAvailabilityException(input: $input) {
      id
      date
      practitionerId
      configuration
      locationIds
    }
  }
`

export const UPDATE_AVAILABILITY_EXCEPTION_MUTATION = gql`
  mutation UpdateAvailabilityException(
    $referenceId: String!
    $input: UpdateAvailabilityExceptionsInput!
  ) {
    updateGroupedAvailabilityException(
      referenceId: $referenceId
      input: $input
    ) {
      id
      date
      practitionerId
      configuration
      locationIds
    }
  }
`

interface WithConfig {
  configuration: PractitionerAvailabilityExceptionConfig
}
export type PractitionerGroupedExceptionWithConfig = Omit<
  GroupedAvailabilityException,
  'configuration'
> &
  WithConfig

interface PractitionerAvailabilityExceptionFormProps {
  onClose: () => void
  groupedAvailabilityException?: PractitionerGroupedExceptionWithConfig
  variant?: 'sidepanel'
  mode?: 'add' | 'edit'
}

const FormContainer = ({
  variant,
  formMethods,
  onSubmit,
  children,
  disabled,
  submitting,
}: {
  variant?: 'sidepanel'
  formMethods: UseFormReturn<PractitionerAvailabilityExceptionLocationInputForm>
  onSubmit: (data: PractitionerAvailabilityExceptionLocationInputForm) => void
  children: React.ReactNode
  disabled: boolean
  submitting: boolean
}) => {
  if (variant === 'sidepanel') {
    return (
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          disabled,
          submitting,
          submitText: 'Save',
        }}
      >
        {children}
      </SidepanelForm>
    )
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      {children}
    </Form>
  )
}

const MultiTimeForm = ({ name, timeIntervalRules }) => {
  const { currentUser } = useEmrAuth()

  const {
    formState: { errors },
  } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    name,
  })
  return (
    <>
      <StackView space={100} divider>
        {fields.map((_, index) => {
          const hasError = !!get(
            errors,
            `configuration.visitHours.${index}.start`
          )
          return (
            <StackView
              space={75}
              key={`configuration.visitHours.${index}`}
              className={clsx(index > 0 && 'pt-4')}
            >
              <StackView
                direction="row"
                alignItems="center"
                space={75}
                justifyContent="start"
              >
                <Box grow>
                  <FieldLabel
                    name={`configuration.visitHours.${index}`}
                    className="pb-1"
                    errorClassName="pb-1"
                  >
                    Availability
                  </FieldLabel>
                  <TimeIntervalSelect
                    keepDefaultValue
                    keepDirtyValues
                    id={`visit-hours-${index}`}
                    intervalType="time"
                    rules={timeIntervalRules}
                    stepMinute={15}
                    name={`configuration.visitHours.${index}`}
                  />
                  <FieldError
                    name={`configuration.visitHours.${index}.start`}
                  />
                </Box>
                {fields.length > 1 && (
                  <Box>
                    <Button
                      className={hasError ? '' : 'mt-6'}
                      buttonStyle="secondary"
                      onClick={() => {
                        remove(index)
                      }}
                    >
                      <StackView direction="row" space={50}>
                        <TrashIcon className="h-5 w-5" />
                        <Typography>Remove</Typography>
                      </StackView>
                    </Button>
                  </Box>
                )}
              </StackView>
              <Box flex="3/12">
                <DropdownField
                  label="Visit type"
                  mode="id"
                  options={availabilityTypeOptions(currentUser)}
                  name={`configuration.visitHours.${index}.availabilityType`}
                  validation={{ required: true }}
                />
              </Box>
            </StackView>
          )
        })}
      </StackView>

      <StackView direction="row" justifyContent="start">
        <Button
          testId="add-visit-hours-btn"
          className="mt-4"
          buttonStyle="secondary"
          onClick={() => {
            append({
              start: '',
              end: '',
              availabilityType: VISIT_AVAILABILITY,
            })
          }}
        >
          <StackView direction="row" space={50}>
            <PlusCircleIcon className="h-5 w-5" />
            <Typography>Add</Typography>
          </StackView>
        </Button>
      </StackView>
    </>
  )
}

const PractitionerAvailabilityExceptionForm = ({
  onClose,
  groupedAvailabilityException = undefined,
  variant,
  mode,
}: PractitionerAvailabilityExceptionFormProps) => {
  const {
    currentUser: { practitionerId },
  } = useEmrAuth()
  const client = useApolloClient()
  const [appointmentsToCancel, setAppointmentsToCancel] = useState<number>(0)
  const [checking, setChecking] = useState(false)
  const [updateGroupedAvailabilityException, { loading: updating }] =
    useMutation(UPDATE_AVAILABILITY_EXCEPTION_MUTATION, {
      refetchQueries: ['GroupedAvailabilityExceptionsQuery'],
    })
  const [createGroupedAvailabilityException, { loading: creating }] =
    useMutation(CREATE_AVAILABILITY_EXCEPTION_MUTATION, {
      refetchQueries: ['GroupedAvailabilityExceptionsQuery'],
    })
  let options: Partial<Options>

  if (groupedAvailabilityException?.configuration?.recurrenceRule?.rule) {
    const { origOptions } = RRule.fromString(
      groupedAvailabilityException?.configuration?.recurrenceRule.rule
    )
    options = origOptions
  }

  const date = groupedAvailabilityException?.date
    ? parse(groupedAvailabilityException?.date, 'yyyy-MM-dd', new Date())
    : new Date()
  const frequency = RRule.FREQUENCIES[options?.freq]?.toLowerCase()
  const { ends, endsOn, occurrences } = getEndRecurrenceValues(
    groupedAvailabilityException?.configuration?.recurrenceRule?.rule
  )
  const formMethods =
    useForm<PractitionerAvailabilityExceptionLocationInputForm>({
      mode: 'onSubmit',
      defaultValues: {
        working: groupedAvailabilityException
          ? groupedAvailabilityException.configuration.visitHours.length > 0
          : true,
        practitioner: groupedAvailabilityException?.practitionerId,
        occurrences,
        ends,
        endsOn,
        repeatActive:
          groupedAvailabilityException?.configuration?.repeatActive || false,
        date: formatDateFieldValue(date),
        location: groupedAvailabilityException?.locationIds[0] || undefined,
        locations:
          groupedAvailabilityException?.locationIds?.reduce(
            (acc, locationId) => {
              acc[locationId] = true
              return acc
            },
            {}
          ) || {},
        weekdaysObj: groupedAvailabilityException?.configuration?.recurrenceRule
          ?.rule
          ? getWeeklyRecurrenceValue(
              groupedAvailabilityException?.configuration?.recurrenceRule?.rule
            )
          : {},
        monthlyRecurrenceRule: getMonthlyRecurrenceValue(
          groupedAvailabilityException?.configuration?.recurrenceRule?.rule
        ),
        recurrence: {
          repeat:
            (frequency as 'yearly' | 'daily' | 'monthly' | 'weekly') ||
            'weekly',
          interval: options?.interval || 1,
        },
        configuration: {
          allDay: groupedAvailabilityException?.configuration?.allDay || false,
          visitHours: (
            groupedAvailabilityException?.configuration?.visitHours || [
              {
                start: '',
                end: '',
                availabilityType: VISIT_AVAILABILITY,
              },
            ]
          ).map((obj) => {
            return {
              ...obj,
              availabilityType: obj.appointmentType ?? obj.availabilityType,
              start: format24HoursTo12(obj.start),
              end: format24HoursTo12(obj.end),
            }
          }),
        },
      },
    })
  const [repeatActive, allDay] = useWatch({
    control: formMethods.control,
    name: ['repeatActive', 'configuration.allDay'],
  })

  const createException = (data) => {
    const { input } = getInputData(data)
    input['appointmentsToCancel'] = appointmentsToCancel
    void createGroupedAvailabilityException({
      variables: {
        input,
      },
      update: (cache, { data: { createGroupedAvailabilityException } }) => {
        cache.modify({
          fields: {
            groupedAvailabilityExceptions: (exceptionList = []) => {
              const newGroupedAvailabilityException = cache.writeFragment({
                data: createGroupedAvailabilityException,
                fragment: gql`
                  fragment NewGroupedException on GroupedAvailabilityException {
                    id
                    date
                    practitionerId
                    __typename
                    configuration
                    locationIds
                  }
                `,
              })
              return [...exceptionList, newGroupedAvailabilityException]
            },
          },
        })
      },
      onCompleted: () => onClose(),
    })
  }

  const updateException = (data) => {
    const { configuration, input } = getInputData(data)
    input['appointmentsToCancel'] = appointmentsToCancel
    void updateGroupedAvailabilityException({
      variables: {
        input,
        referenceId: groupedAvailabilityException.id,
      },
      optimisticResponse: {
        updateGroupedAvailabilityException: {
          id: groupedAvailabilityException.id,
          date: data.date,
          __typename: 'GroupedAvailabilityException',
          practitionerId,
          configuration,
          locationIds: [data.location],
        },
      },
      onCompleted: () => {
        onClose()
      },
    })
  }

  const getInputData = (
    data: PractitionerAvailabilityExceptionLocationInputForm
  ) => {
    const recurrenceRule = getRecurrenceRule(
      parse(data.date, DEFAULT_DATE_FORMAT, new Date()),
      !data.working,
      data.working ? data.configuration.visitHours[0].start : undefined,
      data.working
        ? data.configuration.visitHours[
            data.configuration.visitHours.length - 1
          ].end
        : undefined,
      data.recurrence.repeat,
      data.recurrence.interval,
      getWeekdaysRecurrenceRule(data.weekdaysObj),
      data?.monthlyRecurrenceRule,
      getEndsRecurrenceOptions(
        data.ends,
        parse(data.endsOn, DEFAULT_DATE_FORMAT, new Date()),
        data.occurrences
      ),
      data.repeatActive
    )
    const configuration = {
      visitHours: data.working
        ? data?.configuration?.visitHours.map((obj) => {
            return {
              ...obj,
              start: format12HoursTo24(obj.start),
              end: format12HoursTo24(obj.end),
              availabilityType: isAppointmentType(obj.availabilityType)
                ? VISIT_AVAILABILITY
                : obj.availabilityType,
              appointmentType: obj.availabilityType,
            }
          })
        : [],
      repeatActive: data.repeatActive,
      recurrenceRule,
      allDay: data.configuration.allDay,
    }

    const input = {
      configuration,
      date: data.date,
      locationIds: Object.entries(data.locations)
        .filter(([_key, val]) => !!val)
        .map(([key, _val]) => key),
      practitionerId: data.practitioner ?? practitionerId,
    }
    return { recurrenceRule, configuration, input }
  }

  const onSubmit = async (
    data: PractitionerAvailabilityExceptionLocationInputForm
  ) => {
    const { recurrenceRule, input, configuration } = getInputData(data)

    setChecking(true)

    const appointmentsToCancel = await Promise.all(
      input.locationIds.map(async (locationId) => {
        const checkInput = {
          recurrenceRule: recurrenceRule.rule,
          startDate: input.date,
          locationId,
          configuration,
          practitionerId: input.practitionerId,
        }

        const response = await client.query({
          query: CHECK_APPOINTMENTS_TO_CANCEL,
          variables: {
            input: checkInput,
          },
        })

        return response.data.checkPractitionerAppointmentsToCancel.length
      })
    )
    setChecking(false)

    const totalAppointmentsToCancel = appointmentsToCancel.reduce(
      (acc, val) => acc + val,
      0
    )

    if (totalAppointmentsToCancel > 0) {
      setAppointmentsToCancel(totalAppointmentsToCancel)
    } else if (mode === 'add') {
      createException(data)
    } else if (mode === 'edit') {
      updateException(data)
    } else if (groupedAvailabilityException) {
      updateException(data)
    } else {
      createException(data)
    }
  }
  const timeIntervalRules = allDay
    ? { validate: {}, required: false }
    : { required: 'This field is required' }

  const Container = variant === 'sidepanel' ? React.Fragment : Card

  const locationFieldName = 'locations'

  const working = formMethods.watch('working')

  return (
    <Container>
      <FormContainer
        variant={variant}
        formMethods={formMethods}
        onSubmit={onSubmit}
        disabled={creating || updating || checking}
        submitting={creating || updating}
      >
        <Box horizontalPadding={100} verticalPadding={100}>
          <Typography textStyle="subtitle">Details</Typography>
          <Space space={100} />
          <PractitionerDropdownCell
            name="practitioner"
            label="Practitioner"
            validation={{
              required: true,
            }}
          />
          <FieldError name="practitioner" />
          <Space space={75} />
          <DatePickerField
            mode="date"
            name="date"
            label="Start date"
            controls={['calendar']}
            validation={{
              required: true,
              setValueAs: (value) => formatDateFieldValue(value),
            }}
          />
          <Space space={75} />
          <FieldLabel name={locationFieldName}>Practice location</FieldLabel>
          <Space space={50} />
          <StackView space={50}>
            <AvailabilityExceptionLocationCell multiple />
            <FieldError
              name={locationFieldName}
              defaultErrorMessage="You need to pick a location"
            />
          </StackView>
          <Space space={125} />
          <Divider />
          <Space space={125} />
          <StackView
            testId="is-working-row"
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography size="m">Available</Typography>
            <ToggleField name="working" activeText="Yes" inactiveText="No" />
          </StackView>
          {working ? (
            <>
              <Space space={100} />
              <MultiTimeForm
                name="configuration.visitHours"
                timeIntervalRules={timeIntervalRules}
              />
            </>
          ) : null}
          <Space space={125} />
          <Divider />
          <Space space={125} />
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography size="m">Repeat</Typography>
            <ToggleField
              id={`toggle-repeat`}
              activeText="Repeats"
              inactiveText="Does not repeat"
              name="repeatActive"
            />
          </StackView>
          {repeatActive && <RepeatEventForm />}
        </Box>
        <Space space={75} />
        {variant !== 'sidepanel' ? (
          <Box
            inverse
            verticalPadding={75}
            horizontalPadding={100}
            className="rounded-b-lg"
          >
            <StackView
              direction="row"
              justifyContent="end"
              alignItems="center"
              space={50}
            >
              <Button buttonStyle="secondary" text="Cancel" onClick={onClose} />
              <Submit
                buttonStyle="primary"
                text="Save"
                disabled={creating || updating || checking}
                loading={creating || updating}
              />
            </StackView>
          </Box>
        ) : null}
      </FormContainer>
      <Modal
        isOpen={appointmentsToCancel > 0}
        title={`${appointmentsToCancel} visits need to be rescheduled`}
        content={`By saving these changes, you will need to have ${appointmentsToCancel} visits rescheduled due to the new conflict. Are you sure you would like to save and continue?`}
        modalStyle="danger"
        primaryButton={{
          text: 'Save and continue',
          onClick: () => {
            const data = formMethods.getValues()
            const { input } = getInputData(data)
            input['appointmentsToCancel'] = appointmentsToCancel
            if (groupedAvailabilityException) {
              updateException(data)
            } else {
              createException(data)
            }
          },
        }}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            setAppointmentsToCancel(0)
          }
        }}
      />
    </Container>
  )
}

export default PractitionerAvailabilityExceptionForm
