import { FindBillingProviders } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'
import { useQuery } from '@redwoodjs/web'

import SelectField from 'src/components/atoms/SelectField/SelectField'

import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

const QUERY = gql`
  query FindBillingProviders {
    billingProviders {
      id
      name
      npi
    }
  }
`

export const useDefaultBillingProvider = () => {
  const { data } = useQuery<FindBillingProviders>(QUERY)

  if (data?.billingProviders?.length === 1) {
    return data?.billingProviders[0].id
  }
}

export const BillingProviderSelectField = (props: SelectFieldProps) => {
  const { data, loading } = useQuery<FindBillingProviders>(QUERY)
  const { setValue } = useFormContext()

  if (loading) return null

  const hasOnlyOneBillingProvider = data?.billingProviders.length === 1

  if (hasOnlyOneBillingProvider) {
    setValue(props.name, data?.billingProviders[0].id)
  }

  return (
    <StackView>
      <SelectField
        {...props}
        emptyAs={null}
        disabled={hasOnlyOneBillingProvider}
        options={data.billingProviders.map(({ id, npi, name }) => ({
          value: id,
          display: `${name} (${npi})`,
        }))}
      />
      {hasOnlyOneBillingProvider && (
        <Typography textStyle="description">
          Your practice only has one configured billing provider entity.
        </Typography>
      )}
    </StackView>
  )
}

export default BillingProviderSelectField
