import { screeningTestInterpretationConceptCodings } from 'common/cdr/concepts/observations/index'
import { nameValueArrayFromObject } from 'common/utils'
import { Interpretation } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge'

import { codeToEnumBase } from './utils'

export const interpretationDisplay: {
  [key in Interpretation]: string
} = {
  NORMAL: 'Normal',
  ABNORMAL: 'Abnormal',
  INCONCLUSIVE: 'Inconclusive',

  CRITICAL: 'Critical',
  NON_CRITICAL: 'Non-critical',

  NONE_MINIMAL_DEPRESSION: 'No or Minimal Depression',
  MILD_DEPRESSION: 'Mild Depression',
  MODERATE_DEPRESSION: 'Moderate Depression',
  MODERATELY_SEVERE_DEPRESSION: 'Moderately Severe Depression',
  SEVERE_DEPRESSION: 'Severe Depression',

  MINIMAL_ANXIETY: 'Minimal Anxiety',
  MILD_ANXIETY: 'Mild Anxiety',
  MODERATE_ANXIETY: 'Moderate Anxiety',
  SEVERE_ANXIETY: 'Severe Anxiety',

  CRAFFT_LOW: 'Low',
  CRAFFT_MEDIUM: 'Medium',
  CRAFFT_HIGH: 'High',

  VANDERBILT_SUBTYPE_POSITIVE: 'Positive',

  ASQ_ASSESSMENT_NEEDED: 'Assessment Needed',
  ASQ_MONITOR: 'Monitor',
  ASQ_ON_SCHEDULE: 'On Schedule',
  ASQ_FOLLOW_UP: 'Follow-up',

  HEARING_SCREENING_PASS_BOTH: 'Pass both',
  HEARING_SCREENING_PASS_RIGHT_REFER_LEFT: 'Pass right, refer left',
  HEARING_SCREENING_PASS_LEFT_REFER_RIGHT: 'Pass left, refer right',
  HEARING_SCREENING_REFER_BOTH: 'Refer both',

  M_CHAT_R_LOW_RISK: 'Low Risk',
  M_CHAT_R_MEDIUM_RISK: 'Medium Risk',
  M_CHAT_R_HIGH_RISK: 'High Risk',
}

export const interpretationToColor: {
  [key in Interpretation]: BadgeColor
} = {
  NORMAL: 'green',
  ABNORMAL: 'red',
  INCONCLUSIVE: 'yellow',

  CRITICAL: 'red',
  NON_CRITICAL: 'yellow',

  NONE_MINIMAL_DEPRESSION: 'green',
  MILD_DEPRESSION: 'teal',
  MODERATE_DEPRESSION: 'yellow',
  MODERATELY_SEVERE_DEPRESSION: 'orange',
  SEVERE_DEPRESSION: 'red',

  MINIMAL_ANXIETY: 'green',
  MILD_ANXIETY: 'yellow',
  MODERATE_ANXIETY: 'orange',
  SEVERE_ANXIETY: 'red',

  CRAFFT_LOW: 'green',
  CRAFFT_MEDIUM: 'yellow',
  CRAFFT_HIGH: 'red',

  VANDERBILT_SUBTYPE_POSITIVE: 'red',

  ASQ_FOLLOW_UP: 'red',
  ASQ_ASSESSMENT_NEEDED: 'red',
  ASQ_MONITOR: 'yellow',
  ASQ_ON_SCHEDULE: 'green',

  HEARING_SCREENING_PASS_BOTH: 'green',
  HEARING_SCREENING_PASS_RIGHT_REFER_LEFT: 'yellow',
  HEARING_SCREENING_PASS_LEFT_REFER_RIGHT: 'yellow',
  HEARING_SCREENING_REFER_BOTH: 'red',

  M_CHAT_R_LOW_RISK: 'green',
  M_CHAT_R_MEDIUM_RISK: 'yellow',
  M_CHAT_R_HIGH_RISK: 'red',
}

export const interpretations = nameValueArrayFromObject<Interpretation>(
  interpretationDisplay
)

export const interpretationDisplayToEnum = Object.keys(
  interpretationDisplay
).reduce((acc, key) => {
  acc[interpretationDisplay[key]] = key
  return acc
}, {})

export const screeningTestInterpretationCodeToEnum =
  screeningTestInterpretationConceptCodings.reduce((acc, coding) => {
    if (interpretationDisplayToEnum[coding.display]) {
      acc[coding.code] = interpretationDisplayToEnum[coding.display]
    }
    return acc
  }, codeToEnumBase)
