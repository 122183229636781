import { AppointmentStatus } from 'types/graphql'

import { StatusIndicatorColor } from 'src/components/StatusIndicator/StatusIndicator'

interface AppointmentStatusConfiguration {
  color: string
  display: string
  badgeColor?: StatusIndicatorColor
  enabledOnCheckIn?: boolean
  isCheckedInStatus?: boolean
  disabled?: boolean
}

export const appointmentStatusConfiguration: {
  [key in AppointmentStatus]: AppointmentStatusConfiguration
} = {
  SCHEDULED: {
    color: 'text-comp-badge-yellow-status',
    badgeColor: 'yellow',
    display: 'Scheduled',
  },
  ARRIVED: {
    color: 'text-comp-badge-orange-status',
    badgeColor: 'orange',
    display: 'Arrived',
  },
  CANCELLED: {
    color: 'text-comp-badge-red-status',
    badgeColor: 'red',
    display: 'Cancelled',
  },
  NO_SHOW: {
    color: 'text-comp-badge-red-status',
    badgeColor: 'red',
    display: 'No show',
  },
  CHECKED_IN: {
    color: 'text-comp-badge-green-status',
    badgeColor: 'green',
    display: 'Checked in',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WORKING_UP: {
    color: 'text-comp-badge-yellow-status',
    badgeColor: 'yellow',
    display: 'Working up',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WAITING_FOR_RN: {
    color: 'text-comp-badge-teal-status',
    badgeColor: 'teal',
    display: 'Needs RN',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WAITING_FOR_MA: {
    color: 'text-comp-badge-violet-status',
    badgeColor: 'violet',
    display: 'Needs MA',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WITH_RN_MA: {
    color: 'text-comp-badge-blue-status',
    badgeColor: 'blue',
    display: 'With RN / MA',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WAITING_FOR_LABS: {
    color: 'text-comp-badge-rose-status',
    badgeColor: 'rose',
    display: 'Needs labs',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  LABS_IN_PROGRESS: {
    color: 'text-comp-badge-red-status',
    badgeColor: 'red',
    display: 'Labs in progress',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  LABS_COMPLETED: {
    color: 'text-comp-badge-red-status',
    badgeColor: 'red',
    display: 'Labs completed',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WAITING_FOR_PRACTITIONER: {
    color: 'text-comp-badge-cyan-status',
    badgeColor: 'cyan',
    display: 'Needs practitioner',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WAITING_FOR_VACCINATION: {
    color: 'text-comp-badge-orange-status',
    badgeColor: 'orange',
    display: 'Needs vaccination',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  POST_IMMUNIZATION_WAIT: {
    color: 'text-comp-badge-purple-status',
    badgeColor: 'purple',
    display: 'Post-immunization',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  SEEN: {
    color: 'text-comp-badge-blue-status',
    badgeColor: 'blue',
    display: 'Being seen',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  READY_TO_BE_CHECKED_OUT: {
    color: 'text-comp-badge-yellow-status',
    badgeColor: 'yellow',
    display: 'Check out ready',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  WITH_BILLING: {
    color: 'text-comp-badge-yellow-status',
    badgeColor: 'yellow',
    display: 'With billing',
    enabledOnCheckIn: true,
    isCheckedInStatus: true,
  },
  CHECKED_OUT: {
    color: 'text-comp-badge-dark-gray-status',
    badgeColor: 'dark-gray',
    display: 'Checked out',
    disabled: true,
  },
}

export const CANCELLED_APPOINTMENT_STATUSES: AppointmentStatus[] = [
  'CANCELLED',
  'NO_SHOW',
]
export const isAppointmentCancelled = (status: AppointmentStatus) =>
  CANCELLED_APPOINTMENT_STATUSES.includes(status)
export const isAppointmentCheckedIn = (status: AppointmentStatus) =>
  !nonCheckedInAppointmentStatuses.includes(status)
export const isAppointmentCheckedOut = (status: AppointmentStatus) =>
  status === 'CHECKED_OUT'

export const checkInEnabledStatuses = Object.keys(
  appointmentStatusConfiguration
)
  .filter((key) => appointmentStatusConfiguration[key].enabledOnCheckIn)
  .concat(CANCELLED_APPOINTMENT_STATUSES) as AppointmentStatus[]

export const checkedInStatuses = Object.keys(
  appointmentStatusConfiguration
).filter(
  (key) => appointmentStatusConfiguration[key].isCheckedInStatus
) as AppointmentStatus[]

export const nonCheckedInAppointmentStatuses = Object.keys(
  appointmentStatusConfiguration
).filter(
  (key) => !appointmentStatusConfiguration[key].enabledOnCheckIn
) as AppointmentStatus[]

export const appointmentStatuses = Object.entries(
  appointmentStatusConfiguration
).map(([key, value]) => ({
  name: value.display,
  value: key,
}))
