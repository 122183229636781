import {
  HospitalLocationsQueryForSelect,
  HospitalLocationsQueryForSelectVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'

export const QUERY = gql`
  query HospitalLocationsQueryForSelect {
    hospitalLocations {
      id
      name
      address {
        id
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`

export const HospitalLocationSelect = (
  props: Omit<SelectFieldProps, 'options'>
) => {
  const { data } = useQuery<
    HospitalLocationsQueryForSelect,
    HospitalLocationsQueryForSelectVariables
  >(QUERY)

  const locations = data?.hospitalLocations ?? []

  if (!locations.length)
    return <SelectField key="loading" {...props} options={[]} />

  return (
    <SelectField
      key="loaded"
      {...props}
      options={[
        ...locations.map((location) => {
          return {
            value: location.id,
            display: `${location.name}: ${formatAddress(
              location.address,
              AddressJoinOption.COMMA
            )}`,
          }
        }),
      ]}
    />
  )
}
