import { useQuery } from '@apollo/client'
import { GetDoctorFirstNotifications } from 'types/graphql'

const DOCTOR_FIRST_QUERY = gql`
  query GetDoctorFirstNotifications {
    profile {
      id
      medicationsNotificationsCount {
        id
        updatedAt
        refillRequestsCount
        prescriptionChangeRequestsCount
        incompletePrescriptionsCount
        prescriptionsRequiringSignatureCount
        messageNotificationCount
        reportNotificationCount
        total
      }
    }
  }
`

export const useMedicationNotifications = () => {
  const doctorFirstNotifications = usePollDoctorFirstNotifications({})
  return doctorFirstNotifications
}

const usePollDoctorFirstNotifications = ({ skip }: { skip?: boolean }) => {
  const { data } = useQuery<GetDoctorFirstNotifications>(DOCTOR_FIRST_QUERY, {
    // This is the frequency with which we ask our backend for the latest notifications count
    // Separately, our server polls DoctorFirst for the latest notifications count every 15 minutes,
    // so the notification count in the UI may be up to 16 minutes out of date.
    pollInterval: 1000 * 60 * 1, // 1 minute
    skip,
  })
  const count = data?.profile?.medicationsNotificationsCount?.total ?? 0
  const messageNotificationCount =
    data?.profile?.medicationsNotificationsCount?.messageNotificationCount ?? 0
  const reportNotificationCount =
    data?.profile?.medicationsNotificationsCount?.reportNotificationCount ?? 0
  const tooltipText =
    count > 0
      ? generateDetailedDescription({
          refillRequestsCount:
            data?.profile?.medicationsNotificationsCount?.refillRequestsCount ??
            0,
          prescriptionChangeRequestsCount:
            data?.profile?.medicationsNotificationsCount
              ?.prescriptionChangeRequestsCount ?? 0,
          incompletePrescriptionsCount:
            data?.profile?.medicationsNotificationsCount
              ?.incompletePrescriptionsCount ?? 0,
          prescriptionsRequiringSignatureCount:
            data?.profile?.medicationsNotificationsCount
              ?.prescriptionsRequiringSignatureCount ?? 0,
        })
      : null

  return {
    count,
    tooltipText,
    messageNotificationCount,
    reportNotificationCount,
  }
}

const generateDetailedDescription = ({
  refillRequestsCount,
  prescriptionChangeRequestsCount,
  incompletePrescriptionsCount,
  prescriptionsRequiringSignatureCount,
}: {
  refillRequestsCount: number
  prescriptionChangeRequestsCount: number
  incompletePrescriptionsCount: number
  prescriptionsRequiringSignatureCount: number
}) => {
  const counts = {
    refillRequestsCount,
    prescriptionChangeRequestsCount,
    incompletePrescriptionsCount,
    prescriptionsRequiringSignatureCount,
  }
  const countsGreaterThanZero = (
    Object.keys(counts) as Array<keyof typeof counts>
  ).filter((key) => counts[key] > 0)
  return countsGreaterThanZero.map((key) =>
    formatNotificationCount(counts[key], key)
  )
}

const formattedKeys = {
  refillRequestsCount: {
    plural: 'refills',
    singular: 'refill',
  },
  prescriptionChangeRequestsCount: {
    plural: 'prescription changes',
    singular: 'prescription change',
  },
  incompletePrescriptionsCount: {
    plural: 'incomplete prescriptions',
    singular: 'incomplete prescription',
  },
  prescriptionsRequiringSignatureCount: {
    plural: 'need signature',
    singular: 'needs signature',
  },
}

const formatNotificationCount = (
  count: number,
  key:
    | 'refillRequestsCount'
    | 'prescriptionChangeRequestsCount'
    | 'incompletePrescriptionsCount'
    | 'prescriptionsRequiringSignatureCount'
) => {
  const pluralSingular = formattedKeys[key]
  const formattedKey =
    count === 1 ? pluralSingular.singular : pluralSingular.plural
  return `${count} ${formattedKey}`
}

export default useMedicationNotifications
