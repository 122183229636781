import { useEffect, useRef, useState } from 'react'

import { LocalDate } from '@js-joda/core'
import { dateTimeFormatter } from 'common/data/date'
import { useParams } from 'react-router-dom'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useGeneratePatientDocument } from 'src/pdf/useGeneratePatientDocument'

const SidepanelPatientBillingHistory = () => {
  const params = useParams()

  const { patientId } = params
  const startDate = LocalDate.parse(params.startDate)
  const endDate = LocalDate.parse(params.endDate)

  const generatePatientDocument = useGeneratePatientDocument()

  const [pdfUrl, setPdfUrl] = useState<URL | null>()
  const loadingRef = useRef(false)

  useEffect(() => {
    if (loadingRef.current) return

    loadingRef.current = true

    void generatePatientDocument({
      patientId,
      input: {
        type: 'BILLING_HISTORY',
        startDate,
        endDate,
      },
    }).then((url) => {
      setPdfUrl(url)

      loadingRef.current = false
    })
  }, [patientId, params.startDate, params.endDate])

  return (
    <SidepanelPage
      header={`Billing history for ${startDate.format(
        dateTimeFormatter('MM/dd/yyyy')
      )} to ${endDate.format(
        dateTimeFormatter('MM/dd/yyyy')
      )} dates of service`}
      description="Please review the patient's billing history with the patient and/or their caregiver(s)."
    >
      {!pdfUrl ? (
        <div className="pt-core-space-100">
          <LoadingSpinner />
        </div>
      ) : (
        <FileDisplay
          type="application/pdf"
          url={pdfUrl}
          showDownloadLink={false}
        />
      )}
    </SidepanelPage>
  )
}

export default SidepanelPatientBillingHistory
