import { InputFieldProps } from 'src/components/atoms/InputField/InputField'

import { InputFieldWithUnit } from '../InputFieldWithUnits/InputFieldWithUnits'

export type MoneyFieldInputProps = InputFieldProps & {
  rightText?: string
}

export const MoneyInputField = (props: MoneyFieldInputProps) => {
  return (
    <InputFieldWithUnit
      type="number"
      min={0}
      step={0.01}
      placeholder="0.00"
      {...props}
      unit={props.rightText}
    />
  )
}
