import { match } from 'ts-pattern'
import { GetAutomationRule } from 'types/graphql'

import Card from 'src/components/atoms/Card'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { chargeItemModifierDisplay } from 'src/data/chargeItemModifiers'

const Automation = ({
  automation,
}: {
  automation: GetAutomationRule['automationRule']['automations'][number]
}) =>
  match(automation)
    .with(
      { __typename: 'ChargeItemAutomation' },
      ({ billingCode, countDescription, modifiers, diagnoses }) => (
        <ChargeItemAutomation
          billingCode={billingCode}
          countDescription={countDescription}
          modifiers={modifiers}
          diagnoses={diagnoses}
        />
      )
    )
    .with({ __typename: 'DiagnosisAutomation' }, ({ diagnosis }) => (
      <DiagnosisAutomation diagnosis={diagnosis} />
    ))
    .with({ __typename: 'ModifierAutomation' }, ({ modifier }) => (
      <ModifierAutomation modifier={modifier} />
    ))
    .with({ __typename: 'RemoveChargeItemAutomation' }, ({ billingCode }) => (
      <RemoveChargeItemAutomation billingCode={billingCode} />
    ))
    .exhaustive()

const ChargeItemAutomation = ({
  billingCode,
  countDescription,
  modifiers,
  diagnoses,
}: Omit<
  Extract<
    GetAutomationRule['automationRule']['automations'][number],
    {
      __typename: 'ChargeItemAutomation'
    }
  >,
  '__typename'
>) => (
  <StackView space={25}>
    <Card
      testId="charge-item-automation-list-item"
      verticalPadding={100}
      horizontalPadding={75}
    >
      <StackView>
        <Typography
          textStyle="interface-strong-s"
          color="text-base-color-fg-muted"
        >
          Add in {billingCode.code}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {billingCode.description}
        </Typography>
      </StackView>
    </Card>
    <StackView space={25} className="pl-12">
      <Card verticalPadding={100} horizontalPadding={75}>
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          Billing unit count = {countDescription}
        </Typography>
      </Card>
      <Card verticalPadding={100} horizontalPadding={75}>
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {modifiers.length > 0
            ? `Modifiers: ${modifiers
                .map((modifier) => modifier.replace(/MODIFIER_/, ''))
                .join(', ')}`
            : 'No modifiers'}
        </Typography>
      </Card>
      <Card verticalPadding={100} horizontalPadding={75}>
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {diagnoses.length > 0
            ? `Diagnoses: ${diagnoses
                .map((diagnosis) => diagnosis.code)
                .join(', ')}`
            : 'Same diagnoses as triggering charge item'}
        </Typography>
      </Card>
    </StackView>
  </StackView>
)

const RemoveChargeItemAutomation = ({
  billingCode,
}: Omit<
  Extract<
    GetAutomationRule['automationRule']['automations'][number],
    {
      __typename: 'RemoveChargeItemAutomation'
    }
  >,
  '__typename'
>) => (
  <StackView space={25}>
    <Card
      testId="charge-item-automation-list-item"
      verticalPadding={100}
      horizontalPadding={75}
    >
      <StackView>
        <Typography
          textStyle="interface-strong-s"
          color="text-base-color-fg-muted"
        >
          Remove {billingCode.code}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {billingCode.description}
        </Typography>
      </StackView>
    </Card>
  </StackView>
)

const DiagnosisAutomation = ({
  diagnosis,
}: Omit<
  Extract<
    GetAutomationRule['automationRule']['automations'][number],
    {
      __typename: 'DiagnosisAutomation'
    }
  >,
  '__typename'
>) => (
  <StackView space={25}>
    <Card
      testId="diagnosis-automation-list-item"
      verticalPadding={100}
      horizontalPadding={75}
    >
      <StackView>
        <Typography
          textStyle="interface-strong-s"
          color="text-base-color-fg-muted"
        >
          {diagnosis.code}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {diagnosis.description}
        </Typography>
      </StackView>
    </Card>
  </StackView>
)

const ModifierAutomation = ({
  modifier,
}: Omit<
  Extract<
    GetAutomationRule['automationRule']['automations'][number],
    {
      __typename: 'ModifierAutomation'
    }
  >,
  '__typename'
>) => (
  <StackView space={25}>
    <Card
      testId="diagnosis-automation-list-item"
      verticalPadding={100}
      horizontalPadding={75}
    >
      <StackView>
        <Typography
          textStyle="interface-strong-s"
          color="text-base-color-fg-muted"
        >
          Modifier {modifier.replace(/MODIFIER_/, '')}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {chargeItemModifierDisplay[modifier]}
        </Typography>
      </StackView>
    </Card>
  </StackView>
)

export default Automation
