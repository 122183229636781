/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { StarIcon } from '@heroicons/react/24/outline'
import {
  CalendarIcon,
  LanguageIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/solid'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Workspace } from 'polotno/canvas/workspace'
import { PageType } from 'polotno/model/page-model'
import { createStore, StoreType } from 'polotno/model/store'
import { SectionTab } from 'polotno/side-panel'
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel'
import { ImagesGrid } from 'polotno/side-panel/images-grid'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { PractitionerNameAndCredential } from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import { useForm, Form, useFormContext } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import ActivePractitionerSelectCell from 'src/components/ActivePractitionerSelectCell'
import Button from 'src/components/atoms/Button'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { usePDFAnnotationContext } from 'src/providers/context/PDFAnnotationContext'

import { useConvertPDFToImages } from './useConvertPDFToImages'

import '@blueprintjs/core/lib/css/blueprint.css'
import './index.css'

const SHOULD_INCLUDE_DATE_KEY = 'shouldIncludeDateInSignature'
const SHOULD_INCLUDE_CREDENTIALS_KEY = 'shouldIncludeCredentialsInSignature'

const QUERY = gql`
  query PractitionerNameAndCredential($id: String!) {
    practitioner(id: $id) {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      user {
        id
        credentialsLabel
      }
    }
  }
`

export const PDFEditor = ({
  url,
  onSave,
}: {
  url: string
  onSave: (store: StoreType) => Promise<void>
}) => {
  const { setisAnnotatingPDF } = usePDFAnnotationContext()
  const [loadingState, setLoadingState] = useState('LOADING')
  const [isSaving, setIsSaving] = useState(false)
  const [store] = useState(
    createStore({
      key: 'a4uPODvovESPEopakZe0',
      showCredit: false,
    })
  )
  const auth = useEmrAuth()
  const { images, loading } = useConvertPDFToImages(url)

  useEffect(() => {
    if (images.length > 0) {
      let lastPage: PageType
      store.setSize(850, 1100)
      images.forEach((src) => {
        lastPage = store.addPage()
        lastPage.addElement({
          type: 'image',
          src,
          width: 850,
          height: 1100,
          draggable: false,
          resizable: false,
          contentEditable: false,
          styleEditable: false,
          removable: false,
        })
      })
      store.selectPage(lastPage.id)
      setLoadingState('COMPLETE')
    }
  }, [images, store])

  useEffect(() => {
    if (loadingState === 'COMPLETE') {
      store.setScale(1)
    }
  }, [loadingState, store])

  if (loading || loadingState === 'LOADING') {
    return <div>Loading...</div>
  }

  const SignatureSection: Section = {
    name: 'sign',
    Tab: buildTab({
      input: {
        name: 'Sign',
        displayName: 'SignatureTab',
        Icon: PencilIcon,
      },
    }),
    Panel: observer(({ store }: { store: StoreType }) => {
      const [shouldIncludeDate, setShouldIncludeDate] = useLocalStorage(
        SHOULD_INCLUDE_DATE_KEY,
        false
      )
      const [shouldIncludeCredentials, setShouldIncludeCredentials] =
        useLocalStorage(SHOULD_INCLUDE_CREDENTIALS_KEY, false)

      const formMethods = useForm({
        defaultValues: {
          signedByPractitionerId: auth.currentUser.practitionerId,
          shouldIncludeDate,
          shouldIncludeCredentials,
        },
      })

      const shouldIncludeDateValue = formMethods.watch('shouldIncludeDate')
      const shouldIncludeCredentalValue = formMethods.watch(
        'shouldIncludeCredentials'
      )

      useEffect(() => {
        setShouldIncludeDate(shouldIncludeDateValue)
      }, [setShouldIncludeDate, shouldIncludeDateValue])

      useEffect(() => {
        setShouldIncludeCredentials(shouldIncludeCredentalValue)
      }, [setShouldIncludeCredentials, shouldIncludeCredentalValue])

      return (
        <Form formMethods={formMethods}>
          <SignatureSectionPanel store={store} />
        </Form>
      )
    }),
  }

  const DateSection: Section = {
    name: 'date',
    Tab: buildTab({
      input: {
        name: 'Date',
        displayName: 'DateTab',
        Icon: CalendarIcon,
        onClick: () =>
          store.activePage.addElement({
            type: 'text',
            text: format(new Date(), 'MM/dd/yyyy'),
            fontSize: 30,
            width: 200,
            x: 400,
            y: 800,
            alwaysOnTop: true,
          }),
      },
    }),
    Panel: null,
  }

  const FreeTextSection: Section = {
    name: 'text',
    Tab: buildTab({
      input: {
        name: 'Text',
        displayName: 'FreeTextTab',
        Icon: LanguageIcon,
        onClick: () =>
          store.activePage.addElement({
            type: 'text',
            text: 'Text here',
            fontSize: 30,
            width: 200,
            x: 400,
            y: 800,
            alwaysOnTop: true,
          }),
      },
    }),
    Panel: null,
  }

  const DragDropSection: Section = {
    name: 'Shapes',
    Tab: buildTab({
      input: {
        name: 'Shapes',
        displayName: 'ShapesTab',
        Icon: StarIcon,
      },
    }),
    Panel: observer(({ store }: { store: StoreType }) => {
      return (
        <StackView gap={100}>
          <StackView gap={50}>
            <Typography textStyle="title-xs">Add shapes</Typography>
            <Typography textStyle="body-xs">
              Click to add or drag and drop shapes onto the document
            </Typography>
          </StackView>

          <ImagesGrid
            images={[
              {
                url: `${window.location.origin}/img/checkmark-black.png`,
              },
              {
                url: `${window.location.origin}/img/x-mark-black.png`,
              },
            ]}
            getPreview={(item) => item.url}
            onSelect={async (image, pos) => {
              const width = 25
              const height = 25

              const x = (pos?.x || store.width / 2) - width / 2
              const y = (pos?.y || store.height / 2) - height / 2
              store.activePage?.addElement({
                type: 'image',
                src: image.url,
                width,
                height,
                x,
                y,
              })
            }}
            isLoading={false}
            shadowEnabled={false}
          />
        </StackView>
      )
    }),
  }

  const ActionControls = ({ store }) => {
    return (
      <StackView direction="row" space={50}>
        <Button
          buttonStyle="secondary"
          disabled={isSaving}
          onClick={() => {
            setisAnnotatingPDF(false)
          }}
        >
          Cancel
        </Button>
        <Button
          loading={isSaving}
          onClick={async () => {
            setIsSaving(true)
            await onSave(store)
          }}
        >
          Save
        </Button>
      </StackView>
    )
  }

  const sections = [
    SignatureSection,
    DateSection,
    FreeTextSection,
    DragDropSection,
  ]

  return (
    <PolotnoContainer className="polotno-app-container">
      <SidePanelWrap>
        <SidePanel store={store} sections={sections} defaultSection="custom" />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar
          store={store}
          components={{
            ActionControls,
          }}
        />
        <Workspace store={store} components={{ PageControls: () => null }} />
        <ZoomButtons store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
  )
}

interface SectionTabProps {
  children: React.ReactNode
  name: string
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  active: boolean
  iconSize?: number
}

type Tab = ((props: SectionTabProps) => React.JSX.Element) & {
  displayName: string
}

type Section = (typeof DEFAULT_SECTIONS)[number]

type BuildTabInput = {
  input: {
    name: string
    displayName: string
    Icon: React.FC<React.ComponentProps<'svg'>>
    onClick?: () => void
  }
}

const buildTab = ({
  input: { name, displayName, Icon, onClick },
}: BuildTabInput): Tab => {
  const TabComponent = (props: SectionTabProps) => (
    <SectionTab name={name} {...props} {...(onClick ? { onClick } : {})}>
      <StackView alignItems="center">
        <Icon className="h-base-size-icon-xs w-base-size-icon-xs" />
      </StackView>
    </SectionTab>
  )
  TabComponent.displayName = displayName
  return TabComponent
}

const SignatureSectionPanel = ({ store }: { store: StoreType }) => {
  const [getPractitioner] = useLazyQuery<PractitionerNameAndCredential>(QUERY)
  const formMethods = useFormContext()
  const signedByPractitionerId = formMethods.watch('signedByPractitionerId')
  const shouldIncludeDate = formMethods.watch('shouldIncludeDate')
  const shouldIncludeCredentials = formMethods.watch('shouldIncludeCredentials')

  return (
    <StackView space={50}>
      <StackView space={25}>
        <Typography textStyle="title-xs">Sign</Typography>
        <Typography textStyle="body-xs">
          Saved changes are final, proceed with caution.
        </Typography>
      </StackView>
      <Typography textStyle="body-s">Signing individual</Typography>
      <ActivePractitionerSelectCell name="signedByPractitionerId" />
      <StackView justifyContent="center">
        <CheckboxField
          className="items-center"
          label="Include date"
          name="shouldIncludeDate"
        />
        <CheckboxField
          className="items-center"
          label="Include credentials"
          name="shouldIncludeCredentials"
        />
      </StackView>
      <Button
        buttonStyle="secondary"
        buttonSize="m"
        icon={PlusIcon}
        text="Add to document"
        disabled={!signedByPractitionerId}
        onClick={async () => {
          const practitionerResponse = await getPractitioner({
            variables: {
              id: signedByPractitionerId,
            },
          })
          const practitioner = practitionerResponse.data.practitioner
          const signedByPractitionerDisplayName = formatDisplayName({
            ...practitioner,
            credentials: shouldIncludeCredentials
              ? practitioner.user?.credentialsLabel
              : undefined,
          })

          const date = new Date()
          const signatureEl = store.activePage.addElement({
            type: 'text',
            text: signedByPractitionerDisplayName,
            fontFamily: 'Dancing Script',
            fontStyle: 'italic',
            x: 200,
            y: 800,
            fontSize: 40,
            width: 400,
            alwaysOnTop: true,
          })

          store.activePage.addElement({
            type: 'text',
            text: `Signed electronically by ${signedByPractitionerDisplayName}. Date: ${date.toLocaleString()}`,
            x: 20,
            y: store.height - 40,
            fontSize: 20,
            width: 800,
            alwaysOnTop: true,
            align: 'left',
          })

          if (shouldIncludeDate) {
            const dateEl = store.activePage.addElement({
              type: 'text',
              text: format(date, 'MM/dd/yyyy'),
              fontSize: 30,
              width: 200,
              x: 600,
              y: 800,
              alwaysOnTop: true,
            })

            store.groupElements([signatureEl.id, dateEl.id])
          }
        }}
      />
    </StackView>
  )
}

export default PDFEditor
